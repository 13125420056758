import { get } from 'lodash';
import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from "../constants";
// import { FEATURE_FLAG_ROCKET_QUOTE, FEATURE_FLAG_SUPPLIER_MANAGEMENT } from "../constants/featureFlagConstants";
import { getAuthorizedHeader } from "../utils/apiUtils";
// import { filterReadyForQuoteItems } from "../utils/itemUtils";

export const adminGetAllItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/search?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getSupplierOnboardingProjects = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/rfq-project`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: projects } = await response.json();
    return projects;
  });
}

export const getReadyForQuoteItemsGroupByProject = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/ready-for-quote/by-project`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: projects } = await response.json();
    return projects;
  });
}

export const getRFQOnboardingItemsGroupByProjectID = async ({ projectID, params }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/rfq-projects/${projectID}?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const uploadPoFile = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/upload-po`;
  return fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const uploadPartnershipAgreementFile = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/upload-partnership-agreement`;
  return fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const uploadCounterSignedPartnershipAgreementFile = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/upload-counter-signed-partnership-agreement`;
  return fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const uploadQuotationFormFile = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/upload-quotation-form`;
  return fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getQuotationFormFile = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/onboarding/quotation-form?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getItemSingleUser = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/users/${userID}/items`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

import React from "react";

import FilesUploadActionButton from "../buttons/FilesUploadActionButton";

import { colors } from "../../palette";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ disabled }) => ({
  textAlign: "center",
  height: "100%",
  background: colors.uploadFileBg,
  color: disabled ? colors.fontLightGrey : colors.fontBlack,
  textDecoration: "underline",
}));

function UploadFileItem(props) {
  const { itemKey, text, onFileUpload, uploadState, accept, style, disabled = false } = props;

  return (
    <div style={{ height: "6.5rem" }}>
      <Item key={itemKey} elevation={4} style={style} disabled={disabled}>
        <FilesUploadActionButton
          inputID={itemKey}
          buttonText={text}
          multiple={false}
          highlightButton
          accept={accept}
          handleUploadFiles={onFileUpload}
          disabled={disabled}
        />
        {uploadState !== null && (
          <span style={{ fontSize: "12px", fontStyle: "italic" }}>
            1 file uploaded
          </span>
        )}
      </Item>
    </div>
  );
}

export default UploadFileItem;

export const ppeTechnologyOptions = [
  'CNC Machining',
  '3D Printing',
  'Sheet Metal Fabrication',
];

export const ppeMaterialOptions = {
  'CNC Machining': [
    'Aluminum 2024',
    'Aluminum 5052',
    'Aluminum 6061',
    'Aluminum 6061-T6',
    'Aluminum 6082',
    'Aluminum 7075',
    'Aluminum 7075-T6',
    'Delrin',
    'Mild Steel A36',
    'Stainless Steel 303',
    'Stainless Steel 304',
    'Stainless Steel 316',
    'Brass C360',
  ],
  '3D Printing': [
    'Standard PLA',
    'Standard ABS',
    'Standard Nylon',
    'Polycarbonate',
    'Standard ASA',
    'Standard resin',
    'Polyamide Nylon',
  ],
  'Sheet Metal Fabrication': [],
};

export const ppeFileOptions = {
  CNC: ['step', 'stp'],
  '3DP': ['step', 'stp', 'stl'],
  SMF: ['dxf'],
};

export const ppeSurfaceFinishOptions = [
  'NIL',
  'Anodizing Type II',
  'Anodizing Type III (Hardcoat)',
  'Powder Coating',
  'Tumble',
];

export const ppe3dpTechOptions = [
  'Fused Deposition Modeling (FDM)',
  'Stereolithography Apparatus (SLA)',
  'Selective Laser Sintering (SLS)',
];

export const ppeToleranceOptions = [0.01, 0.051, 0.076, 0.1, 0.127];

export const ppeSafeguardPriceOptions = [
  'Minimum Price Per Piece',
  'Maximum Price Per Piece',
  'Minimum Total Price',
  'Maximum Total Price',
];

export const ppeQuantityOptions = ['Max Quantity'];

export const ppeComplexityOptions = ['Minimum', 'Maximum'];

export const ppeFileSizeOptions = ['Minimum File Size', 'Maximum File Size'];

export const ppeMarkupOptions = ['CNC Markup', '3DP Markup', 'SMF Markup'];

const roundToPointZeroFive = (num) => Math.round(num * 20) / 20;

const ppe3dpInfillRange = [0.1, 1.0];

const ppe3dpInfillOptions = [];
let curInfill = ppe3dpInfillRange[0];
while (curInfill <= ppe3dpInfillRange[1]) {
  ppe3dpInfillOptions.push(curInfill);
  curInfill = roundToPointZeroFive(curInfill + 0.05);
}

const ppe3dpLayerThicknessRange = {
  FDM: [0.1, 0.4],
  SLS: [0.1, 0.4],
  SLA: [0.025, 0.4],
};

const ppe3dpLayerThicknessOptions = {
  FDM: [],
  SLS: [],
  SLA: [],
};
for (const tech in ppe3dpLayerThicknessRange) {
  let curLayerThickness = ppe3dpLayerThicknessRange[tech][0];
  while (curLayerThickness <= ppe3dpLayerThicknessRange[tech][1]) {
    ppe3dpLayerThicknessOptions[tech].push(curLayerThickness);
    curLayerThickness = roundToPointZeroFive(curLayerThickness + 0.05);
  }
}

export { ppe3dpInfillOptions, ppe3dpLayerThicknessOptions };

export const techMapping = {
  'CNC Machining': 'CNC',
  'Sheet Metal Fabrication': 'SMF',
  '3D Printing': '3DP',
};

export const threeDPrintingTechMapping = {
  'Fused Deposition Modeling (FDM)': 'FDM',
  'Stereolithography Apparatus (SLA)': 'SLA',
  'Selective Laser Sintering (SLS)': 'SLS',
};

export const CNC_MATERIAL_CODE_MAPPING = {
  '1018 Mild Steel': 'ms1018',
  'ABS': 'abs',
  'Acrylic': 'acrlyic',
  'Alloy Steel 4140': 'as4140',
  'Aluminum 2024': 'alu2024',
  'Aluminum 5052': 'alu5052',
  'Aluminum 6061-T6': 'alu6061',
  'Aluminum 6061': 'alu6061',
  'Aluminum 6082-T6': 'alu6082',
  'Aluminum 6082': 'alu6082',
  'Aluminum 7075-T6': 'alu7075',
  'Aluminum 7075': 'alu7075',
  'Aluminum MIC-6': 'alumic6',
  'Brass C360': 'brass',
  'Copper 101': 'copper101',
  'Delrin (Acetal / POM)': 'delrin',
  'Delrin': 'delrin',
  'HDPE': 'pe100',
  'UHMW-PE': 'pe1000',
  'Mild Steel A36 (Low Carbon Steel)': 'MSA36',
  'Mild Steel A36': 'MSA36',
  'PC (Polycarbonate)': 'polycarbonate',
  'PVC': 'pvc',
  'ULTEM 1000': 'ultem1000',
  'PP (Polypropylene)': 'polypropylene',
  'Nylon 6/6': 'nylon',
  'Titanium Grade 2': 'titanium2',
  'Titanium Grade 5 (Ti6Al4V)': 'titanium5',
  'PEEK': 'peek',
  'Polyamide Nylon': 'PA',
  'Polycarbonate': 'polycarbonate',
  'PTFE (Teflon)': 'ptfe',
  'ST45': 'st45',
  'Stainless Steel 303': 'ss303',
  'Stainless Steel 304': 'ss304',
  'Stainless Steel 316': 'ss316',
  'Stainless Steel 316/316L': 'ss316',
  'Standard ABS': 'abs',
  'Standard ASA': 'ASA',
  'Standard Nylon': 'nylon',
  'Standard PLA': 'PLA',
  'Standard resin': 'resin',
};

export const THREE_DP_MATERIAL_CODE_MAPPING = {
  'ABS': 'ABS',
  'Polycarbonate': 'pc',
  'PC (Polycarbonate)': 'pc',
  'PLA': 'PLA',
  'Nylon PA 12 (PA2200)': 'nylon',
  'ASA': 'ASA',
  'Standard ASA': 'ASA',
  'Standard Resin': 'resin',
  'Nylon 12CF (Carbon Fiber)': 'PA2200',
};

export const surfaceFinishMapping = {
  'Anodizing Type II': 'typeII',
  'Anodizing Type III (Hardcoat)': 'typeIII',
  'Powder Coating': 'powder',
  'Tumbling': 'tumble',
  'NIL': 'none',
  'Electroless Nickel Plating': 'enp',
  'Painting': 'paint',
  'Polishing': 'polish',
  'Sanding': 'sanding',
  'Black Oxide': 'blackoxide',
  'Bead Blasting': 'beadblast',
  'Anodizing Type III + PTFE': 'typeIIIPTFE',
  'Brushing': 'brush',
  'Zinc Plating': 'zinc',
};

export const toleranceMapping = (tol) => {
  return tol >= 0.01
    ? 0
    : tol >= 0.051
      ? 1
      : tol >= 0.076
        ? 2
        : tol >= 0.1
          ? 3
          : tol >= 0.127
            ? 4
            : -1;
};

export const COMPLEXITY_THRESHOLD = 1.5;

export const SUPPLIER_PRICE_MULTIPLIER = 0.85;

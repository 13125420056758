import React from 'react';
import { get, isEmpty } from 'lodash';
import { toast } from "react-toastify";
import { Badge, Tooltip, Typography, IconButton } from "@mui/material";
import {
  FileCopy as FileCopyIcon,
} from "@mui/icons-material";

import FilesUploadActionButton from '../components/buttons/FilesUploadActionButton';

import { adminUpdateQuotation } from '../apis/quotationApi';
import { notifyError } from '../services/notificationService';
import { getBackgroundColorByQuoteStatus, getDiscountAmountUtil, getQuotationExchangeRate, isPpeQuote, isRocketQuote, rejectedReasonTextUtil } from '../utils/quotationUtils';
import { ORDER_STATUS } from '../constants';
import { includesIgnoreCase } from '../utils/stringUtils';
import { getDateStrWithMonth } from '../utils/dateTimeUtils';
import { convertPriceToCurrency, convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { CURRENCY_CODE } from './currencyConstants';
import { isEmptyValue } from '../utils/commonUtils';
import { colors } from '../palette';
// import { pdfFlatten } from '../apis/pdfApi';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../utils/fileUtils';
import { getIsRepeatOrder } from '../utils/itemUtils';
import { FACTOREM_BLUE } from '../palette';


// export const dateAcceptedCol = {
//   title: "Date Accepted",
//   field: "dateOfConfirmation",
//   render: rowData => {
//     const dateStr = rowData.dateOfConfirmation || rowData.dateOfAcceptance;
//     return (
//       <div>
//         {dateStr
//           ? getDateStrWithMonth(dateStr)
//           : ""}
//       </div>
//     );
//   },
//   cellStyle: {
//     minWidth: 150,
//     textAlign: 'center',
//   },
//   hidden: true,
//   export: true,
// }

// export const dateOfConfirmationCol = {
//   title: 'Date of Confirmation',
//   exportData: rowData => {
//     const data = rowData.dateOfConfirmation
//       ? rowData.dateOfConfirmation.substring(0, 10)
//       : "";
//     return data;
//   },
//   hidden: true,
//   export: true,
// }

// export const markupPercentCol = {
//   title: 'Markup Percent',
//   field: 'markupPercent',
//   hidden: true,
//   export: true,
// }

// export const quoteValidTillCol = {
//   title: 'Quote Valid Till',
//   exportData: rowData => {
//     const data = rowData.dateOfExpiry
//       ? rowData.dateOfExpiry.substring(0, 10)
//       : "";
//     return data;
//   },
//   hidden: true,
//   export: true,
// }

// export const supplierRemarksCol = {
//   title: 'Supplier Remarks',
//   exportData: rowData => {
//     const data = rowData.remarks || '';
//     return data;
//   },
//   hidden: true,
//   export: true,
// }

// const handlePORevisedUpload = async (files, quotationID, oldPOUrl, supplierName, projectID) => {
//   if (isEmpty(files)) {
//     notifyError('No file selected');
//   }
//   const toastId = toast('Uploading file...', { type: toast.TYPE.INFO, autoClose: false });
//   const file = files[0];
//   const keyArr = oldPOUrl.split('/');
//   let [oldFileName, extension] = keyArr[keyArr.length - 1].split('.');
//   extension = extension || 'pdf';
//   const revStr = '_Rev';
//   let newRev = 2;
//   if (oldFileName.includes(revStr)) {
//     const [oldFileNamePart, rev] = oldFileName.split(revStr);
//     oldFileName = oldFileNamePart;
//     newRev = Number(rev) + 1;
//   }
//   const newFileName = `${oldFileName}${revStr}${newRev}`;
//   const customFileName = `${newFileName}.${extension}`;
//   const formData = new FormData()
//   formData.append('filename', customFileName)
//   formData.append('file', file)
//   const result = await pdfFlatten(formData);
//   const s3ObjectUrl = result.data;
//   const body = {
//     combinedPOsForm: s3ObjectUrl,
//   }
//   return adminUpdateQuotation(quotationID, body)
//     .then(() => {
//       toast.success("PDF(s) flattened successfully!", {
//         position: toast.POSITION.TOP_RIGHT
//       });
//       toast.update(toastId, {
//         render: `Revised PO uploaded for all parts awarded to ${supplierName} in Project ${projectID}.`,
//         type: toast.TYPE.SUCCESS,
//         autoClose: 3000,
//       });
//       return s3ObjectUrl;
//     })
//     .catch(err => {
//       toast.update(toastId, {
//         render: 'Error uploading PO file!',
//         type: toast.TYPE.ERROR,
//         autoClose: 3000,
//       });
//     });
// }

// export const purchaseOrderAdminCol = ({
//   uploadCallback,
// }) => ({
//   title: "Purchase Order",
//   exportData: rowData => rowData.combinedPOsForm || rowData.purchaseOrderForm || 'N.A.',
//   render: rowData => {
//     const poFormURL = rowData.combinedPOsForm || rowData.purchaseOrderForm //for legacy data, some may not have combinedPOsForm
//     const poUrlDisplay = poFormURL
//       ? (
//         <div>
//           <Link
//             onClick={(e) => {
//               e.stopPropagation();
//               downloadS3File(poFormURL);
//             }}
//           >
//             Download PO
//           </Link>
//           <IconButton
//             onClick={(e) => {
//               e.stopPropagation();
//               navigator.clipboard.writeText(poFormURL)
//             }}>
//             <FileCopyIcon />
//           </IconButton>
//         </div>
//       ) : (
//         <div>N.A.</div>
//       );
//     return (
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//         }}
//       >
//         {poUrlDisplay}
//         {poFormURL && (
//           <FilesUploadActionButton
//             inputID={`file-upload-quotation-${rowData.quotationID}`}
//             highlightButton
//             buttonText="Upload PO Revision"
//             multiple={false}
//             handleUploadFiles={(files) => {
//               handlePORevisedUpload(files, rowData.quotationID, poFormURL, rowData.supplierName, rowData.projectID)
//                 .then(() => {
//                   if (typeof uploadCallback === 'function') {
//                     uploadCallback();
//                   }
//                 });
//             }}
//             accept=".pdf"
//           />
//         )}
//       </div>
//     );
//   },
//   cellStyle: {
//     minWidth: 250,
//   },
// });

const renderTooltipStatus = (status) => {
  if (status !== 'cancelled') return status;
  return (
    <Tooltip title="Order Cancelled">
      <div>
        {status}
      </div>
    </Tooltip>
  )
}

export const quotationStatusColumn = {
  headerName: "Status",
  field: 'status',
  renderCell: params => {
    const rowData = params.row;
    let status = rowData.ppeQuoteStatus || rowData.status;
    status = [ORDER_STATUS.VERIFYING, ORDER_STATUS.PENDING].includes(status)
      ? 'submitted'
      : status === ORDER_STATUS.DISABLED
        ? 'cancelled'
        : status
    const bgColor = getBackgroundColorByQuoteStatus(status);
    const dateStr = rowData.dateOfConfirmation || rowData.dateOfAcceptance;
    const reasonRejectedWithSymbol = rowData.reasonRejected
      && !!rowData.reasonRejected.length
      && rejectedReasonTextUtil(rowData.reasonRejected)
    return (
      <div>
        <div
          style={{
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            textTransform: "uppercase",
            fontWeight: "bold"
          }}
        >
          {rowData.price != null && rowData.status === ORDER_STATUS.VERIFYING
            ? "Processing"
            : renderTooltipStatus(status)}
        </div>
        {status === ORDER_STATUS.ACCEPTED && (
          <div
            style={{
              fontWeight: "bold",
              fontSize: "0.75rem",
              whiteSpace: "nowrap",
              textAlign: 'center',
              width: '130px'
            }}
          >
            {dateStr
              ? `Delivery Date: ${getDateStrWithMonth(rowData.collectionDate)}`
              : ""}
          </div>
        )}
        {status === ORDER_STATUS.REJECTED && reasonRejectedWithSymbol && (
          <div style={{
            display: 'flex',
            width: '150px',
            fontSize: '11px',
            fontWeight: '500',
            textAlign: 'center'
          }}>
            {reasonRejectedWithSymbol}
          </div>
        )}
      </div>
    );
  },
  width: 180,
}

// export const poAcknowledgeColumn = {
//   title: 'PO Acknowledged',
//   exportData: rowData => {
//     if (rowData.poAcknowledged === null) {
//       return 'N.A.';
//     }
//     if (Boolean(rowData.poAcknowledged) === true) {
//       return 'Yes';
//     } else if (Boolean(rowData.poAcknowledged) === false) {
//       return 'No';
//     } else {
//       return 'N.A.';
//     }
//   },
//   render: rowData => {
//     if (rowData.poAcknowledged === null) {
//       return 'N.A.';
//     }
//     if (Boolean(rowData.poAcknowledged) === true) {
//       return (
//         <span style={{ color: 'green' }}>Yes</span>
//       );
//     } else if (Boolean(rowData.poAcknowledged) === false) {
//       return (
//         <span style={{ color: 'orange' }}>No</span>
//       );
//     } else {
//       return 'N.A.';
//     }
//   },
//   cellStyle: {
//     textAlign: 'center',
//   },
// }

// export const originalQuoteWithCurrencyColumn = {
//   title: "Original Quote",
//   render: rowData => {
//     return convertPriceToCurrency(
//       {
//         price: rowData.originalQuote,
//         currency: rowData.currency,
//         exchangeRate: 1,
//       },
//       ''
//     );
//   },
//   cellStyle: {
//     minWidth: 150,
//   },
// }

// export const originalQuoteColumn = {
//   title: "Original Quote",
//   render: rowData => {
//     return convertPriceToCurrency(
//       {
//         price: rowData.priceBidded,
//         currency: CURRENCY_CODE.SGD,
//         exchangeRate: 1,
//       },
//       ''
//     );
//   },
//   cellStyle: {
//     minWidth: 150,
//   },
// }

// export const totalPriceShownToCustomerWithOriginalCurrencyCol = {
//   title: "Total Price shown to customer (excl. Factorem GST)",
//   render: rowData => {
//     return rowData.totalPrice
//       ? convertPriceToCurrency(
//         {
//           price: rowData.totalPrice,
//           currency: rowData.currency,
//           exchangeRate: 1,
//         },
//         ''
//       )
//       : '';
//   },
//   cellStyle: {
//     minWidth: 150,
//   },
// }

// export const totalPriceShownToCustomerCol = {
//   title: "Total Price shown to customer (excl. Factorem GST)",
//   render: rowData => {
//     const totalPrice = rowData.totalPrice
//       ? convertPriceToCurrency(
//         {
//           price: rowData.totalPrice,
//           currency: CURRENCY_CODE.SGD,
//           exchangeRate: 1,
//         },
//         ''
//       )
//       : '';
//     const discountAmount = getDiscountAmountUtil(rowData)
//     return (
//       <div>
//         <div style={{ color: FACTOREM_BLUE }}>
//           {totalPrice}
//         </div>
//         {discountAmount === "N.A." ? null : (
//           <div style={{ color: colors.errorRed, fontSize: '0.9rem' }}>{discountAmount}</div>
//         )}
//       </div>
//     )
//   },
//   exportData: rowData => {
//     return rowData.totalPrice
//       ? convertPriceToCurrency(
//         {
//           price: rowData.totalPrice,
//           currency: CURRENCY_CODE.SGD,
//           exchangeRate: 1,
//         },
//         ''
//       )
//       : ''
//   },
//   cellStyle: {
//     minWidth: 200,
//     textAlign: 'center',
//   },
// }

// export const platformFeeCol = {
//   title: 'Platform Fee',
//   render: (rowData) =>
//     convertPriceToCurrency({
//       price: rowData.platformFee,
//       currency: CURRENCY_CODE.SGD,
//       exchangeRate: 1,
//     }),
//   exportData: (rowData) =>
//     convertPriceToCurrency({
//       price: rowData.platformFee,
//       currency: CURRENCY_CODE.SGD,
//       exchangeRate: 1,
//     }),
// };

// export const threeDTechnologyCol = {
//   title: '3D Printing Technology',
//   render: rowData => {
//     const data = get(rowData.metadata, 'threeDTechnology') || '';
//     return data;
//   },
// }

// export const threeDInfillCol = {
//   title: '3D Infill',
//   render: rowData => {
//     const data = get(rowData.metadata, 'threeDInfill') || '';
//     return isEmptyValue(data) ? '' : `${Number(data * 100).toFixed(2)}%`;
//   },
// }

// export const threeDLayerThicknessCol = {
//   title: '3D Layer Thickness',
//   render: rowData => {
//     const data = get(rowData.metadata, 'threeDLayerThickness') || '';
//     return isEmptyValue(data) ? '' : `${data}mm`;
//   },
// }

// const renderYes = () => {
//   return <span style={{ color: 'green' }}>Yes</span>;
// }

// const renderRocketQuoteBadgeTooltip = (rowData) => {
//   const { numOfPpeQuotes, adminEmail } = rowData;
//   const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center'
//       }}
//     >
//       <Tooltip
//         title={`${numOfPpeQuotes} ${supplierText} quoted`}
//         arrow
//         placement='top'
//       >
//         <Badge badgeContent={numOfPpeQuotes} color="error">
//           {renderYes()}
//         </Badge>
//       </Tooltip>
//       {adminEmail && (
//         <Typography>({adminEmail})</Typography>
//       )}
//     </div>
//   );
// }

// const renderRocketQuoteYesWithAdminInfo = (rowData) => {
//   const { adminEmail } = rowData;
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center'
//       }}
//     >
//       <span style={{ color: 'green' }}>Yes</span>
//       {adminEmail && (
//         <Typography>({adminEmail})</Typography>
//       )}
//     </div>
//   );
// }

// export const rocketQuoteInfoCol = {
//   title: "Rocket Quote",
//   exportData: rowData => {
//     if (!isRocketQuote(rowData)) {
//       return 'No';
//     }
//     return 'Yes';
//   },
//   render: rowData => {
//     if (!isRocketQuote(rowData)) {
//       return <span style={{ color: 'orange' }}>No</span>;
//     }
//     const { numOfPpeQuotes } = rowData;
//     return numOfPpeQuotes
//       ? renderRocketQuoteBadgeTooltip(rowData)
//       : renderRocketQuoteYesWithAdminInfo(rowData);
//   },
//   cellStyle: {
//     textAlign: 'center',
//   },
// }

export const supplierPriceStrDisplayCol = ({ currency, exchangeRate }) => ({
  headerName: "Price",
  renderCell: params => {
    const rowData = params.row;
    let totalPriceStr;
    if (isPpeQuote(rowData) || rowData.currency !== currency) {
      const { totalPriceStr: convertedTotalPriceStr } = convertPriceWithQuantityToCurrency({
        totalPrice: rowData.priceBidded ?? 0,
        quantity: rowData.quantity,
        currency,
        exchangeRate: getQuotationExchangeRate(rowData, currency) || exchangeRate,
      });
      totalPriceStr = convertedTotalPriceStr;
    } else {
      totalPriceStr = convertPriceToCurrency({
        price: rowData.originalQuote ?? rowData.priceBidded ?? 0,
        currency: rowData.currency,
        exchangeRate: 1,
      });
    }
    return totalPriceStr;
  },
  with: 150,
});

// export const repeatOrderInfoCol = {
//   title: 'Repeat Order',
//   exportData: (rowData) => getIsRepeatOrder(rowData.instantQuotesLogMeta) ? 'Yes' : 'No',
//   render: (rowData) =>
//     getIsRepeatOrder(rowData.instantQuotesLogMeta) ? (
//       <span style={{ color: 'green' }}>Yes</span>
//     ) : (
//       <span style={{ color: 'orange' }}>No</span>
//     ),
// };

import { Badge } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles(() => ({
  badge: {
    '& .MuiBadge-badge': {
      top: '50%',
    }
  }
}));

const ActionButtonBadge = ({children, badgeContent, showBadgeCounter}) => {
  const classes = useStyles();

  return showBadgeCounter ? (
    <Badge badgeContent={badgeContent} color="error" className={classes.badge}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
}

export default ActionButtonBadge;

import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import {
  ACCESS_TOKEN_KEY,
  BACKEND_SERVICE_URL,
  CURRENCY,
  DEFAULT_MYR_EXCHANGE_RATE,
  DEFAULT_USD_EXCHANGE_RATE,
  DEFAULT_AUD_EXCHANGE_RATE,
  ROLE_TYPES,
} from "../constants";
import { fetchWithTimeout, getAuthorizedHeader } from "../utils/apiUtils";
import { notifyError, notifySuccess } from '../services/notificationService';
import { getExchangeRates } from "../apis/exchangeRatesApi";
import { isEmpty } from 'lodash';
import jwt_decode from 'jwt-decode';

import { getUserInfo } from "../apis/userApi";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const RETRIEVE_LOCATION_SUCCESS = "RETRIEVE_LOCATION_SUCCESS";
export const SET_LOCATION_SUCCESS = "SET_LOCATION_SUCCESS";
export const SET_COUNTRY_SUCCESS = "SET_COUNTRY_SUCCESS";
export const RETRIEVE_EXCHANGE_RATE_SUCCESS = "RETRIEVE_EXCHANGE_RATE_SUCCESS";
export const SET_EXCHANGE_RATES_SUCCESS = "SET_EXCHANGE_RATES_SUCCESS";

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user
  };
};

const resetEmailSent = props => {
  return {
    type: RESET_SUCCESS
  };
};

const resetEmailFail = () => {
  toast.error("Incorrect email address. Please try again!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: RESET_FAIL
  };
};

const loginError = (message) => {
  toast.error(message || "Incorrect username or password. Please try again!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: LOGIN_FAILURE
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const retrieveLocation = location => {
  return {
    type: RETRIEVE_LOCATION_SUCCESS,
    location,
  };
};

const setLocation = location => {
  return {
    type: SET_LOCATION_SUCCESS,
    location,
  };
};

const setCountry = payload => {
  return {
    type: SET_COUNTRY_SUCCESS,
    payload,
  };
};

const setExchangeRates = rates => {
  return {
    type: SET_EXCHANGE_RATES_SUCCESS,
    rates,
  };
};

const retrieveExchangeRates = rates => {
  return {
    type: RETRIEVE_EXCHANGE_RATE_SUCCESS,
    rates,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

const openVerifyEmailPage = (props, email) => {
  const path = isEmpty(email)
    ? '/verify-email'
    : `/verify-email?email=${email}`;
  props.history.push(path);
  toast.error("Please verify your email address before logging in!", {
    position: toast.POSITION.TOP_RIGHT
  });
};

const cookies = new Cookies();

export const logoutUser = () => dispatch => {
  dispatch(requestLogout());
  try {
    cookies.remove("token", { path: "/" });
    if (!cookies.get("token")) {
      dispatch(receiveLogout());
    }
  } catch (error) {
    dispatch(logoutError());
  }
};

export const resetAccount = (email, props) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/reset`;

  let data = {
    email: email,
    isOnboarding: true
  };

  fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(function (response) {
    response
      .json()
      .then(res => {
        if (res.success) {
          dispatch(resetEmailSent(props));
        } else {
          dispatch(resetEmailFail());
        }
      })
      .catch(function () {
        dispatch(resetEmailFail());
      });
  });
};

export const retrieveUserLocation = (userID) => dispatch => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}/currency`;
  fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
    .then(response => {
      if (!response.ok) {
        const modifiedLocation = {
          currency: CURRENCY.SGD,
        };
        dispatch(retrieveLocation(modifiedLocation));
      } else {
        response.json().then(({ data }) => {
          const modifiedLocation = {
            currency: data?.currency || CURRENCY.SGD,
          };
          dispatch(retrieveLocation(modifiedLocation));
        });
      }
    })
    .catch(() => {
      // unable to retrieve location
      const modifiedLocation = {
        currency: CURRENCY.SGD,
      };
      dispatch(retrieveLocation(modifiedLocation));
    });
};

export const retrieveExchangeRate = () => (dispatch) => {
  getExchangeRates()
    .then(function (response) {
      let rates = {
        ...response.exchangeRates['exchange_rates'],
      };
      dispatch(retrieveExchangeRates(rates));
    })
    .catch(() => {
      dispatch(retrieveExchangeRates({
        MYR: DEFAULT_MYR_EXCHANGE_RATE,
        USD: DEFAULT_USD_EXCHANGE_RATE,
        SGD: 1,
        AUD: DEFAULT_AUD_EXCHANGE_RATE,
      }));
    });
};

export const updateUserCurrency = (userID, currency) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}/currency`;
  fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ currency }),
  })
    .then(response => {
      if (!response.ok) {
        notifyError(`Can't update user currency`);
      } else {
        const location = {
          currency,
        };
        dispatch(setLocation(location));
      }
    })
    .catch(() => {
      notifyError(`Can't update user currency`);
    });
};

export const updateUserCountryAndCurrency = (userID, { country, currency }) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}`;
  fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ country, currency }),
  })
    .then(response => {
      if (!response.ok) {
        notifyError(`Updated country and currency failed`);
      } else {
        dispatch(setLocation({ currency }));
        dispatch(setCountry({ country }));
        notifySuccess('Updated country and currency successfully');
      }
    })
    .catch(() => {
      notifyError(`Updated country and currency failed`);
    });
};

export const setExchangeRateManually = (rates) => dispatch => {
  dispatch(setExchangeRates(rates));
};

export const loginUser = (email, password, props) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/login`;
  let data = {
    email,
    password,
  };
  return fetchWithTimeout(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    timeout: 30000, // 30 seconds
  })
    .then(function (response) {
      dispatch(requestLogin());
      response.json().then(user => {
        if (response.status === 403) {
          // Unverified email
          dispatch(openVerifyEmailPage(props, email));
        }

        if (response.status === 200 && user.accessToken) {
          //Successful response
          cookies.set(ACCESS_TOKEN_KEY, user.accessToken, {
            path: "/",
            maxAge: 24 * 60 * 60, // 1 day in seconds
          });
          dispatch(receiveLogin(user));
          dispatch(retrieveExchangeRates({
            MYR: DEFAULT_MYR_EXCHANGE_RATE,
            USD: DEFAULT_USD_EXCHANGE_RATE,
            SGD: 1,
            AUD: DEFAULT_AUD_EXCHANGE_RATE,
          }));
          dispatch(retrieveUserLocation(user.userID));
        } else {
          // Error message for unverified suppliers
          if (user.name === 'unverifiedUser') {
            dispatch(loginError("Account pending verification. Please contact onboarding@factorem.co if you need any assistance."));
          //Incorrect username or password
          } else {
            dispatch(loginError());
          }
        }
      });
    })
    .catch(function (err) {
      if (err.name === 'AbortError') {
        dispatch(loginError('Unexpected error occurred. Please contact our admin for details.'));
      } else {
        dispatch(loginError());
      }
      throw err;
    });
};

export const verifyAuth = () => dispatch => {
  try {
    const token = cookies.get(ACCESS_TOKEN_KEY);
    if (token) {
      const decodedToken = jwt_decode(token);
      const { userID } = decodedToken;
      dispatch(verifyRequest());
      // this will check and patch user's data from server, will replace if data has been modify from local browser's storage
      getUserInfo(userID)
        .then(user => {
          dispatch(receiveLogin(user));
          dispatch(verifySuccess());
        })
        .catch(error => {
          dispatch(receiveLogout());
        })
    } else {
      dispatch(receiveLogout());
    }
  } catch (err) {
    // throw err
  }
};

// Import settings
import React, { Fragment, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

// Import color palette
import { colors, FACTOREM_BLUE } from "../palette";

// Import material UI components
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { makeStyles } from "tss-react/mui";

import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

// Import actions
import { loginUser } from "../actions/index";

import factoremLogoSmall from "../assets/img/factorem-logo-with-name-small.png";
import factoremLogoWhite from "../assets/img/factorem-logo-white.png";

// Import customized components
import { REDIRECT_URL_KEY } from "../constants/cookiesConstants";
import { getCookie, removeCookie } from "../services/cookiesService";
import { notifyError } from "../services/notificationService";
import Slideshow from "../components/Slideshow";
import Description from "../components/Description";
import { ROLE_TYPES } from "../constants";

const useStyles = makeStyles()((theme) => ({
  login: {
    marginTop: 12,
    width: "100%",
    fontSize: "14pt",
    fontWeight: "bold",
    textTransform: "none",
    background: colors.buttonBlue,
    color: colors.fontWhite,
    "&:hover": {
      backgroundColor: FACTOREM_BLUE,
    },
  },
  inputField: {
    borderRadius: 5,
    marginBottom: "0.95rem",
    background: colors.mainLightBlue,
    width: "100%",
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.fontBlack,
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: "30pt",
    letterSpacing: "-0.6px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    fontSize: "14pt",
    marginBottom: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12pt",
      textAlign: "center",
    },
  },
  forgotPassword: {
    float: "right",
    fontSize: "11pt",
    color: colors.buttonBlue,
    textDecoration: "none",
    ".visited": {
      color: colors.buttonBlue,
    },
    "&:hover": {
      color: FACTOREM_BLUE,
    },
  },
  container: {
    height: "100%",
    overflow: "hidden",
    flexWrap: "nowrap",
    position: "relative",
    alignItems: "center",
    backgroundColor: FACTOREM_BLUE,
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
      backgroundSize: "cover",
      backgroundImage: colors.mobileBackground,
    },
  },
  video: {
    left: "25%",
    height: "100%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
  },
  form: {
    height: "100%",
    position: "relative",
    alignItems: "center",
    background: colors.mainLightBlue,
    [theme.breakpoints.down("sm")]: {
      background: "none",
    },
  },
  others: {
    width: "100%",
    marginTop: "5px",
    fontSize: "14pt",
    fontWeight: "bold",
    textTransform: "none",
    color: colors.fontGrey,
    background: colors.fontWhite,
    border: `solid 1px ${colors.fontGrey}`,
  },
  mobile: {
    fontSize: "10pt",
    paddingTop: "2rem",
    color: colors.fontWhite,
  },
  paper: {
    display: "flex",
    borderRadius: 10,
    padding: "1.75rem 1.55rem",
    flexDirection: "column",
    boxShadow: "0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)",
  },
  partner: {
    top: 10,
    right: 20,
    zIndex: 1,
    fontWeight: 600,
    fontSize: "12pt",
    position: "absolute",
    color: FACTOREM_BLUE,
    textTransform: "none",
    "&:hover": {
      background: "none",
      color: colors.fontBlackGrey,
    },
    [theme.breakpoints.down("sm")]: {
      top: "2.3rem",
    },
  },
}));

function Login(props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let navigate = useNavigate();

  const { isAuthenticated, loginUser, role } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (
      isAuthenticated &&
      (role === ROLE_TYPES.ADMIN ||
        role === ROLE_TYPES.SUPER_ADMIN ||
        role === ROLE_TYPES.ONBOARDING_SUPPLIER || 
        role === ROLE_TYPES.SUPPLIER)
    ) {
      const redirectUrl = getCookie(REDIRECT_URL_KEY);
      if (!isEmpty(redirectUrl)) {
        removeCookie(REDIRECT_URL_KEY, { path: "/" });
        navigate(redirectUrl, { replace: true });
      }
    } else if (!isAuthenticated) {
      {
        // user currently not authenticated, do nothing
      }
    } else {
      navigate("/login", { replace: true });
      notifyError("Your account type cannot login to onboarding.factorem.co");
    }
  }, [isAuthenticated]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(email, password);
  };

  const renderLoginForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography className={classes.title}>Login</Typography>
        <Typography className={classes.subtitle}>
          to continue your manufacturing journey
        </Typography>
        <Typography className={classes.inputLabel}>Email</Typography>
        <TextField
          id="email"
          name="email"
          autoFocus
          className={classes.inputField}
          onChange={handleEmailChange}
          variant="outlined"
          size="small"
        />
        <Typography className={classes.inputLabel}>
          Password
          <a href="/reset" className={classes.forgotPassword}>
            Forgot Password?
          </a>
        </Typography>
        <TextField
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          className={classes.inputField}
          onChange={handlePasswordChange}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          margin="normal"
          className={classes.login}
        >
          Login
        </Button>
        <Box
          style={{
            height: "2rem",
          }}
        ></Box>
        <Divider
          style={{
            display: "flex",
            alignItems: "center",
            color: colors.fontGrey,
            fontWeight: "bold",
          }}
        >
          or
        </Divider>
        <div
          style={{
            width: "100%",
            marginTop: 30,
            fontSize: "10pt",
          }}
        >
          <Button
            type="button"
            className={classes.others}
            component={Link}
            to="/signup"
          >
            Create an Account
          </Button>
        </div>
      </form>
    );
  };

  const renderAdditionalInfo = () => {
    return (
      <Grid container direction="column" spacing={1} className={classes.mobile}>
        <Description size={4} />
      </Grid>
    );
  };

  const renderPartnerButton = () => {
    return (
      <Button
        type="button"
        className={classes.partner}
        component={Link}
        to="/signup"
      >
        Become a Partner
      </Button>
    );
  };

  return (
    <Grid container className={classes.container}>
      {isAuthenticated && <Navigate to="/" />}
      <Grid item sm={6}>
        {renderPartnerButton()}
        {!isMobile ? (
          <Fragment>
            <a href="https://www.factorem.co" rel="noreferrer" target="_blank">
              <img
                src={factoremLogoWhite}
                alt="icon"
                style={{
                  height: "4rem",
                  position: "absolute",
                  top: "2rem",
                  left: "4rem",
                  zIndex: 2,
                }}
              />
            </a>
            <Slideshow />
          </Fragment>
        ) : (
          <a href="https://www.factorem.co" rel="noreferrer" target="_blank">
            <img
              src={factoremLogoSmall}
              alt="icon"
              style={{
                height: "3rem",
                position: "absolute",
                top: "2rem",
                left: "2rem",
                zIndex: 2,
              }}
            />
          </a>
        )}
      </Grid>
      <Grid
        container
        component="main"
        justifyContent="center"
        className={classes.form}
      >
        <div
          style={{
            padding: isMobile ? "7rem 5% 5rem 5%" : "0 25% 0 25%",
          }}
        >
          {isMobile ? (
            <Fragment>
              <Paper className={classes.paper}>{renderLoginForm()}</Paper>
              {renderAdditionalInfo()}
            </Fragment>
          ) : (
            renderLoginForm()
          )}
        </div>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth?.isLoggingIn,
    loginError: state.auth?.loginError,
    isAuthenticated: state.auth?.isAuthenticated,
    role: state.auth?.user?.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(Login);

// Import settings
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { Button } from "@mui/material";

import { 
  DataGrid,
  GridActionsCellItem,
  GridRowModes, 
} from '@mui/x-data-grid';

import { useNavigate } from "react-router-dom";

import { makeStyles } from "tss-react/mui";
import { PageTitle } from "../components/PageTitle";
import { getAllOnboardingItems } from "../actions";
import { CustomToolbar } from "../components/CustomToolbar";

const useStyles = makeStyles()(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3)
  },
  wrapCellContent: {
    whiteSpace: 'pre-line',
    wrap: true,
    wordBreak: 'break-word',
    padding: '10px',
  },
}));

export function ManageItems(props) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [rowModesModel, setRowModesModel] = useState({});

  const {
    items,
    onPageLoad,
    deleteItem,
  } = props;

  useEffect(() => {
    onPageLoad();
  }, []);

  function handleEditClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  function handleSaveClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  function handleDeleteClick(id) {
    deleteItem(id);
  };

  function handleCancelClick(id) {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => navigate(`/edit-item/${id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    { field: 'id', headerName: 'ID', width: 70,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.id}
        </div>
      ), 
    },
    { field: 'referenceName', headerName: 'Name', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.referenceName}
        </div>
      ), 
    },
    { field: 'quantity', headerName: 'Quantity', width: 100 },
    { field: 'technology', headerName: 'Technology', width: 200,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.technology}
        </div>
      ), 
    },
    { field: 'material', headerName: 'Material', width: 200,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.material}
        </div>
      ), 
    },
    { field: 'setThreeDTechnology', headerName: '3D Technology', width: 200,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.threeDTechnology || 'NIL'}
        </div>
      ), 
    },
    { field: 'surfaceFinish', headerName: 'Surface Finish', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.surfaceFinish}
        </div>
      ), 
    },
    { field: 'color', headerName: 'Colour', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.color || 'NIL'}
        </div>
      ), 
    },
    { field: 'materialColor', headerName: 'Material Colour', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.materialColor || 'NIL'}
        </div>
      ), 
    },
    { field: 'threeDInfill', headerName: '3D Infill', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.threeDInfill || 'NIL'}
        </div>
      ), 
    },
    { field: 'threeDLayerThickness', headerName: '3D Layer Thickeness', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.threeDLayerThickness || 'NIL'}
        </div>
      ), 
    },
    { field: 'otherTechnology', headerName: 'Other Technology', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.otherTechnology || 'NIL'}
        </div>
      ), 
    },
    { field: 'otherMaterial', headerName: 'Other Material', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.otherMaterial || 'NIL'}
        </div>
      ), 
    },
    { field: 'otherSurfaceFinish', headerName: 'Other Surface Finish', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.otherSurfaceFinish || 'NIL'}
        </div>
      ), 
    },
    { field: 'tolerance', headerName: 'Tolerance', width: 150,
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.tolerance || 'NIL'}
        </div>
      ), 
    },
    { field: 'leadTime', headerName: "Lead Time (days)", width: 150 },
    { field: 'qcReportTemplate', headerName: "QC Report Template (Link)", width: 200,
      renderCell: (params) => (
          <div className={classes.wrapCellContent}>
            <Link to={params.row.qcReportTemplate} target="_blank" rel="noopener noreferrer">{'View'}</Link>
          </div>
      ), 
    },
    { field: 'description', headerName: "Description", width: 200, 
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.description || 'NIL'}
        </div>
      ),
    },
    { field: 'techDrawing', headerName: "Technical Drawing", width: 200, 
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.techDrawing || 'NIL'}
        </div>
      ),
    },
    { field: 'cadFileExtension', headerName: "CAD File Extension", width: 200, 
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.cadFileExtension || 'NIL'}
        </div>
      ),
    },
    { field: 'cadFileImagePreview', headerName: "CAD File Image Preview", width: 200, 
      renderCell: (params) => (
        <div className={classes.wrapCellContent}>
          {params.row.cadFileImagePreview || 'NIL'}
        </div>
      ),
    },
  ];

  const customToolbarWithAddNewItem = () => {
    return (
      <CustomToolbar>
        <Button
          startIcon={<AddIcon />}
          size="small"
          onClick={() => navigate("/add-new-item")}
        >
          Add New Item
        </Button>
      </CustomToolbar>
    );
  }
  
  return (
    <div>
      <PageTitle title="Manage Items" />
      <div style={{ 
        width: "80vw", 
        overflow: "scroll",
        marginLeft: "15px",
        marginRight: "15px",
      }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={items}
          columns={columns}
          editMode="row"
          getRowId={(row) => row.id}
          rowModesModel={rowModesModel}
          slots={{ toolbar: customToolbarWithAddNewItem }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10,20,50]}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    items: state.items.items,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: () => dispatch(getAllOnboardingItems()),
    // deleteItem: (itemID) => dispatch(deletePart(itemID))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ManageItems);

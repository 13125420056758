// Import settings
import React, { useEffect, useState } from 'react';

import {
  Chip,
} from '@mui/material';

import {
  LooksOne as LooksOneIcon,
  LooksTwo as LooksTwoIcon,
  Looks3 as Looks3Icon,
  Looks4 as Looks4Icon,
  Looks5 as Looks5Icon,
} from "@mui/icons-material";

/**
 * @param {Object} props
 * @param {String} props.step 
 */
function StepChip(props) {
  const {
    step
  } = props;

  const [icon, setIcon] = useState(<LooksOneIcon/>);
  const [color, setColor] = useState("error");

  useEffect(() => {
    switch (step) {
      case "Initiation":
        setColor("error");
        setIcon(<LooksOneIcon color="error"/>);
        break;
      case "Fabrication":
        setColor("warning");
        setIcon(<LooksTwoIcon color="warning"/>);
        break;
      case "Delivery":
        setColor("info");
        setIcon(<Looks3Icon color="info"/>);
        break;
      case "Assessment":
        setColor("secondary");
        setIcon(<Looks4Icon color="secondary"/>);
        break;
      case "Onboard":
        setColor("success");
        setIcon(<Looks5Icon color="success"/>);
        break;
      default:
        setColor("error");
        setIcon(<LooksOneIcon color="error"/>);
        break;
    }
  }, [step]);

  return (
    <Chip icon={icon} size="small" color={color} variant="outlined" label={step}/>
  );
}

export default StepChip;
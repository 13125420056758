import { Cookies } from 'react-cookie';

export const GET_MYORDERS_LOADING = "GET_MYORDERS_LOADING";
export const GET_MYORDERS_SUCCESS = "GET_MYORDERS_SUCCESS";
export const GET_MYORDERS_FAILURE = "GET_MYORDERS_FAILURE";

const getMyOrders = () => {
  return {
    type: GET_MYORDERS_LOADING,
  };
}

const getMyOrdersSuccess = myorders => {
  return {
    type: GET_MYORDERS_SUCCESS,
    myorders,
  };
}

const getMyOrdersFailure = () => {
  return {
    type: GET_MYORDERS_FAILURE,
  };
}

const cookies = new Cookies();

export const getAllMyOrders = () => (dispatch, getState) => {
  const userId = getState().auth.user.userID;
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/quotations/${userId}?isOnboarding=true`;
  dispatch(getMyOrders());
  return fetch(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
  }).then(
    function (response) {
      dispatch(getMyOrders());
      response.json().then(
        myorders => {
          dispatch(getMyOrdersSuccess(myorders.data));
        }
      );
    }
  ).catch(function() {
    dispatch(getMyOrdersFailure());
  });
};

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { InputAdornment, TextField, Typography } from '@mui/material';

import {
  // QC_FORMAL_CMM_REPORT,
  QC_REPORTS_ALL_OPTIONS,
} from '../../constants/projectConstants';
import InfoIcon from '../icons/InfoIcon';
import { getQcReportsList } from '../../utils/projectUtils';
// import { getCurrencySymbol } from '../../utils/currencyUtils';

function QcReportsDisplay(props) {
  const {
    qcReports,
    onChange,
    isError,
    disabled = false,
    value,
    showTextField = false,
    currency
  } = props;

  const [qcReportList, setQcReportList] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const [cmmPriceError, setCmmPriceError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    setIsDisabled(disabled);
    setInputValue(value);
  }, [value, disabled]);

  useEffect(() => {
    if (isError && !inputValue) {
      setCmmPriceError('Required');
    } else {
      setCmmPriceError(null);
    }
  }, [isError, inputValue]);

  useEffect(() => {
    const _qcReportList = getQcReportsList(qcReports);
    if (!isEmpty(_qcReportList)) {
      setQcReportList(_qcReportList);
    }
  }, [qcReports]);

  return (
    <div>
      {qcReportList.map((report) => {
        const option =
          QC_REPORTS_ALL_OPTIONS.find((o) => o.name === report) || {};
        const { tooltip } = option;
        return (
          <div
            key={report}
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '5px',
            }}
          >
            &#8226;<Typography>{report}</Typography>
            {tooltip && <InfoIcon toolTipText={tooltip} />}
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
  };
}

const withConnect = connect(mapStateToProps);

export default /* withRouter(*/withConnect(QcReportsDisplay);//);

// Import settings
import React from "react";

// Import color palette
import { colors } from "../palette";

// Import material UI components
import {
    Accordion as MuiExpansionPanel,
    AccordionDetails as MuiExpansionPanelDetails,
    AccordionSummary as MuiExpansionPanelSummary,
    Typography,
} from "@mui/material";
import {
    withStyles,
} from 'tss-react/mui';
import {
    Add as AddIcon,
    Remove as RemoveIcon
} from '@mui/icons-material';

const ExpansionPanel = withStyles(MuiExpansionPanel, () => ({
    root: {
        boxShadow: 'none',
        "&:not(:last-child)": {
            borderBottom: 0
        },
        "&:before": {
            display: 'none'
        },
        "&$expanded": {
            margin: 'auto'
        }
    },
    expanded: {}
}));

const ExpansionPanelSummary = withStyles(
  MuiExpansionPanelSummary,
  (theme, props) => ({
    root: {
        borderBottom: colors.expansionPanelBorder,
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56
        }
    },
    content: {
        "&$expanded": {
            margin: '12px 0'
        }
    },
    expanded: {}
}));

const ExpansionPanelDetails = withStyles(
    MuiExpansionPanelDetails,
    (theme, props) => ({
      root: {
        padding: theme.spacing(2),
      },
    })
);

export default function FAQExpansionPanel({ faqQn, faqAns, expanded, onChange }) {

    return (
        <div>
            <ExpansionPanel
                square
                expanded={expanded}
                onChange={onChange}
            >
                <ExpansionPanelSummary
                    expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
                >
                    <Typography style={{
                        color: expanded ? `${colors.blue060}` : `${colors.fontGrey}`,
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        letterSpacing: '0.15em'
                    }}>
                        <span style={{ marginRight: 10 }}>⬤</span>
                        {faqQn}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography style={{
                        color: `${colors.fontGrey}`,
                        fontFamily: 'Open Sans',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '1rem',
                        letterSpacing: '0.15em',
                        paddingLeft: 35
                    }}>{faqAns}</Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div >
    );
}

import {
  GET_ITEM_FAILURE,
  GET_ITEM_SUCCESS,
  GET_ITEM_LOADING,
  CREATE_QUOTATION_LOADING,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_FAILURE,
  EDIT_ITEM_FAILURE,
  EDIT_ITEM_LOADING,
  EDIT_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_LOADING,
  DELETE_ITEM_SUCCESS,
  SAVE_ORDERED_AGAIN_PARTS,
  DELETE_ORDERED_AGAIN_PARTS,
  SAVE_PART_SUMMARY_DETAILS,
  UPDATE_2D_IMAGE_URL,
  UPDATE_UPLOAD_PROJECT_FILES_STEPS,
} from '../actions';
import { LOGOUT_SUCCESS } from '../actions/';
import { TWO_D_IMAGE_URLS_KEY } from '../constants';

const defaultState = {
  getItemLoading: false,
  getItemSuccess: false,
  getItemFailure: false,
  item: [],
  orderedAgainPartDetails: null,
  formDataAvailable: null,
  uploadProjectFilesSteps: [],
  createQuotationLoading: false,
  createQuotationSuccess: false,
  createQuotationFailure: false,
  editItemFailure: false,
  editItemSuccess: false,
  editItemLoading: false,
  deleteItemLoading: false,
  deleteItemSuccess: false,
  deleteItemFailure: false,
};

export default function item(state = defaultState, action) {
  switch (action.type) {
    case GET_ITEM_LOADING:
      return {
        ...state,
        getItemLoading: true,
        getItemSuccess: false,
        getItemFailure: false,
      };
    case GET_ITEM_SUCCESS:
      return {
        ...state,
        getItemLoading: false,
        getItemSuccess: true,
        getItemFailure: false,
        item: {
          ...action.item,
          ...action.item.metadata,
        },
      };
    case GET_ITEM_FAILURE:
      return {
        ...state,
        getItemLoading: false,
        getItemSuccess: false,
        getItemFailure: true,
      };
    case CREATE_QUOTATION_LOADING:
      return {
        ...state,
        createQuotationLoading: true,
        createQuotationSuccess: false,
        createQuotationFailure: false,
      };
    case CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        createQuotationLoading: false,
        createQuotationSuccess: true,
        createQuotationFailure: false,
      };
    case CREATE_QUOTATION_FAILURE:
      return {
        ...state,
        createQuotationLoading: false,
        createQuotationSuccess: false,
        createQuotationFailure: true,
      };
    case EDIT_ITEM_SUCCESS:
      return {
        ...state,
        editItemFailure: false,
        editItemSuccess: true,
        editItemLoading: false,
      };
    case EDIT_ITEM_LOADING:
      return {
        ...state,
        editItemFailure: false,
        editItemSuccess: false,
        editItemLoading: true,
      };
    case EDIT_ITEM_FAILURE:
      return {
        ...state,
        editItemFailure: true,
        editItemSuccess: false,
        editItemLoading: false,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        deleteItemLoading: false,
        deleteItemSuccess: true,
        deleteItemFailure: false,
      };
    case DELETE_ITEM_FAILURE:
      return {
        ...state,
        deleteItemLoading: false,
        deleteItemSuccess: false,
        deleteItemFailure: true,
      };
    case DELETE_ITEM_LOADING:
      return {
        ...state,
        deleteItemLoading: true,
        deleteItemSuccess: false,
        deleteItemFailure: false,
      };
    case SAVE_ORDERED_AGAIN_PARTS:
      return {
        ...state,
        orderedAgainPartDetails: action.orderedAgainPartDetails,
      };
    case SAVE_PART_SUMMARY_DETAILS:
      return {
        ...state,
        formDataAvailable: action.formDataAvailable,
      };
    case UPDATE_2D_IMAGE_URL: {
      const { originalS3Key, twoDImageUrl } = action.payload;
      const newFormDataAvailable = [...state.formDataAvailable];
      const item = newFormDataAvailable.find((item) =>
        item.cadPart.includes(originalS3Key)
      );
      if (!item) {
        return state;
      }
      item[TWO_D_IMAGE_URLS_KEY] = [twoDImageUrl];
      return {
        ...state,
        formDataAvailable: newFormDataAvailable,
      };
    }
    case DELETE_ORDERED_AGAIN_PARTS:
      return {
        ...state,
        orderedAgainPartDetails: null,
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case UPDATE_UPLOAD_PROJECT_FILES_STEPS: {
      return {
        ...state,
        uploadProjectFilesSteps: [...action.payload],
      };
    }
    default:
      return state;
  }
};

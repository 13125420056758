import React, { Fragment, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";

import SolidBlueButton from "../components/buttons/SolidBlueButton";
import Subheader from "../components/Subheader";
import TestPartResultsTable from "../components/TestPartResultsTable";

import { Autocomplete, Card, Checkbox, Grid, IconButton, TextField, Typography, FormControlLabel, FormGroup } from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import UploadFileItem from "../components/cards/UploadFileItem";
import { notifyError, notifySuccess } from "../services/notificationService";
import { getAssessmentInfo } from "../actions";
import { 
  getSupplierInfoForESign,
  generatePartnershipAgreement,
  generateEmbeddedSingingLink,
  getESignFileForSupplier,
} from "../apis/onboardingApi";
import {
  sendAssessmentCompleteEmail,
  updateTestPartResults,
} from "../apis/assessmentApi";
import ItalicNote from "../components/texts/ItalicNote";
import {
  getAssessmentReportFileS3Key,
  getPartnershipAgreementFileS3Key,
  uploadFileToS3,
} from "../services/s3Service";
import { uploadPartnershipAgreementFile } from "../apis/itemApi";
import { getCurrentFullYearStr } from '../utils/dateTimeUtils';
import { convertToDigits } from '../utils/stringUtils';
import { isEmptyValue } from "../utils/commonUtils";
import { PAGE_KEYS, BUTTON_KEYS } from "../constants/onboardingAdminButtonsClickedConstants";
import { useButtonClicked } from "../hooks/useButtonClicked";
import { colors } from "../palette";

function AssessmentProgress(props) {
  const { user, onPageLoad, info } = props;
  const { userID } = user || {}
  const currDocNum = `${getCurrentFullYearStr()}${convertToDigits(userID)}`;

  useEffect(() => {
    onPageLoad(user.userID);
    // extract partnership agreement from info
    const tempObj = info.find((row) => row.partnershipAgreement);
    if (tempObj) {
      setPartnershipAgreement(tempObj.partnershipAgreement);
    }
  }, []);

  const [isQaqcTabOpen, setQaqcTabOpen] = useState(false);
  const [testPartsData, setTestPartsData] = useState(info);
  const [selectedRow, setSelectedRow] = useState(null);
  // todo: find a better way to handle file that has been retrieved from backend
  const [partnershipAgreement, setPartnershipAgreement] = useState(null);

  const [buttonsClicked, addButtonsClicked] = useButtonClicked(user.userID, PAGE_KEYS.ASSESSMENT);
  const [generatePartnershipAgreementButtonClicked, setGeneratePartnershipAgreementButtonClicked] = useState(false);
  const [adminInviteLink, setAdminInviteLink] = useState(null);

  const toggleQaqcTab = (itemID) => {
    setQaqcTabOpen(!isQaqcTabOpen);
    const filteredRow = testPartsData.find((row) => row.itemID === itemID);
    setSelectedRow(filteredRow);
  };

  const handleAssessmentReport = (file) => {
    let stringOfLinks = [];
    Promise.all(
      Array.from(file).map(async (f) => {
        const s3ObjectKey = getAssessmentReportFileS3Key(f, user.userID);
        return uploadFileToS3(f, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(" ").join("%20"));
            if (f !== selectedRow.assessmentReport) {
              setSelectedRow({
                ...selectedRow,
                assessmentReport: stringOfLinks[0],
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      })
    );
  };

  const handleNoNeedAssessmentReport = () => {
    setSelectedRow({
      ...selectedRow,
      noNeedAssessmentReport: !selectedRow.noNeedAssessmentReport,  // stored as tinyint in db
    });
  }

  // Left for future reference
  const handlePartnershipAgreement = (file) => {
    setPartnershipAgreement(file);
    // upload file to s3 and backend
    let stringOfLinks = [];
    Promise.all(
      Array.from(file).map(async (f) => {
        const s3ObjectKey = getPartnershipAgreementFileS3Key(f, user.userID);
        return uploadFileToS3(f, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(" ").join("%20"));
          })
          .catch((err) => {
            alert(err);
          });
      })
    )
      .then(async () => {
        const payload = {
          itemID: testPartsData[0].itemID,
          userID: user.userID,
          file: stringOfLinks[0],
        };
        await uploadPartnershipAgreementFile(payload);
        notifySuccess("File uploaded successfully!");
      })
      .catch((err) => {
        console.log(err);
        notifyError("Error uploading Partnership Agreement file");
      });
  };

  const handleFeedback = (feedback) => {
    if (feedback !== selectedRow.feedback) {
      setSelectedRow({ ...selectedRow, feedback: feedback });
    }
  };

  const handleResult = (res) => {
    if (res !== selectedRow.hasItemPassQC) {
      setSelectedRow((prevRow) => ({ ...prevRow, hasItemPassQC: res }));
    }
  };

  const onClickSaveButton = (itemID) => {
    const updatedData = testPartsData.map((row) => {
      if (row.itemID === itemID) {
        return selectedRow;
      }
      return row;
    });
    setTestPartsData(updatedData);
    const payload = {
      userID: user.userID,
      itemID: itemID,
      assessmentReport: selectedRow.assessmentReport,
      noNeedAssessmentReport: selectedRow.noNeedAssessmentReport,
      feedback: selectedRow.feedback,
      hasItemPassQC: selectedRow.hasItemPassQC,
    };
    updateTestPartResults(payload)
      .then(() => {
        setQaqcTabOpen(false);
        notifySuccess("Test part results successfully updated!");
      })
      .catch((err) => {
        console.log(err);
        notifyError("Failed to update test part results.");
      });
  };

  // Partnership Agreement related variables and functions
  const {
    data: supplierInfo = {},
  } = useQuery(
    ['getSupplierInfoForESign', userID],
    () => getSupplierInfoForESign({ supplierID: userID }),
  );

  const {
    email,
    companyName,
    address,
    country,
    companyRegistrationNumber
  } = supplierInfo;

  const {
    data: currPartnershipAgreementInfo = {},
  } = useQuery(
    ['getESignFileForSupplier', userID],
    () => getESignFileForSupplier({ supplierID: userID, docType: 'Partnership Agreement' }),
  );

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const infoComponent = (description, value) => {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Typography variant="body1" gutterBottom>
            {description}: {value} 
            <IconButton aria-label="copy" onClick={() => copyText(value)} size="small" style={{ margin: '0 0 0.2rem 0.2rem' }}>
              <FileCopyIcon fontSize="6px"/>
            </IconButton>
          </Typography>
        </div>
    );
  }

  const onClickGeneratePartnershipAgreement = async () => {
    try {
      const params = {
        supplierId: `${userID}`,
        companyName: `${companyName}`,
        docNum: `${currDocNum}`,
        partnerEmail: `${email}`,
      }
      setGeneratePartnershipAgreementButtonClicked(true);
      const inviteLink = await generatePartnershipAgreement(params);
      setAdminInviteLink(inviteLink);
    } catch (error) {
      // Handle any errors that occur during the link generation process
      console.error("Error generating partnership agreement link:", error);
    }
  }

  const regeneratePartnershipAgreementSigningLink = async (docID, adminFieldInviteID) => {
    try {
      const params = {
        docId: `${docID}`,
        fieldInviteId: `${adminFieldInviteID}`,
      }
      const inviteLink = await generateEmbeddedSingingLink(params);
      setAdminInviteLink(inviteLink);
    } catch (error) {
      // Handle any errors that occur during the link generation process
      console.error("Error generating partnership agreement link:", error);
    }
  }

  const onClickSendEmailButton = () => {
    for (let field of testPartsData) {
      if (field.hasItemPassQC === "Pending") {
        notifyError("Test part results still pending.");
        return;
      }
      if (field.assessmentReport === null && !field.noNeedAssessmentReport) {
        notifyError("Assessment report not uploaded. If no assessment report is needed, you can check 'No Assessment Report needed' and save.");
        return;
      }
    }

    // assuming only 1 test part for now
    const payload = {
      hasItemPassQC: testPartsData[0].hasItemPassQC,
      itemID: testPartsData[0].itemID,
      companyName: user.companyName,
      supplierName: user.name,
      toEmail: user.email,
      supplierID: user.userID,
    };
    sendAssessmentCompleteEmail(payload)
      .then(() => {
        addButtonsClicked(BUTTON_KEYS.SEND_ASSESSMENT_EMAIL); // Button only changes green if the email was successfully sent
        notifySuccess("Email successfully sent!");
      })
      .catch((err) => {
        console.log(err);
        notifyError("Failed to send email.");
      });
  };

  let currButton;

  if (adminInviteLink||currPartnershipAgreementInfo) {
    currButton = (
      <SolidBlueButton
        btnContent={"send email"}
        onBtnClick={onClickSendEmailButton}
        overrideStyle={
          buttonsClicked.includes(BUTTON_KEYS.SEND_ASSESSMENT_EMAIL) 
          ? { backgroundColor: colors.successGreen }
          : {}
        }
      />
    );
  } else if (testPartsData[0].hasItemPassQC === 'Pass') { // assuming only 1 test part for now
    currButton = (
      <SolidBlueButton
        btnContent={"Generate Agreement"}
        onBtnClick={onClickGeneratePartnershipAgreement}
      />
    );
  } else if (testPartsData[0].hasItemPassQC === 'Fail') {
    currButton = (
      <SolidBlueButton
        btnContent={"send fail email"}
        onBtnClick={onClickSendEmailButton}
        overrideStyle={
          buttonsClicked.includes(BUTTON_KEYS.SEND_ASSESSMENT_EMAIL) 
          ? { backgroundColor: colors.successGreen }
          : {}
        }
      />
    );
  } else if (testPartsData[0].hasItemPassQC === 'Pending') {
    currButton = (
      <SolidBlueButton
        btnContent={"Generate Agreement"}
        onBtnClick={onClickSendEmailButton}
        style={{
          backgroundColor: colors.disabled
        }}
        disabled={true}
      />
    );
  }

  if (!isEmptyValue(currPartnershipAgreementInfo)) { // If the partnership agreement has already been generated once, form may or may not be complete
    const {
      supplierID,
      docType,
      docID,
      docNum,
      docName,
      adminEmail,
      adminFieldInviteID,
      partnerEmail,
      partnerFieldInviteID,
      templateID,
    } = currPartnershipAgreementInfo;

    regeneratePartnershipAgreementSigningLink(docID, adminFieldInviteID);
  }

  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      spacing={1}
    >
      <Grid item xs={6}>
        <Card style={{ padding: "1rem" }} variant="outlined">
          <Subheader title="Test Part Results" />
          <TestPartResultsTable
            data={testPartsData}
            onClickEditIcon={toggleQaqcTab}
          />
          <div
            style={{
              textAlign: "right",
              paddingTop: "1rem",
            }}
          >
            {currButton}
          </div>
        </Card>
        {(adminInviteLink || currPartnershipAgreementInfo) && 
          <Fragment>
            {
              <div
                style={{
                  paddingTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "right",
                }}
              >
                {generatePartnershipAgreementButtonClicked && (adminInviteLink === null) && (
                  <div>Loading...</div>
                )}
                <div>
                    {infoComponent('Company Name', companyName)}
                    <br></br>
                    {infoComponent('Company ID', companyRegistrationNumber)}
                    <br></br>
                    {infoComponent('Address', address)}
                    <br></br>
                    {infoComponent('Country of Incorporation', country)}
                    <br></br>
                    {infoComponent('Document Number', currDocNum)}
                    <br></br>
                </div>
                <br></br>
                <iframe src={adminInviteLink} width='1000' height='1000' title="Partnership Agreement"></iframe>
              </div>
            }
          </Fragment>
        }
        {generatePartnershipAgreementButtonClicked && (!adminInviteLink) && 
          (
            <div>Loading...</div>
          )
        }
      </Grid>
      {isQaqcTabOpen && (
        <Grid item xs={6}>
          <Card style={{ padding: "1rem" }} variant="outlined">
            <Subheader title={`QAQC of ${selectedRow.name}`} />
            <UploadFileItem
              itemKey="upload-assessment-report"
              text="Upload Assessment Report"
              accept=".pdf"
              onFileUpload={(file) => {
                handleAssessmentReport(file);
              }}
              uploadState={selectedRow.assessmentReport}
              disabled={selectedRow.noNeedAssessmentReport}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={selectedRow.noNeedAssessmentReport}
                    onChange={handleNoNeedAssessmentReport}
                    disabled={selectedRow.assessmentReport !== null}
                  />
                }
                label="No Assessment Report needed"
              />
            </FormGroup>
            <TextField
              style={{
                width: "100%",
                marginTop: "2vh",
              }}
              variant="outlined"
              multiline
              rows={6}
              margin="dense"
              id="feedback"
              label="Additional Feedback"
              placeholder=""
              type="text"
              fullWidth
              onChange={(evt) => handleFeedback(evt.target.value)}
              value={selectedRow.feedback}
            />
            <Autocomplete
              disablePortal
              id="combo-box"
              options={["Pass", "Fail", "Pending"]}
              value={selectedRow.hasItemPassQC}
              renderInput={(params) => (
                <TextField {...params} label="QAQC Outcome" />
              )}
              getOptionLabel={(option) => option}
              onChange={(e, op) => handleResult(op)}
              style={{ padding: "1rem" }}
            />
            <div
              style={{
                textAlign: "right",
                paddingTop: "1rem",
              }}
            >
              <SolidBlueButton
                btnContent={"save"}
                onBtnClick={() => onClickSaveButton(selectedRow.itemID)}
              />
            </div>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    info: state.assessment.info,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: (id) => {
      dispatch(getAssessmentInfo(id));
    },
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(AssessmentProgress);

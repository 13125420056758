import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';
import WhiteButton from '../buttons/WhiteButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function YesNoPopup(props) {
  const classes = useStyles();
  const {
    open,
    handleNo,
    handleYes,
    title = "Are you sure that you want to delete this file?",
    body,
    noButtonLabel = 'No',
    yesButtonLabel = 'Yes',
    showCloseButton = true,
    loading = false,
    isSubmitDisabled = false,
  } = props;

  const renderBody = () => {
    if (typeof body === 'string') {
      return (
        <Typography>{body}</Typography>
      );
    }
    return body;
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleNo}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Title contentTitle={title} size="small" />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0 30px',
          paddingBottom: '2rem',
          textAlign: 'center',
        }}
      >
        <React.Fragment>
          {renderBody()}
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '0.5rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleNo}
            btnContent={noButtonLabel}
            size='small'
          />
          <Box className={classes.space}></Box>
          <BlueButton
            disabled={isSubmitDisabled}
            loading={loading}
            onBtnClick={() => {
              if (loading) {
                return
              }
              handleYes();
            }}
            btnContent={yesButtonLabel}
          />
        </div>
      </DialogActions>
      {
        showCloseButton && (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleNo}
          >
            <CloseIcon />
          </IconButton>
        )
      }
    </Dialog >
  );
}

export default YesNoPopup;

import React, { useEffect, useReducer } from 'react';
import { connect, useDispatch } from 'react-redux';
import { capitalize, get, isEmpty, lowerCase } from 'lodash';
import { toast } from 'react-toastify';
import ImageGallery from 'react-image-gallery';
import { makeStyles } from "tss-react/mui";
import { useParams, useLocation } from 'react-router-dom';

import ColoredWordsSentence from '../components/ColoredWordsSentence';
import DeliveryInstructions from '../assets/img/order-ready-management-delivery-instructions.png';
import TruckIcon from "../assets/icons/truck.svg";
import DeliveryInstructionsVertical from '../assets/img/order-ready-management-delivery-instructions-vertical.png';

import "react-image-gallery/styles/css/image-gallery.css";

import {
  ArtTrack,
  Edit as EditIcon,
  Delete,
  ExpandMore,
  LocalShipping,
  Warning,
} from '@mui/icons-material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import {
  adminAddItemOrderReadyRequiredImages,
  deleteOrderReadyResource,
  getProjectOrderReadyHistory,
  getProjectOrderReadyInfo,
  trackOrderReadyResourceDownload,
  updateItemOrderReadyStatus,
  updateOrderShipped,
  updateProjectOrderReadyInfo,
  upsertOrderReadyResource,
} from '../apis/orderReadyApi';
import { getUserInfo } from '../apis/userApi';

import { getSubmittedProjectOrderReadyCountAction } from '../actions/orderReady';

import { extractFileNameFromUrl } from '../utils/fileUtils';
import { formatOrderReadyHistoryDate } from '../utils/dateTimeUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { sortOrderReadyResource } from '../utils/orderReadyUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { uploadPublicFileToS3 } from '../services/s3Service';
import { notifyError, notifySuccess } from '../services/notificationService';

import BlueButton from '../components/buttons/BlueButton';
import DownloadableFileWithActions from '../components/list-items/DownloadableFileWithActions';
import ErrorLabel from '../components/labels/ErrorLabel';
import FilesUploadButton from '../components/FilesUploadButton';
import GreyButton from '../components/buttons/GreyButton';
import ImageUpload from './orderReady/ImageUpload';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import OrderReadyTimeline from '../components/OrderReadyTimeline';
import SingleImage from '../components/images/SingleImage';
import YesNoPopup from '../components/popups/YesNoPopup';

import {
  DELIVERY_STATUSES,
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_REVIEW_COMMENTS,
  ORDER_READY_STATUS,
  SHIPMENT_PROVIDERS,
} from '../constants/orderReadyConstants';
import { IMG_PLACE_HOLDER_URL, ROLE_TYPES, S3_ORDER_READY_IMG_DIR_NAME } from '../constants';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import {
  QC_NO_QC_REPORT,
  QC_REPORT_TEMPLATE_URL,
} from '../constants/projectConstants';
import { FACTOREM_BLUE, colors } from '../palette';
import { PageTitle } from '../components/PageTitle';

const useStyles = makeStyles()((theme, _params, classes) => ({
  body: {
    padding: '1.6rem',
    paddingTop: '1.2rem',
  },
  heading: {
    fontWeight: 'bold',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: FACTOREM_BLUE,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: FACTOREM_BLUE,
    },
    backgroundColor: colors.secondaryBlue,
    color: FACTOREM_BLUE,
  },
  disabledTab: {
    color: colors.lightGray,
  },
  iconLabelWrapper: {
    marginBottom: '0 !important',
    marginRight: '0.5rem',
    height: '2rem',
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
  labelIcon: {
    minHeight: 0,
    width: 150,
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: 'inherit',
  },
  container: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: '0.5rem',
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
  editShipmentNotes: {
    marginRight: '10px',
  }
}));

const TAB_KEYS = {
  REVIEW: 'Review',
  DELIVER: 'Deliver',
}

const TABS = [
  TAB_KEYS.REVIEW,
  TAB_KEYS.DELIVER,
];

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

function ProjectOrderReady(props) {
  const { classes } = useStyles();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const queryParams = useQueryParams();
  const dispatch = useDispatch();

  const {
    userID,
    userRole,
  } = props;
  const { projectID } = useParams();

  const supplierID = queryParams.get('supplierID') || userID;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedTab: location.state ? location.state.selectedTab : 0,
      projectOrderReadyID: null,
      projectOrderReadyInfo: {},
      itemOrderReadyList: [],
      orderReadyHistory: [],
      showImageView: false,
      imageUrlList: [],
      showImageIndex: 0,
      showDeleteFilePopup: false,
      deleteResource: null,
      updatingItemOrderReadyStatus: false,
      comments: {},
      additionalComment: {},
      showAdminAddImagePopup: false,
      addAnotherRequiredImage: false,
      selectedItemOrderReady: null,
      addPredefinedRequiredImages: [],
      newImageTitle: '',
      newImageDescription: '',
      hasQcReportsRequired: false,
      isNonSgpSupplier: false,
      errors: {},
      existingShipmentNotes: null,
      shipmentNotes: null,
      showEditShippingNotesContainer: false,
    }
  );

  const loadOrderReadyInfo = async () => {

    const allProjectOrderReadyInfo = await getProjectOrderReadyInfo({
      projectID,
      supplierID,
    });
    const projectOrderReadyInfo = allProjectOrderReadyInfo.projectOrderReady;
    const projectOrderReadyID = get(projectOrderReadyInfo, ['info', 'projectOrderReadyID']);
    const orderReadyHistory = await getProjectOrderReadyHistory({
      projectOrderReadyID,
    });
    const projectOrderReadyStatus = get(projectOrderReadyInfo, ['info', 'status']);
    const shipmentNotes = localState.shipmentNotes || get(projectOrderReadyInfo, ['info', 'shipmentNotes']);
    const existingShipmentNotes = shipmentNotes;
    const itemOrderReadyList = allProjectOrderReadyInfo.itemOrderReady;
    const projectQcReports = get(projectOrderReadyInfo, ['info', 'qcReports', 'main']);
    const projectQcReportsAddOns = get(projectOrderReadyInfo, ['info', 'qcReports', 'addOns']);
    const hasQcReportsRequired = !isEmpty(projectQcReports) && projectQcReports !== QC_NO_QC_REPORT;
    const allItemsVerified = itemOrderReadyList.some(ior => ORDER_READY_STATUS.VERIFIED === ior.info.status)
      && itemOrderReadyList.every(ior => [ORDER_READY_STATUS.VERIFIED, ...DELIVERY_STATUSES].includes(ior.info.status));
    const hasItemNotStarted = itemOrderReadyList.some(ior => [ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(ior.info.status));
    const deliverFiles = (projectOrderReadyInfo.resources || []).filter(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const hasDownloadDeliverFiles = !isEmpty(deliverFiles) && deliverFiles.every(resource => resource.downloadCount > 0);
    const noDeliveryDocRequired = Boolean(get(projectOrderReadyInfo, ['info', 'noDeliveryDocRequired']));
    const hasItemVerified = itemOrderReadyList.some(ior => ![ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE, ORDER_READY_STATUS.SUBMITTED].includes(ior.info.status));
    const deliverTabWarning = hasItemVerified === true && noDeliveryDocRequired === false && deliverFiles.length <= 0 && isAdminOrHigherRole(userRole);

    /**
     * disable delivery tab if
     * - it's supplier platform and
     * - no itemOrderReady has status ready_to_ship or in_transit
     * - there is no delivery documents uploaded and the noDeliveryDocRequired is still set false value
     * - or project status is not ready_to_ship or in_transit
     */
    const disableDeliverTab = !isAdminOrHigherRole(userRole)
      && (
        itemOrderReadyList.every(orderReady => !DELIVERY_STATUSES.includes(orderReady.info.status))
        || (noDeliveryDocRequired === false && deliverFiles.length <= 0)
        || !DELIVERY_STATUSES.includes(projectOrderReadyStatus)
      );

    updateLocalState({
      projectOrderReadyID,
      projectOrderReadyInfo,
      projectOrderReadyStatus,
      itemOrderReadyList,
      orderShipmentInfo: allProjectOrderReadyInfo.orderShipmentInfo,
      noDeliveryDocRequired,
      orderReadyHistory,
      allItemsVerified,
      disableDeliverTab,
      projectQcReports,
      projectQcReportsAddOns,
      hasQcReportsRequired,
      hasDownloadDeliverFiles,
      deliverTabWarning,
      hasItemNotStarted,
      errors: {},
      shipmentNotes,
      showEditShippingNotesContainer: false,
      existingShipmentNotes,
    });

    const allItemsReadyToShip = itemOrderReadyList.every(ior => ior.info.status === ORDER_READY_STATUS.READY_TO_SHIP);
    // auto switch to deliver tab if projectOrderReadyStatus is ready for delivery and all items are also ready for delivery
    if (projectOrderReadyStatus === ORDER_READY_STATUS.READY_TO_SHIP && allItemsReadyToShip) {
      updateLocalState({ selectedTab: 1 });
    }
  }

  useEffect(() => {
    if (!isEmpty(projectID)) {
      loadOrderReadyInfo();
    }
  }, [projectID]);

  useEffect(() => {
    if (isEmptyValue(supplierID)) {
      return;
    }
    getUserInfo(supplierID)
      .then(data => {
        const isNonSgpSupplier = data.country !== COUNTRY_NAMES.SINGAPORE;
        updateLocalState({ isNonSgpSupplier });
      });
  }, [supplierID]);

  useEffect(() => {
    // set default shipment notes
    if (localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED) {
      const notes = localState.isNonSgpSupplier
        ? 'Ship to 81 Ayer Rajah Crescent, #01-54, Singapore 139967'
        : 'Drop-off at nearest Ninja Collection Point: https://www.ninjavan.co/en-sg/support/find-ninja-point';
      updateLocalState({
        shipmentNotes: localState.shipmentNotes ?? notes,
      });
    }
  }, [localState.isNonSgpSupplier, localState.projectOrderReadyStatus]);

  const handleUploadOrderReadyResource = (
    {
      itemOrderReadyID,
      resourceID,
      file,
      resourceType,
    }
  ) => {
    const splitFilename = file.name.split('.');
    const preFilename = splitFilename.slice(0, -1).join('.');
    const fileExtension = splitFilename[splitFilename.length - 1];
    // projectOrderReadyID
    const porID = localState.projectOrderReadyID;
    const s3Path = isEmpty(itemOrderReadyID)
      ? `${S3_ORDER_READY_IMG_DIR_NAME}/por-${porID}`
      : `${S3_ORDER_READY_IMG_DIR_NAME}/por-${porID}/ior - ${itemOrderReadyID}`;
    const s3ObjectKey = `${s3Path}/${preFilename}_${Date.now()}.${fileExtension}`;
    const resourceName = capitalize(resourceType.replace(/_/g, ' '));
    const toastId = toast(`Uploading ${resourceName}...`, { type: toast.TYPE.INFO, autoClose: false });
    uploadPublicFileToS3(file, s3ObjectKey)
      .then((data) => {
        const url = data.Location.split(' ').join('%20');
        const body = {
          itemOrderReadyID,
          resourceID,
          fileURL: url,
          type: resourceType,
        }
        upsertOrderReadyResource(porID, body)
          .then(() => {
            toast.update(toastId, {
              render: `${resourceName} uploaded successfully.`,
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
            });
            loadOrderReadyInfo();
          })
          .catch((err) => {
            toast.update(toastId, {
              render: `${resourceName} uploaded failed.`,
              type: toast.TYPE.ERROR,
              autoClose: 3000,
            });
          });
      })
      .catch((err) => {
        toast.update(toastId, {
          render: `${resourceName} uploaded failed.`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      });
  }

  const handleItemOrderReadySubmit = (itemOrderReadyID) => {
    const itemOrderReady = localState.itemOrderReadyList.find(itemOrderReady => {
      return itemOrderReady.info.itemOrderReadyID === itemOrderReadyID;
    });
    const requiredImageResources = itemOrderReady.resources.filter(resource => lowerCase(resource.type).includes('image') && Boolean(resource.required) === true);
    for (const imageResource of requiredImageResources) {
      if (imageResource.fileURL === IMG_PLACE_HOLDER_URL || imageResource.fileURL.includes('2dImgFiles')) {
        const message = `${capitalize(imageResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }
    if (localState.hasQcReportsRequired) {
      const hasQcReportUploaded = itemOrderReady.resources.some(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
      if (!hasQcReportUploaded) {
        const message = 'QC Report is required';
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              qcReports: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }
    const body = {
      status: ORDER_READY_STATUS.SUBMITTED,
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });
    const toastId = toast('Submitting item order ready for review...', { type: toast.TYPE.INFO, autoClose: false });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            toast.update(toastId, {
              render: 'Item order ready submitted successfully.',
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
            });
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        toast.update(toastId, {
          render: 'Item order ready submitted failed.',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleVerifyItemOrderReady = (itemOrderReadyID) => {
    const body = {
      status: ORDER_READY_STATUS.VERIFIED,
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready is verified');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Verifying Item order ready failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleItemOrderReadyNeedUpdate = (itemOrderReadyID) => {
    updateLocalState({ updatingItemOrderReadyStatus: true });
    const body = {
      status: ORDER_READY_STATUS.NEED_UPDATE,
      comments: [...(localState.comments[itemOrderReadyID] || []), localState.additionalComment[itemOrderReadyID]],
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready status is updated');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Update Item order ready status failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleDeleteResourceFile = () => {
    deleteOrderReadyResource(
      localState.projectOrderReadyID,
      localState.deleteResource.resourceID
    ).then(() => {
      loadOrderReadyInfo();
      notifySuccess('File is removed successfully');
    }).catch(() => notifyError('Failed to delete file.'));
  }

  const handleAddComment = (event, itemOrderReadyID) => {
    const { name, checked } = event.target;
    if (checked) {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: [...(localState.comments[itemOrderReadyID] || []), name],
        }
      });
    } else {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: (localState.comments[itemOrderReadyID] || []).filter(comment => comment !== name),
        }
      });
    }
  }

  const handleUpdateImageRequired = (event, resource) => {
    const { checked } = event.target;
    const body = {
      ...resource,
      required: checked,
    }
    const { projectOrderReadyID: porID } = resource;
    upsertOrderReadyResource(porID, body)
      .then(() => {
        loadOrderReadyInfo();
        notifySuccess('Updated successfully');
      }).catch(() => notifyError('Failed to updated.'));
  }

  const handleOrderShipped = () => {
    if (localState.isNonSgpSupplier) {
      if (isEmpty(localState.shipmentProvider)) {
        updateLocalState({
          errors: {
            shipmentProvider: 'Required',
          }
        });
        return;
      }
      if (localState.shipmentProvider === 'Other' && isEmpty(localState.otherShipmentProvider)) {
        updateLocalState({
          errors: {
            otherShipmentProvider: 'Required',
          }
        });
        return;
      }
      if (localState.shipmentTrackingNA !== true && isEmpty(localState.shipmentTrackingNumber)) {
        updateLocalState({
          errors: {
            shipmentTrackingNumber: 'Required',
          }
        });
        return;
      }
    }
    const projectOrderReadyID = localState.projectOrderReadyID;
    const itemOrderReadyList = localState.itemOrderReadyList || [];
    const itemOrderReadyIDList = itemOrderReadyList.map((itemOrderReady => itemOrderReady.info.itemOrderReadyID));
    let body = {
      itemOrderReadyIDList,
    }
    if (localState.isNonSgpSupplier) {
      body = {
        ...body,
        shipmentProvider: localState.shipmentProvider === 'Other'
          ? localState.otherShipmentProvider
          : localState.shipmentProvider,
        shipmentTrackingNA: localState.shipmentTrackingNA,
        shipmentTrackingNumber: localState.shipmentTrackingNumber,
      }
    }
    updateLocalState({ orderShippedProcessing: true });
    updateOrderShipped(projectOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Updated successfully');
            updateLocalState({ orderShippedProcessing: false });
          });
        }, 2000);
      }).catch(() => {
        notifyError('Failed to updated.');
        updateLocalState({ orderShippedProcessing: false });
      });
  }

  const handleAdminAddRequireImages = () => {
    updateLocalState({
      showAdminAddImagePopup: false,
    });
    const {
      addPredefinedRequiredImages,
      newImageTitle,
      newImageDescription,
      selectedItemOrderReady,
    } = localState;
    const { itemOrderReadyID } = selectedItemOrderReady.info;
    if (isEmpty(addPredefinedRequiredImages) && isEmpty(newImageTitle) && isEmpty(newImageDescription)) {
      return;
    }
    const body = {
      addPredefinedRequiredImages,
      newImageTitle,
      newImageDescription,
    }
    const toastId = toast(`Uploading...`, { type: toast.TYPE.INFO, autoClose: false });
    adminAddItemOrderReadyRequiredImages(itemOrderReadyID, body)
      .then(() => {
        loadOrderReadyInfo().then(() => {
          toast.update(toastId, {
            render: `Updated successfully.`,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
        });
      })
      .catch(() => {
        toast.update(toastId, {
          render: `Updated failed.`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      })
      .finally(() => {
        updateLocalState({
          addPredefinedRequiredImages: [],
          newImageTitle: '',
          newImageDescription: '',
        });
      });
  }

  const handleReadyToShipClick = () => {
    updateLocalState({ readyToShipProcessing: true });
    const toastId = toast('Ready to ship is processing...', { type: toast.TYPE.INFO, autoClose: false });
    updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
      status: ORDER_READY_STATUS.READY_TO_SHIP,
      shipmentNotes: localState.shipmentNotes
    }).then(() => {
      setTimeout(() => {
        loadOrderReadyInfo().then(() => {
          toast.update(toastId, {
            render: 'The partner has been notified successfully.',
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          updateLocalState({ readyToShipProcessing: false });
        });
        if (isAdminOrHigherRole(userRole)) {
          dispatch(getSubmittedProjectOrderReadyCountAction());
        }
      }, 2000);
    }).catch(() => {
      toast.update(toastId, {
        render: 'Ready to ship processing failed.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      updateLocalState({ readyToShipProcessing: false });
    });
  }

  const showImageListOfItemOrderReady = (resources, resourceType) => {
    const imageResources = resources
      .filter(resource => isAdminOrHigherRole(userRole) || Boolean(resource.required) === true)
      .filter(resource => lowerCase(resource.type).includes('image'))
      .sort(sortOrderReadyResource);
    const imageUrlList = imageResources.map(resource => ({
      original: resource.fileURL,
      thumbnail: resource.fileURL,
    }));
    const showImageIndex = imageResources.findIndex(resource => resource.type === resourceType);
    updateLocalState({
      imageUrlList,
      showImageView: true,
      showImageIndex,
    });
  }

  const renderStatus = (status) => {
    return (
      <OrderReadyStatusDisplay status={status} />
    )
  }

  const renderProjectOrderReadyStatus = () => {
    const projectStatus = get(localState, ['projectOrderReadyInfo', 'info', 'status']);
    return renderStatus(projectStatus);
  }

  const renderResourceFileWithActions = (file, showDeleteButton = true) => {
    return (
      <DownloadableFileWithActions
        key={`${file.resourceID}-${file.downloadCount}`}
        fileUrl={file.fileURL}
        showDeleteButton={showDeleteButton}
        onDelete={(event) => {
          event.preventDefault();
          event.stopPropagation();
          updateLocalState({
            showDeleteFilePopup: true,
            deleteResource: {
              resourceID: file.resourceID,
              fileName: extractFileNameFromUrl(file.fileURL),
            },
          });
        }}
      />
    );
  }

  const renderTrackingDownloadResourceFileWithActions = (file, showDeleteButton = true, iconImage) => {
    return (
      <div
        key={`${file.resourceID}-${file.downloadCount}`}
      >
        <DownloadableFileWithActions
          iconImage={iconImage}
          fileUrl={file.fileURL}
          downloadCount={file.downloadCount}
          showDeleteButton={showDeleteButton}
          onDelete={(event) => {
            event.preventDefault();
            event.stopPropagation();
            updateLocalState({
              showDeleteFilePopup: true,
              deleteResource: {
                resourceID: file.resourceID,
                fileName: extractFileNameFromUrl(file.fileURL),
              },
            });
          }}
          postDownloadFunction={() => {
            if (!isAdminOrHigherRole(userRole)) {
              trackOrderReadyResourceDownload({ resourceID: file.resourceID })
                .then(() => {
                  setTimeout(() => {
                    loadOrderReadyInfo();
                  }, 1000);
                });
            }
          }}
        />
        {isAdminOrHigherRole(userRole) && (
          <Tooltip title="Number of times this document was downloaded by partner">
            <Typography
              style={{
                fontStyle: 'italic',
                marginLeft: '0.5rem',
              }}
              variant='caption'
            >
              Total download: {file.downloadCount}
            </Typography>
          </Tooltip>
        )}
      </div>
    );
  }

  const renderQcReportFiles = (resources) => {
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
    if (isEmpty(fileList)) {
      return null;
    }
    return fileList.map((file) => renderResourceFileWithActions(file, true));
  }

  const renderDeliveryOrderFiles = () => {
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER);
    if (isEmpty(fileList)) {
      return null;
    }
    const showDeleteButton = isAdminOrHigherRole(userRole);
    return fileList.map((file) => renderTrackingDownloadResourceFileWithActions(file, showDeleteButton));
  }

  const renderWaybillFiles = () => {
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.WAYBILL);
    const showDeleteButton = isAdminOrHigherRole(userRole);

    if (!isAdminOrHigherRole(userRole) && isEmpty(fileList)) {
      return null;
    }

    return (
      <div>
        <Typography className={classes.heading}>
          {isAdminOrHigherRole(userRole) ? 'Upload Waybill' : 'Waybill'}
        </Typography>
        {isAdminOrHigherRole(userRole) && (
          <div style={{ marginTop: '1rem' }}>
            <FilesUploadButton
              id="upload-way-bill"
              buttonText="Upload Waybill"
              handleUploadFiles={(files) => handleUploadOrderReadyResource({
                file: files[0],
                resourceType: ORDER_READY_RESOURCE_TYPES.WAYBILL,
              })}
              multiple={false}
            />
          </div>
        )}
        <div style={{ marginTop: '1rem' }}>
          {fileList.map((file) => renderTrackingDownloadResourceFileWithActions(file, showDeleteButton, TruckIcon))}
        </div>
      </div>
    );
  }

  const changeStatusWordColor = (note, status) => {
    switch (status) {
      case ORDER_READY_STATUS.NEED_UPDATE:
        return <ColoredWordsSentence sentence={note} highlightWords={['Update', 'required']} color={colors.bgOrderNeedUpdate} />
      default:
        return note
    }
  }

  const renderItemOrderReadyList = () => {
    const itemOrderReadyList = localState.itemOrderReadyList || [];

    return (
      <div>
        {itemOrderReadyList.map((itemOrderReady, index) => {
          const itemOrderReadyID = itemOrderReady.info.itemOrderReadyID;
          const { itemID } = itemOrderReady.info;

          const showVerifyButton = isEmpty(localState.comments[itemOrderReadyID])
            && isEmpty(localState.additionalComment[itemOrderReadyID]);

          const filteredImageResources = itemOrderReady.resources
            .filter(resource => lowerCase(resource.type).includes('image') && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole)))
            .sort(sortOrderReadyResource);

          const noDocRequired = isEmpty(filteredImageResources) && localState.hasQcReportsRequired === false;

          const renderRequireUpdateButton = () => {
            if (showVerifyButton) {
              return null;
            }
            return (
              <Button
                style={{
                  backgroundColor: colors.warningYellow,
                }}
                variant="contained"
                component="span"
                onClick={() => handleItemOrderReadyNeedUpdate(itemOrderReadyID)}
                disabled={localState.updatingItemOrderReadyStatus === true}
              >
                {localState.updatingItemOrderReadyStatus === true && (
                  <CircularProgress className={classes.circularProgress} size={20} />
                )}
                Require Update
              </Button>
            );
          }

          const renderLatestUpdateNotes = () => {
            let filteredArray = localState.orderReadyHistory.filter(
              item => item.notes.includes(itemOrderReady.info.itemID) && item.notes.includes("Update required")
            );
            let history = filteredArray[filteredArray.length - 1];
            const name = isAdminOrHigherRole(history.userRole)
              ? 'Factorem'
              : history.companyName || capitalize(history.userRole);
            return (
              <Paper
                style={{
                  padding: '0.6rem',
                  margin: '0.3rem',
                }}
                key={`${history.notes} latest update`}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {formatOrderReadyHistoryDate(history.createdDate)}, {name}
                </Typography>
                {history.notes.split('\n').map((note, index) => {
                  return (
                    <Typography
                      key={note}
                      style={{
                        textAlign: 'left'
                      }}
                    >
                      {index === 0 ? changeStatusWordColor(note, history.status) : note}
                    </Typography>
                  );
                })}
              </Paper>)
          }

          const renderVerifyButton = () => {
            if (!showVerifyButton) {
              return null;
            }
            return (
              <Button
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                }}
                variant="contained"
                component="span"
                onClick={() => handleVerifyItemOrderReady(itemOrderReadyID)}
                disabled={localState.updatingItemOrderReadyStatus === true}
              >
                {localState.updatingItemOrderReadyStatus === true && (
                  <CircularProgress className={classes.circularProgress} size={20} />
                )}
                Verify
              </Button>
            );
          }

          const renderPartImageUploadArea = () => {
            if (isEmpty(filteredImageResources)) {
              return null;
            }

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.3rem',
                }}
              >
                <Typography className={classes.heading}>Part Images</Typography>
                <Typography>
                  Please upload mandatory images for <span className={classes.heading}>Part #{itemOrderReady.info.itemID}</span> as shown below.
                </Typography>
                {isAdminOrHigherRole(userRole) && (
                  <div>
                    <Button
                      style={{
                        marginTop: '0.5rem',
                      }}
                      color='primary'
                      variant='contained'
                      onClick={() => updateLocalState({
                        showAdminAddImagePopup: true,
                        selectedItemOrderReady: itemOrderReady,
                      })}
                    >
                      Add Required Image
                    </Button>
                  </div>
                )}
                {!isEmpty(get(localState, ['errors', itemOrderReadyID, 'requiredImages'])) && (
                  <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'requiredImages'])} />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    fontSize: '0.8rem',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  {filteredImageResources.map(resource => {
                    const {
                      type: resourceType,
                    } = resource;
                    const key = `itemID-${itemID}-resourceID-${resource.resourceID}-${resourceType}-image-upload-${resource.fileURL}`;
                    const imageURL = resource.fileURL;
                    const uploadAllowed = ![
                        ORDER_READY_STATUS.VERIFIED,
                        ORDER_READY_STATUS.READY_TO_SHIP,
                        ORDER_READY_STATUS.IN_TRANSIT,
                        ORDER_READY_STATUS.DELIVERED].includes(itemOrderReady.info.status)
                      || isAdminOrHigherRole(userRole);
                    return (
                      <div
                        style={{
                          margin: '1rem',
                        }}
                      >
                        <Tooltip title={isEmpty(resource.description) ? '' : resource.description}>
                          <div
                            style={{
                              cursor: uploadAllowed ? 'pointer' : "cursor"
                            }}
                            key={key}
                          >
                            <ImageUpload
                              type={resourceType}
                              id={key}
                              uploadAllowed={uploadAllowed}
                              onUpload={(file) => handleUploadOrderReadyResource({
                                resourceID: resource.resourceID,
                                itemOrderReadyID,
                                file,
                                resourceType,
                              })}
                              imageURL={imageURL}
                              placeHolderUrl={resource.placeHolderUrl}
                              onViewClick={() => showImageListOfItemOrderReady(itemOrderReady.resources, resourceType)}
                              showAlarm={imageURL === IMG_PLACE_HOLDER_URL || imageURL.includes('2dImgFiles')}
                            />
                          </div>
                        </Tooltip>
                        {
                          isAdminOrHigherRole(userRole) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="required"
                                    onChange={(event) => handleUpdateImageRequired(event, resource)}
                                    checked={resource.required}
                                  />
                                }
                                label="Required"
                              />
                              <Tooltip title="Delete required image that shows to supplier">
                                <IconButton
                                  edge='end'
                                  aria-label='delete'
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateLocalState({
                                      showDeleteFilePopup: true,
                                      deleteResource: {
                                        resourceID: resource.resourceID,
                                        fileName: resource.type,
                                      },
                                    });
                                  }}
                                >
                                  <Delete color='error' style={{ fontSize: '15pt' }} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }

          const renderRequiredQcReportTexts = (qcReports) => {
            return qcReports.map((qcReport, index) => {
              const templateUrl = QC_REPORT_TEMPLATE_URL[qcReport];
              let qcReportText;
              if (templateUrl) {
                qcReportText = (
                  <a href={templateUrl} target='_blank'>
                    {qcReport}
                  </a>
                );
              } else {
                qcReportText = qcReport;
              }
              return (
                <span>
                  {qcReportText}
                  {index === qcReports.length - 1 ? '.' : ', '}
                </span>
              );
            })
          }

          const renderQcReportUploadArea = () => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1rem',
                }}
              >
                <Typography className={classes.heading}>
                  QC Report{localState.hasQcReportsRequired === true && (
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  )}
                </Typography>
                {localState.hasQcReportsRequired === true && (
                  <div>
                    <Typography>
                      Please upload the Quality Check (QC) {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} for <b>Part #{itemOrderReady.info.itemID}</b>
                    </Typography>
                    <Typography>
                      QC {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} required: <b>{renderRequiredQcReportTexts([localState.projectQcReports, ...localState.projectQcReportsAddOns])}</b>
                    </Typography>
                    <div
                      style={{
                        padding: '0.5rem',
                      }}
                    >
                      <FilesUploadButton
                        id={`upload - qc - report - ${itemOrderReadyID}`}
                        buttonText="Upload QC Report"
                        handleUploadFiles={(files) => handleUploadOrderReadyResource({
                          itemOrderReadyID,
                          file: files[0],
                          resourceType: ORDER_READY_RESOURCE_TYPES.QC_REPORT,
                        })}
                        multiple={false}
                      />
                    </div>
                    {!isEmpty(get(localState, ['errors', itemOrderReadyID, 'qcReports'])) && (
                      <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'qcReports'])} />
                    )}
                  </div>
                )}
                {localState.hasQcReportsRequired === false && (
                  <Typography>
                    No QC Report required.
                  </Typography>
                )}
                <div
                  style={{
                    padding: '0.5rem',
                  }}
                >
                  {renderQcReportFiles(itemOrderReady.resources)}
                </div>
              </div>
            );
          }

          const renderReviewArea = () => {
            const { itemOrderReadyID } = itemOrderReady.info;
            return itemOrderReady.info.status === ORDER_READY_STATUS.SUBMITTED
              && [ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(userRole)
              && (
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <Typography className={classes.heading}>Review</Typography>
                  <Typography>Please select or add comments for the part if there is any issue, otherwise click the Verify button to accept the order ready.</Typography>
                  <Box style={{ height: '0.3rem' }} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.REWORK}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.REWORK}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <Box style={{ height: '0.5rem' }} />
                  <TextField
                    id="outlined-multiline-static"
                    label="Additional comment"
                    multiline
                    minRows={3}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateLocalState({
                      additionalComment: {
                        ...localState.additionalComment,
                        [itemOrderReadyID]: e.target.value,
                      },
                    })}
                  />
                </div>
              );
          }

          return (
            <Accordion
              key={itemOrderReady.info.itemID}
              defaultExpanded={index === 0 && itemOrderReady.info.status === ORDER_READY_STATUS.NOT_STARTED}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  content: classes.accordionSummaryContent,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SingleImage
                    url={itemOrderReady.info.imageFile || itemOrderReady.info.twoDImageUrl}
                  />
                  <Box style={{ width: '1rem' }} />
                  <Typography className={classes.heading}>Part #{itemOrderReady.info.itemID}</Typography>
                  <Box style={{ width: '1rem' }} />
                  {renderStatus(itemOrderReady.info.status)}
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Divider />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '1rem',
                  }}
                >
                  {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReady.info.status && renderLatestUpdateNotes()}
                  {noDocRequired === true && (
                    <div>
                      <Typography>
                        There are no order ready documents required for this order.
                      </Typography>
                      {ORDER_READY_STATUS.NOT_STARTED === itemOrderReady.info.status && (
                        <Typography>
                          Please click <b>SUBMIT</b> to get shipment approval.
                        </Typography>
                      )}
                    </div>
                  )}
                  {noDocRequired !== true && renderPartImageUploadArea()}
                </div>
                {noDocRequired !== true && renderQcReportUploadArea()}
                {renderReviewArea()}
                <div
                  style={{
                    marginTop: '1rem',
                    padding: '0.5rem',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  {[ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(itemOrderReady.info.status) && (
                    <Tooltip
                      title={Boolean(itemOrderReady.info.poAcknowledged) !== true
                        ? 'Upon clicking, your order will be reviewed for shipment. PO needs to be acknowledged to enable this button.'
                        : 'Upon clicking, your order will be reviewed for shipment.'
                      }
                    >
                      <div>
                        <Button
                          id={`submit-itemOrderReadyID-${itemOrderReadyID}`}
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={() => handleItemOrderReadySubmit(itemOrderReadyID)}
                          disabled={localState.updatingItemOrderReadyStatus === true || Boolean(itemOrderReady.info.poAcknowledged) !== true}
                        >
                          {localState.updatingItemOrderReadyStatus === true && (
                            <CircularProgress className={classes.circularProgress} size={20} />
                          )}
                          {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReady.info.status
                            ? 'Re-Submit'
                            : 'Submit'
                          }
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {itemOrderReady.info.status === ORDER_READY_STATUS.SUBMITTED
                    && isAdminOrHigherRole(userRole)
                    && (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {renderRequireUpdateButton(itemOrderReady)}
                        {renderVerifyButton(itemOrderReady)}
                      </div>
                    )}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div >
    );
  }

  const renderOrderReadyHistory = () => {
    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          History
        </Typography>
        <Typography>Your upload history for this project is shown below</Typography>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            marginTop: '0.5rem',
          }}
        >
          {localState.orderReadyHistory.map(history => {
            const name = isAdminOrHigherRole(history.userRole)
              ? 'Factorem'
              : history.companyName || capitalize(history.userRole);
            return (
              <Paper
                style={{
                  padding: '0.6rem',
                  margin: '0.3rem',
                }}
                key={history.notes}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: history.userRole === ROLE_TYPES.SUPPLIER ? 'left' : 'right',
                  }}
                >
                  {formatOrderReadyHistoryDate(history.createdDate)}, {name}
                </Typography>
                {history.notes.split('\n').map((note, index) => {
                  return (
                    <Typography
                      key={note}
                      style={{
                        textAlign: history.userRole === ROLE_TYPES.SUPPLIER ? 'left' : 'right',
                      }}
                    >
                      {index === 0 ? changeStatusWordColor(note, history.status) : note}
                    </Typography>
                  );
                })}
              </Paper>
            );
          })}
        </Paper>
      </div>
    );
  }

  const renderOrderShippedButton = () => {
    const itemOrderReadyList = localState.itemOrderReadyList || [];
    const readyToShipItems = itemOrderReadyList.filter((itemOrderReady) => itemOrderReady.info.status === ORDER_READY_STATUS.READY_TO_SHIP);

    const projectOrderReadyStatus = get(localState.projectOrderReadyInfo, ['info', 'status']);

    if (readyToShipItems.length === 0 || projectOrderReadyStatus !== ORDER_READY_STATUS.READY_TO_SHIP) {
      return null;
    }

    const deliveryDocs = (get(localState.projectOrderReadyInfo, ['info', 'resources']) || []).filter(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const buttonTooltip = deliveryDocs.length > 1
      ? 'Please download delivery documents above before shipping'
      : 'Please download delivery document above before shipping';

    let text = 'All items have been verified';
    if (itemOrderReadyList.length > 1) {
      if (readyToShipItems.length === 1) {
        text = '1 item has been verified';
      } else if (readyToShipItems.length < itemOrderReadyList.length) {
        text = 'Some items have been verified';
      }
    } else {
      text = 'Item has been verified';
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.5rem',
          flexWrap: 'wrap',
          gap: '0.5rem',
          width: 'fit-content',
        }}
      >
        <Typography>
          {text}. Please click this button once the order has been shipped.&nbsp;
        </Typography>
        <BlueButton
          size={isMobile ? "small" : "large"}
          onBtnClick={handleOrderShipped}
          btnContent='Order Shipped'
          disabled={(localState.noDeliveryDocRequired !== true && localState.hasDownloadDeliverFiles !== true && !isAdminOrHigherRole(userRole)) || localState.orderShippedProcessing}
          tooltip={localState.noDeliveryDocRequired !== true && localState.hasDownloadDeliverFiles !== true ? buttonTooltip : ''}
          loading={localState.orderShippedProcessing}
        />
      </div>
    );
  }

  const renderReviewTab = () => {
    const projectStatus = get(localState, ['projectOrderReadyInfo', 'info', 'status']);
    return (
      <Grid container spacing={3}>
        {localState.allItemsVerified === true
          && (localState.disableDeliverTab === false || isAdminOrHigherRole(userRole))
          && (
            <Grid item xs={12}>
              <Typography>
                All orders have been verified, please proceed to&nbsp;
                <Link
                  onClick={() => updateLocalState({ selectedTab: 1 })}
                  style={{ cursor: 'pointer' }}
                >
                  Deliver
                </Link>&nbsp;tab.
              </Typography>
            </Grid>
          )}
        {localState.hasItemNotStarted === true && (
          <Grid item xs={12}>
            <Typography>
              Upload delivery documents for each item and submit for review.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          {renderItemOrderReadyList()}
        </Grid>
        <Grid item xs={12} md={4}>
          {!isEmpty(localState.orderReadyHistory) && isAdminOrHigherRole(userRole)
            ? renderOrderReadyHistory()
            : <OrderReadyTimeline projectStatus={projectStatus} />
          }
        </Grid>
      </Grid>
    );
  }

  const renderEditShipmentNotesArea = () => {
    const toRender = localState.showEditShippingNotesContainer
      && DELIVERY_STATUSES.includes(localState.projectOrderReadyStatus);

    if (toRender) {
      return (
        <div>
          <TextField
            style={{ width: '50%' }}
            label="Shipping Notes (if any)"
            variant="outlined"
            multiline={true}
            rows={6}
            rowsMax={6}
            onChange={(e) => updateLocalState({
              shipmentNotes: e.target.value,
              errors: {},
            })}
            value={localState.shipmentNotes}
            margin="dense"
            fullWidth
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: '10px',
            }}
          >
            <BlueButton
              size="small"
              onBtnClick={() => {
                updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
                  shipmentNotes: localState.shipmentNotes
                }).then(() => {
                  notifySuccess(`Updated successfully`);
                  loadOrderReadyInfo();
                }).catch(() => {
                  notifyError(`Updated failed`);
                });
              }}
              btnContent="Edit shipment notes"
              className={classes.editShipmentNotes}
            />
            <BlueButton
              size="small"
              onBtnClick={() => updateLocalState({ 
                showEditShippingNotesContainer: false,
                shipmentNotes: localState.existingShipmentNotes, 
              })}
              btnContent="Cancel" />
          </div>
        </div>)
    }
  }
  const renderReadyToShipArea = () => {
    const hasVerifiedItemOrderReady = localState.itemOrderReadyList.some(itemOrderReady => itemOrderReady.info.status === ORDER_READY_STATUS.VERIFIED);
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const hasWaybillOrDO = resources.some(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const disabledButton = localState.deliverTabWarning;

    return (
      <div>
        {!hasWaybillOrDO && <div>
          <FormControlLabel
            control={
              <Checkbox
                name="no-delivery-docs-required"
                onChange={(event) => {
                  const { checked } = event.target;
                  updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
                    noDeliveryDocRequired: checked
                  }).then(() => {
                    notifySuccess(`Updated successfully`);
                    loadOrderReadyInfo();
                  }).catch(() => {
                    notifyError(`Updated failed`);
                  });
                }}
                checked={Boolean(get(localState.projectOrderReadyInfo, ['info', 'noDeliveryDocRequired']))}
              />
            }
            label="No delivery documents necessary."
          />
        </div>}
        {localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED &&
          (<div>
            <TextField
              style={{ width: '50%' }}
              label="Shipping Notes (if any)"
              variant="outlined"
              multiline={true}
              rows={6}
              rowsMax={6}
              onChange={(e) => updateLocalState({
                shipmentNotes: e.target.value,
                errors: {},
              })}
              value={localState.shipmentNotes}
              margin="dense"
              fullWidth
            />
          </div>)}
        {renderEditShipmentNotesArea()}
        {hasVerifiedItemOrderReady !== false && (
          <div>
            <Tooltip title="Clicking this will notify partner to download documents (if any) and ship the order.">
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleReadyToShipClick}
                  disabled={disabledButton || localState.readyToShipProcessing === true}
                >
                  {localState.readyToShipProcessing === true && (
                    <CircularProgress className={classes.circularProgress} size={20} />
                  )}
                  Ready to ship
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  const renderItemsShippedSentence = () => {
    const allItemsShipped = localState.itemOrderReadyList.every(itemOrderReady => itemOrderReady.info.status === ORDER_READY_STATUS.IN_TRANSIT);
    if (!allItemsShipped) {
      return null;
    }
    return (
      <Typography>
        {localState.itemOrderReadyList.length > 1
          ? "All items have shipped."
          : "Item has shipped."
        }
      </Typography>
    )
  }

  const renderShipmentNotes = () => {
    const toRender = DELIVERY_STATUSES.includes(localState.projectOrderReadyStatus)
      && !localState.showEditShippingNotesContainer;

    return toRender &&
      (<div style={{ marginTop: '15px', marginBottom: '25px', }}>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <div style={{ fontWeight: 'bold' }}>Shipment Notes</div>
          {isAdminOrHigherRole(userRole) &&
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => updateLocalState({ showEditShippingNotesContainer: true })}
            >
              <EditIcon />
            </IconButton>}
        </div>
        <div style={{ maxWidth: '600px', wordWrap: 'break-word', marginTop: '5px' }}>
          {localState.shipmentNotes ?? 'N.A.'}
        </div>
      </div>)
  }

  const renderShipmentInfo = () => {
    const orderShipmentInfo = localState.orderShipmentInfo || [];
    if (isEmpty(orderShipmentInfo)) {
      return null;
    }
    return (
      <div
        style={{
          margin: '1rem 0',
        }}
      >
        <Typography className={classes.heading}>
          Shipment Info
        </Typography>
        {orderShipmentInfo.map(shipmentInfo => {
          return (
            <div
              key={shipmentInfo.id}
              style={{
                marginLeft: '0.5rem',
              }}
            >
              <Typography>Shipment provider: <b>{shipmentInfo.shipmentProvider}</b></Typography>
              <Typography>Shipment Tracking Number: <b>{shipmentInfo.trackingNumber}</b></Typography>
            </div>
          );
        })}
      </div>
    );
  }

  const renderDeliverTab = () => {
    return (
      <div>
        {localState.noDeliveryDocRequired === false && (
          <Typography>
            Please download following document(s) to proceed with shipping of the order.
          </Typography>
        )}
        {localState.isNonSgpSupplier && localState.projectOrderReadyStatus === ORDER_READY_STATUS.READY_TO_SHIP && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              maxWidth: 800,
            }}
          >
            <Typography>
              Key in a shipment tracking number, along with shipment provider and click 'Order Shipped'
            </Typography>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    labelId="shipment-provider"
                    label="Shipment Provider"
                    id="shipment-provider"
                    variant="outlined"
                    select
                    onChange={(e) => updateLocalState({
                      shipmentProvider: e.target.value,
                      errors: {},
                    })}
                    margin="dense"
                    fullWidth
                    error={!!localState.errors.shipmentProvider}
                    helperText={localState.errors.shipmentProvider}
                  >
                    {SHIPMENT_PROVIDERS.map(shipment => {
                      return (
                        <MenuItem key={shipment} value={shipment}>{shipment}</MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                {localState.shipmentProvider === 'Other' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="other-shipment-provider"
                      label="Other Shipment Provider"
                      variant="outlined"
                      fullWidth
                      size='small'
                      margin="dense"
                      onChange={(e) => updateLocalState({
                        otherShipmentProvider: e.target.value,
                        errors: {},
                      })}
                      error={!!localState.errors.otherShipmentProvider}
                      helperText={localState.errors.otherShipmentProvider}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="tracking-number-na"
                        onChange={(event) => updateLocalState({
                          shipmentTrackingNA: event.target.checked,
                          errors: {},
                        })}
                      />
                    }
                    label="Shipment number not available"
                  />
                </Grid>
                {localState.shipmentTrackingNA !== true && (
                  <Grid item xs={12}>
                    <TextField
                      id="shipment-tracking-number"
                      label="Shipment Tracking Number"
                      variant="outlined"
                      fullWidth
                      size='small'
                      margin="dense"
                      onChange={(e) => updateLocalState({
                        shipmentTrackingNumber: e.target.value,
                        errors: {},
                      })}
                      error={!!localState.errors.shipmentTrackingNumber}
                      helperText={localState.errors.shipmentTrackingNumber}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        <Box style={{ height: '0.5rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            {localState.noDeliveryDocRequired === false && !isAdminOrHigherRole(userRole) && (
              <Typography className={classes.heading}>
                Delivery Order
              </Typography>
            )}
            {isAdminOrHigherRole(userRole) && (
              <Typography className={classes.heading}>
                Upload Delivery Order
              </Typography>
            )}
            {isAdminOrHigherRole(userRole) && (
              <div style={{ marginTop: '1rem' }}>
                <FilesUploadButton
                  id="upload-delivery-order"
                  buttonText="Upload Delivery Order"
                  handleUploadFiles={(files) => handleUploadOrderReadyResource({
                    file: files[0],
                    resourceType: ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
                  })}
                  multiple={false}
                />
              </div>
            )}
            <div style={{ marginTop: '1rem' }}>
              {renderDeliveryOrderFiles()}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {renderWaybillFiles()}
          </Grid>
        </Grid>
        {isAdminOrHigherRole(userRole) && renderReadyToShipArea()}
        {renderShipmentNotes()}
        {renderOrderShippedButton()}
        {renderItemsShippedSentence()}
        {renderShipmentInfo()}
        <Divider style={{ marginTop: '1rem' }} />
        <div>
          <div style={{ marginTop: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Shipment Instruction</div>
          <p>
            Please follow these simple rules for a smooth shipment of your order.
          </p>
          {isMobile
            ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img src={DeliveryInstructionsVertical} style={{ width: '60%', height: '60%' }} alt="deliver-process" />
              </div>
            )
            : <img src={DeliveryInstructions} style={{ width: '60%', height: '60%' }} alt="deliver-process" />}
        </div>
      </div>
    );
  }

  const renderTab = () => {
    const tabName = TABS[localState.selectedTab];
    switch (tabName) {
      case TAB_KEYS.REVIEW:
        return renderReviewTab();
      case TAB_KEYS.DELIVER:
        return renderDeliverTab();
      default:
        return null;
    }
  }

  const renderDeleteFilePopup = () => {
    return (
      <YesNoPopup
        open={localState.showDeleteFilePopup}
        handleNo={() => updateLocalState({ showDeleteFilePopup: false })}
        handleYes={() => handleDeleteResourceFile()}
        body={`${localState.deleteResource.fileName}`}
      />
    );
  }

  const renderAdminAddImagePopup = () => {
    const imageResources = localState.selectedItemOrderReady.resources.filter(resource => lowerCase(resource.type).includes('image'));
    const existingImageResourceTypes = imageResources.map(resource => resource.type);
    const availablePredefinedImages = Object.values(ORDER_READY_RESOURCE_TYPES).filter(type => lowerCase(type).includes('image') && !existingImageResourceTypes.includes(type));

    return (
      <Dialog
        open={localState.showAdminAddImagePopup}
        onClose={() => updateLocalState({ showAdminAddImagePopup: false })}
        maxWidth="md"
      >
        <DialogTitle>
          Add more required images
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.container}>
            <div
              style={{
                display: 'flex',
                gap: '0.3rem',
              }}
            >
              {availablePredefinedImages.map(imageType => {
                return (
                  <FormControlLabel
                    key={imageType}
                    control={
                      <Checkbox
                        name="required"
                        onChange={(event) => {
                          const { checked } = event.target;
                          if (checked) {
                            updateLocalState({
                              addPredefinedRequiredImages: [
                                ...localState.addPredefinedRequiredImages,
                                imageType,
                              ]
                            });
                          } else {
                            updateLocalState({
                              addPredefinedRequiredImages: [
                                ...localState.addPredefinedRequiredImages
                                  .filter(type => type !== imageType),
                              ]
                            });
                          }
                        }}
                      />
                    }
                    label={imageType}
                  />
                );
              })}
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="required"
                  onChange={(event) => {
                    const { checked } = event.target;
                    updateLocalState({ addAnotherRequiredImage: checked });
                  }}
                  checked={localState.addAnotherRequiredImage}
                />
              }
              label="Add another required image"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.3rem',
                paddingLeft: '0.5rem',
              }}
            >
              <TextField
                id="new-image-title"
                label="Image Title"
                variant="outlined"
                fullWidth
                size='small'
                disabled={!localState.addAnotherRequiredImage}
                onChange={(e) => updateLocalState({ newImageTitle: e.target.value })}
              />
              <TextField
                id="new-image-description"
                label="Image Description"
                multiline
                minRows={3}
                variant="outlined"
                fullWidth
                size='small'
                disabled={!localState.addAnotherRequiredImage}
                onChange={(e) => updateLocalState({ newImageDescription: e.target.value })}
              />
            </div>
          </div>
          <Typography
            style={{ fontStyle: 'italic' }}
            variant="caption"
          >
            You can upload sample image after click OK button
          </Typography>
        </DialogContent>
        <DialogActions>
          <GreyButton
            btnContent="Cancel"
            onBtnClick={() => updateLocalState({ showAdminAddImagePopup: false })}
          />
          <BlueButton
            btnContent="OK"
            onBtnClick={handleAdminAddRequireImages}
          />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      <PageTitle title={`Project ${projectID}`} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          padding: '0 1rem',
        }}
      >
        <Typography>Status:</Typography>
        {renderProjectOrderReadyStatus()}
      </div>
      <div
        style={{
          marginTop: '1rem',
          padding: '0 1rem',
        }}
      >
        <Tabs
          value={localState.selectedTab}
          onChange={(event, newValue) => updateLocalState({ selectedTab: newValue })}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
              iconWrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
            key={TAB_KEYS.REVIEW}
            label={TAB_KEYS.REVIEW}
            icon={<ArtTrack />}
          />
          <CloneProps>
            {tabProps => (
              <Tooltip title={localState.disableDeliverTab ? 'Complete order ready review before proceeding to Deliver' : ''}>
                <div>
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                      disabled: classes.disabledTab,
                      iconWrapper: classes.iconLabelWrapper,
                      labelIcon: classes.labelIcon,
                    }}
                    icon={<LocalShipping />}
                    {...tabProps}
                    key={TAB_KEYS.DELIVER}
                    label={(
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        {TAB_KEYS.DELIVER}
                        {localState.deliverTabWarning === true && (
                          <Tooltip title="Delivery documents have not been uploaded">
                            <Warning style={{ marginTop: 0, color: 'red', opacity: '1 important' }} />
                          </Tooltip>
                        )}
                      </div>
                    )}
                    disabled={localState.disableDeliverTab}
                  />
                </div>
              </Tooltip>
            )}
          </CloneProps>
        </Tabs>
        <div
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {renderTab()}
        </div>
      </div>
      {localState.showImageView === true && (
        <Dialog
          open={localState.showImageView}
          onClose={() => updateLocalState({ showImageView: false })}
          maxWidth="md"
        >
          <ImageGallery
            items={localState.imageUrlList}
            startIndex={localState.showImageIndex}
          />
        </Dialog>
      )}
      {localState.showDeleteFilePopup === true && renderDeleteFilePopup()}
      {localState.showAdminAddImagePopup === true && renderAdminAddImagePopup()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
    userRole: state.auth.user.role,
    userCountry: state.auth.user.country,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ProjectOrderReady);

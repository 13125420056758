import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const pdfFlatten = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/pdf/flatten`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: {
      Authorization: getAuthorizedHeader().Authorization,
    },
    body,
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const combinedPOs = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/pdf/project/combined-pos`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const individualPOFromItemID = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/pdf/items/${itemID}/generate-po`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const adminGenerateCombinedQuotes = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/pdf/quotations/combined`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

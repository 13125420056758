import {
    GET_FABRICATION_FAILURE,
    GET_FABRICATION_SUCCESS,
    GET_FABRICATION_LOADING,
} from "../actions/fabrication";

const defaultState = {
    info: [],
    getFabricationSuccess: false,
    getFabricationFailure: false,
    getFabricationLoading: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_FABRICATION_LOADING:
            return {
                ...state, 
                getFabricationSuccess: false,
                getFabricationFailure: false,
                getFabricationLoading: true,
            }
        case GET_FABRICATION_SUCCESS:
            return {
                ...state, 
                getFabricationSuccess: true,
                getFabricationFailure: false,
                getFabricationLoading: false,
                info: action.info,
            }
        case GET_FABRICATION_FAILURE:
            return {
                ...state, 
                getFabricationSuccess: false,
                getFabricationFailure: true,
                getFabricationLoading: false,
            }
        default:
            return state;
    }
}
import React from 'react';

import { Typography } from '@mui/material';

function DisplayItemName(props) {
  const { text } = props;

  return (
    <Typography
      variant="h5"
      component="h2"
      color="textPrimary"
      display="inline"
      noWrap
    >
      {text}
    </Typography>
  );
}

export default DisplayItemName;

import React from "react";

import { Publish as PublishIcon } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {string} props.inputID
 * @param {string} props.buttonText
 * @param {() => void} props.handleUploadFiles
 * @param {boolean} props.highlightButton
 * @param {boolean} props.disabled
 * @param {boolean} props.multiple
 * @param {string} props.accept
 * @param {string} props.tooltip
 */
function FilesUploadActionButton(props) {
  const {
    inputID = "file-upload",
    buttonText = "Upload Files",
    handleUploadFiles,
    highlightButton = false,
    disabled = false,
    multiple = true,
    accept = "*",
    tooltip = "",
  } = props;

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <input
        style={{
          display: "none",
        }}
        id={inputID}
        type="file"
        multiple={multiple}
        onChange={(evt) => handleUploadFiles(evt.target.files)}
        onClick={(event) => (event.target.value = null)}
        disabled={disabled}
        accept={accept}
      />
      <Tooltip title={tooltip}>
        <label
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            color: "black",
            padding: "0.4rem",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#0000000a",
            },
            borderRadius: 4,
            textTransform: "uppercase",
            fontSize: "0.6rem",
            lineHeight: "0.8rem",
            whiteSpace: "nowrap",
          }}
          htmlFor={inputID}
        >
          <PublishIcon
            sx={{
              color: "gray",
              marginTop: 3,
            }}
          />
          <span
            style={{
              marginTop: 3,
              marginLeft: 4,
              marginRight: 4,
              cursor: "pointer",
            }}
          >
            {buttonText}
          </span>
        </label>
      </Tooltip>
    </div>
  );
}

export default FilesUploadActionButton;

// Import settings
import React from "react";

// Import color palette
import { colors } from "../palette";

// Import Material UI components
import {
    Container,
    Grid,
    Typography,
} from '@mui/material';
import {
    makeStyles,
} from 'tss-react/mui';

// Style component
const useStyles = makeStyles()((theme) => ({
  faqHeading: {
    position: 'relative',
    width: 322,
    height: 41,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '33px',
    textAlign: 'center',
    letterSpacing: '0.15em',
    color: `${colors.blue060}`
  },
  faqDescriptionText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.25rem',
    lineHeight: '33px',
    textAlign: 'center',
    letterSpacing: '0.15em',
    color: `${colors.fontGrey}`,
    padding: 15
  },
  frequentlyAskedQuestionsHeading: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '2rem',
    lineHeight: '54px',
    textAlign: 'center',
    color: `${colors.blue060}`,
    padding: '20px 20px 0px 20px'
  }
}));

function FAQHeading({ headingText }) {

    // Define styles to use in function
    const { classes } = useStyles();

    return (
        <div>
            <Container>
                <Typography className={classes.faqHeading}>
                    {headingText}
                </Typography>
            </Container>
        </div>
    )
}

function FAQDescriptionText({ descriptionText }) {

    // Define styles to use in function
    const classes = useStyles();

    return (
        <div>
            <Container>
                <Typography className={classes.faqDescriptionText}>
                    {descriptionText}
                </Typography>
            </Container>
        </div>
    )
}

export function FAQDescription({ headingText, descriptionText }) {
    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item >
                <FAQHeading headingText={headingText} />
            </Grid>
            <Grid item>
                <FAQDescriptionText descriptionText={descriptionText} />
            </Grid>
        </Grid>
    )
}




import { useParams } from "react-router-dom";
import PageTitle from "../components/PageTitle"
import EditItemForm from "../components/forms/EditItemForm"

export default function EditItem() {
  const { id } = useParams();
  return (
    <>
      <PageTitle title={`Edit Item ${id}`} />
      <EditItemForm />
    </>
  )
}

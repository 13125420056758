import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  Alert,
  Card,
  CardActions,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "../palette";

import DisplayPartDetails from "./DisplayPartDetails";
import DownloadFileItem from "../components/cards/DownloadFileItem";
import POAcknowledgementLogTable from "../components/POAcknowledgementLogTable";
import SolidBlueButton from "../components/buttons/SolidBlueButton";
import Subheader from "../components/Subheader";
import UploadFileItem from "../components/cards/UploadFileItem";

import { getFabricationInfo } from "../actions";

import { uploadPoFile, getItemSingleUser } from "../apis/itemApi";

import { isEmptyValue } from "../utils/commonUtils";
import { getAuthorizedHeader } from "../utils/apiUtils";
import { downloadS3File } from "../utils/fileUtils";

import { notifyError, notifySuccess } from "../services/notificationService";
import { getCustomerPoFileS3Key, uploadFileToS3 } from "../services/s3Service";
import { generatePresignedUrl } from "../services/s3Service";

import { ITEM_FILE_UPLOAD_TYPE } from "../constants/itemConstants";
import { PAGE_KEYS, BUTTON_KEYS } from "../constants/onboardingAdminButtonsClickedConstants";
import { useButtonClicked } from "../hooks/useButtonClicked";

function FabricationProgress(props) {
  const { user, onPageLoad, info } = props;

  const [purchaseOrderFile, setPurchaseOrderFile] = useState(null);
  const [quotationFormFile, setQuotationFormFile] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [buttonsClicked, addButtonClicked] = useButtonClicked(user.userID, PAGE_KEYS.FABRICATION);

  useEffect(() => {
    onPageLoad(user.userID);
    getItemSingleUser(user.userID)
      .then((res) => {
        // assuming only one item
        setItemDetails(res[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // info is an array of fabrication data objects
    if (info.length > 0) {
      setQuotationFormFile(info[0].quotationFile);
    }
  }, [info]);

  const handleDownloadQuotationForm = (e) => {
    e.stopPropagation();
    downloadS3File(quotationFormFile);
  };

  const handlePurchaseOrderFile = (file, itemID) => {
    setPurchaseOrderFile(file[0]);
    const uploadFilesBody = [];
    Promise.all(
      Array.from(file).map(async (file) => {
        const data = await uploadFileToS3(
          file,
          getCustomerPoFileS3Key(file, itemID)
        );
        const s3ObjectUrl = data.Location;
        const uploadedFileItem = {
          fileName: file.name,
          url: s3ObjectUrl,
          type: ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO,
        };
        let uploadItem = uploadFilesBody.find((item) => item.itemID === itemID);
        if (!uploadItem) {
          uploadItem = {
            itemID,
            files: [],
            userID: user.userID,
          };
          uploadFilesBody.push(uploadItem);
        }
        uploadItem.files.push(uploadedFileItem);
      })
    )
      .then(async () => {
        await uploadPoFile(uploadFilesBody);
        notifySuccess("File uploaded successfully!");
      })
      .catch((err) => {
        console.log(err);
        notifyError("Error uploading PO file");
      });
  };

  const onClickApproveQuotationButton = () => {
    let payload = {
      supplierID: user.userID,
      itemID: info[0].itemID,
    };

    addButtonClicked(BUTTON_KEYS.APPROVE_QUOTATION);

    try {
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/fabrication/approve-quotation`, {
        method: "POST",
        headers: getAuthorizedHeader(),
        body: JSON.stringify(payload),
      }).then((res) => {
        if (res.ok) {
          notifySuccess("Approved supplier quotation!");
        } else {
          notifyError(
            "There was an error. Please fill in the necessary fields and try again."
          );
        }
      });
    } catch (error) {
      console.log(error);
      notifyError("There was an error. Please try again.");
    }
  }

  const onClickDownloadPoButton = async () => {
    if (info.length === 0) {
      notifyError(
        "There was an error. Check if supplier has already quoted. If not, contact an admin."
      );
      return;
    }

    try {
      const payload = {
        itemID: info[0].itemID,
        supplierID: user.userID,
      }
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/get-current-po`, {
        method: "POST",
        headers: getAuthorizedHeader(),
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          response.json().then(async (res) => {
            const currentPoUrl = res.data.combinedPOsForm;
            if (!currentPoUrl) {
              notifyError(
                "There is currently no purchase order shown to the supplier. This may means that no email has been sent to the supplier yet."
              );
              return;
            }
            const fileNameParam = currentPoUrl.split('/').pop();
            const signedUrl = await generatePresignedUrl(currentPoUrl);
            downloadS3File(signedUrl, { fileNameParam });
            notifySuccess("Downloaded current PO!");
          });
        } else {
          notifyError(
            "There was an error. Check if supplier has already quoted. If not, contact an admin."
          );
        }
      });
    } catch (error) {
      console.log(error)
      notifyError("There was an error. Please try again.");
    }
  }

  const onClickSendEmailButton = () => {
    let payload = {
      supplierID: user.userID,
      companyName: user.companyName,
      supplierName: user.name,
      toEmail: user.email,
      itemID: info[0].itemID,
    };

    addButtonClicked(BUTTON_KEYS.SEND_FABRICATION_EMAIL);

    try {
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/fabrication/send-email`, {
        method: "POST",
        headers: getAuthorizedHeader(),
        body: JSON.stringify(payload),
      }).then((res) => {
        if (res.ok) {
          notifySuccess("Email successfully sent!");
        } else {
          notifyError(
            "There was an error. Please fill in the necessary fields and try again."
          );
        }
      });
    } catch (error) {
      console.log(error);
      notifyError("There was an error. Please try again.");
    }
  };

  const onClickGeneratePoButton = () => {
    if (info.length === 0) {
      notifyError(
        "There was an error. Check if supplier has already quoted. If not, contact an admin."
      );
      return;
    }

    let payload = {
      itemID: info[0].itemID,
      supplierID: user.userID,
    };

    try {
      fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/generate-combined-po`, {
        method: "POST",
        headers: getAuthorizedHeader(),
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          response.json().then(async (res) => {
            const fileNameParam = `${res.data.pdfUrl.split('/').pop()}.pdf`;
            const signedUrl = await generatePresignedUrl(res.data.pdfUrl);
            downloadS3File(signedUrl, { fileNameParam });
          });
          notifySuccess("Generated and downloaded PO!");
        } else {
          notifyError(
            "There was an error. Check if supplier has already quoted. If not, contact an admin."
          );
        }
      });
    } catch (error) {
      console.log(error);
      notifyError("There was an error. Please try again.");
    }
  }

  return (
    <Grid container spacing={4}>
      {/* Left column */}
      <Grid item xs={5}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card style={{ padding: "1rem" }} variant="outlined">
              <Subheader title="View Supplier's Quotation Form" />
              {isEmpty(quotationFormFile)
                ? (
                  <Typography>
                    No Quotation Form provided
                  </Typography>
                )
                : (<DownloadFileItem
                  onClickItem={handleDownloadQuotationForm}
                  key="download-quotation-form"
                  text="Download Quotation Form"
                />)}
            </Card>
          </Grid>
          <Grid item>
            <Card style={{ padding: "1rem" }} variant="outlined">
              <Subheader title="Purchase Order" />
              <CardActions style={{ justifyContent: "center" }}>
                <Tooltip title="1. After reviewing the quotation details and form, approve the quotation and generate PO.">
                  <div style={{ margin: '0 4px' }}>
                    <SolidBlueButton
                      btnContent={"Approve quotation"}
                      onBtnClick={onClickApproveQuotationButton}
                      size="small"
                      overrideStyle={
                        buttonsClicked.includes(BUTTON_KEYS.APPROVE_QUOTATION) 
                        ? { backgroundColor: colors.successGreen }
                        : {}}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="2. Downloads the current PO being shown to the supplier. If the PO is problematic, you can override it using below in 'Override Generated Purchase Order.'">
                  <div style={{ margin: '0 4px' }}>
                    <SolidBlueButton
                      btnContent={"Download PO"}
                      onBtnClick={onClickDownloadPoButton}
                      size="small"
                    />
                  </div>
                </Tooltip>
                <Tooltip title="3. If everything is correct (quotation details and form, generated PO), send an email notification to the supplier.">
                  <div>
                    <SolidBlueButton
                      btnContent={"Send email"}
                      disabled={!buttonsClicked.includes(BUTTON_KEYS.APPROVE_QUOTATION)}
                      onBtnClick={onClickSendEmailButton}
                      size="small"
                      overrideStyle={
                        buttonsClicked.includes(BUTTON_KEYS.SEND_FABRICATION_EMAIL) 
                        ? { backgroundColor: colors.successGreen }
                        : {}}
                    />
                  </div>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card style={{ padding: "1rem" }} variant="outlined">
              <Subheader title="Override Generated Purchase Order" />
              <Tooltip title="If the generated PO is problematic, you can upload and override the current PO being shown to the supplier.">
                <div>
                  <UploadFileItem
                    key="upload-purchase-order"
                    text="Upload Purchase Order Revision"
                    accept=".pdf"
                    onFileUpload={(file) => {
                      handlePurchaseOrderFile(file, info[0].itemID);
                    }
                    }
                    uploadState={purchaseOrderFile}
                  />
                </div>
              </Tooltip>
            </Card>
          </Grid>
          <Grid item>
            <Card style={{ padding: "1rem" }} variant="outlined">
              <Subheader title="Other Tools" />
              <CardActions style={{ justifyContent: "center" }}>
                <Tooltip title="Generate PO for your own preview.">
                  <div style={{ margin: '4px' }}>
                    <SolidBlueButton
                      btnContent={"Generate PO"}
                      onBtnClick={onClickGeneratePoButton}
                      size="small"
                    />
                  </div>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* Right column */}
      <Grid item xs={7}>
        <Card
          style={{ padding: "1rem", marginBottom: "1rem" }}
          variant="outlined"
        >
          <Subheader title="Quotation Details" />
          <Typography style={{ marginLeft: '1rem' }}>
            Part ID: {itemDetails?.itemID}
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography style={{ marginLeft: '1rem' }}>
              Quotation ID:&nbsp;{itemDetails?.quotationID}
            </Typography>
            {isEmptyValue(itemDetails?.quotationID) && (
              <Alert
                style={{
                  padding: '0 0.5rem',
                }}
                severity="error"
              >
                No quote submitted yet.
              </Alert>
            )}
          </div>
          <DisplayPartDetails
            details={itemDetails}
          />
        </Card>
        <Card style={{ padding: "1rem" }} variant="outlined">
          <Subheader title="PO Acknowledgement Log" />
          <POAcknowledgementLogTable rows={info} user={user} />
        </Card>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    info: state.fabrication.info,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: (id) => {
      dispatch(getFabricationInfo(id));
    },
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(FabricationProgress);

import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';

import {
  Button,
  InputLabel,
  Tooltip,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

import { colors } from '../../palette';

import ItemColorPalettePopup from '../popups/ItemColorPalettePopup';
import clsx from 'clsx';
import InfoMatchValue from '../info/InfoMatchValue';
import { CNC_FINISHING_COLOURS } from "../../constants/technologies";

const useStyles = makeStyles()(() => ({
  optionalBox: {
    marginTop: 15,
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  colorButton: {
    padding: '12px 18px',
    width: '100%',
    justifyContent: 'left',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    textTransform: 'none',
    fontSize: '16px',
  },
  colorButtonSmall: {
    width: '100%',
    fontSize: 15,
    padding: '0px 8px',
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    textTransform: 'none',
    justifyContent: 'left',
    height: '32px',
  },
  colorButtonMaterial: {
    borderColor: `${colors.inputBorderGrey}`,
  },
  quotationField: {
    marginTop: 8,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%'
    }
  },
  container: {
    marginBottom: '13px',
    marginLeft: '3px'
  }
}));

function ColorInputField(props) {
  const { classes } = useStyles();

  const {
    visible = false,
    value,
    colorInputLabel,
    colorPalette,
    onSubmit,
    rowDisplay = false,
    bootstrapStyle = true,
    quotationField = false,
    valueCustomer,
    required = false,
  } = props;

  const [colorDialogOpen, setColorDialogOpen] = useState(false);
  const [color, setColor] = useState(value);

  const location = useLocation()

    // Force a re-render everytime color palette changes
    // This is to prevent colors from being stuck on the previous palette when the palette changes
    useEffect(() => {
      setColor(null)
      setColor(value)
    }, [colorPalette])

  if (!visible) {
    return null;
  }

  const renderButton = () => {
    return (
      <FormControl
            key='color-selection-section'
            style={{ display: "block", paddingBottom: "1rem" }}
            required={required}
          >
            <InputLabel shrink>Color options</InputLabel> {/* shrink is temporary workaround for value overlapping with placeholder text*/}
            <Select
              id={`select-color`}
              value={color}
              onChange={(event) => {
                setColor(event.target.value);
                onSubmit(event);
              }}
              style={{ minWidth: "10rem" }}
              label={`Color options`}
            >
              {Object.keys(colorPalette).map((color) => (
                <MenuItem key={color} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
      </FormControl>
    );
  }

  const renderContent = () => {
    return (
      <div
        className={clsx(rowDisplay ? classes.rowDisplay : classes.optionalBox, quotationField ? classes.quotationField : null)}
      >
        {!quotationField && (
          <InputLabel
            className={rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel}
          >
            {colorInputLabel}
          </InputLabel>
        )}
        {location.pathname === '/verify-quotes' ? (
          <div className={classes.rowContainer}>
            {renderButton()}
            <InfoMatchValue
              name='Material Color'
              valueCustomer={valueCustomer}
              valueSupplier={value}
            />
          </div>
        ) : (quotationField
          ? (
            <Tooltip title={colorInputLabel}>
              {renderButton()}
            </Tooltip>
          )
          : renderButton()
        )}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {renderContent()}
      {/* {colorDialogOpen && <ItemColorPalettePopup
        dialogOpen={colorDialogOpen}
        colorPalette={colorPalette}
        handleDialogClose={() => setColorDialogOpen(false)}
        handleSubmitColor={(selectedColor) => {
          onSubmit(selectedColor);
          setColorDialogOpen(false);
        }}
        value={value}
      />} */}
    </div>
  );
}

export default ColorInputField;

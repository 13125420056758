// Import settings
import React from "react";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
  Button, CircularProgress
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

// Style components
const useStyles = makeStyles()((theme) => ({
  submitBtn: {
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    borderRadius: '0.3125rem',
    fontSize: '0.9375rem',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '0.75rem 1.6875rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75rem 1rem',
      fontSize: '0.75rem'
    }
  },
  smallSubmitBtn: {
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    borderRadius: '0.3125rem',
    fontSize: '0.5625rem',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '0.625rem 0.9375rem',
    textAlign: 'center'
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

function BlueButton(props) {
  const { classes } = useStyles();
  const { id, btnContent, onBtnClick, component, to, size, disabled, loading = false, className = '' } = props;
  if (size === 'small') {
    return (
      <Button
        data-cy={id}
        className={`${classes.smallSubmitBtn} ${className}`}
        onClick={onBtnClick}
        variant='contained'
        component={component ? component : 'span'}
        to={to ? to : null}
        disabled={disabled ? true : false}
      >
        {loading && <CircularProgress className={classes.circularProgress} size={20} />}
        {btnContent}
      </Button>
    )
  }
  return (
    <Button
      data-cy={id}
      className={`${classes.submitBtn} ${className}`}
      onClick={onBtnClick}
      variant='contained'
      component={component ? component : 'span'}
      to={to ? to : null}
      disabled={disabled ? true : false}
    >
      {loading && <CircularProgress className={classes.circularProgress} size={20} />}
      {btnContent}
    </Button>
  )
}

export default BlueButton;

import {TextField, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {isEmpty} from "lodash";
import libphonenumber from "google-libphonenumber";

import CountryDropDown from "./CountryDropDown";

import {COUNTRY_LIST} from "../../constants/countryConstants";

function PhoneNumberFields({
                             setCountryValue,
                             contact,
                             countryValue,
                             classes,
                             setContact,
                             contactValidated,
                             setContactValidated
                           }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const validateContact = (contact) => {
    if (isEmpty(contact) || contact.length <= 1) {
      setContactValidated(false);
      return false;
    }

    const region = COUNTRY_LIST[countryValue].code;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(contact, region);
      if (phoneUtil.isValidNumber(number)) {
        setContactValidated(true);
        return true;
      }
      setContactValidated(false);
      return false;
    } catch (e) {
      setContactValidated(false);
      return false;
    }
  };

  return (
    <div style={{display: "flex"}}>
      <CountryDropDown
        id="countryCode"
        isCountryCodeDropDown
        onSelect={(value) => {
          setCountryValue(value);
          validateContact(contact);
        }}
        valueSelected={countryValue}
      />
      <TextField
        id="contact"
        name="contact"
        className={classes.inputField}
        value={contact}
        onChange={({target}) => {
          validateContact(target.value);
          setContact(target.value);
        }}
        error={!contactValidated && contact.toString().length > 7}
        helperText={
          !contactValidated && contact.toString().length > 7
            ? "Please enter a valid contact number."
            : ""
        }
        variant="outlined"
        size="small"
        style={{width: isMobile ? "60%" : "70%"}}
      />
    </div>
  );
}

export default PhoneNumberFields;

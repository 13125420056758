import React from 'react';

import { Typography } from '@mui/material';
import {
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";

import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { downloadS3File, generateDesignFileNameForRfq } from '../../utils/fileUtils';

const useStyles = makeStyles()(() => ({
  itemPropertyKey: {
    fontWeight: "bold",
    margin: 0,
  },
  itemFile: {
    paddingTop: "10px",
    display: "flex",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    padding: '0 3px'
  },
}));


function FileDownloadListDisplay(props) {
  const { classes } = useStyles();

  const { title, urlList, itemID } = props;

  return (
    <div>
      <Typography
        gutterBottom
        variant="body1"
        className={classes.itemPropertyKey}
      >
        {title}
      </Typography>
      <div>
        {urlList.map((link) => {
          // For now, ProjectQ test parts have only one part per onboarding.
          // This can change in the future, check for this.
          const fileName = generateDesignFileNameForRfq(itemID, '01.', link.toString().split('.').pop());
          return (
          <div
            key={link}
            className={classes.itemFile}
          >
            <div>
              <AttachFileIcon color="action" fontSize="small" />
            </div>
            <div>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  downloadS3File(link, { fileNameParam : fileName });
                }}
              >
                {fileName}
              </Link>
            </div>
          </div>
        )})}
      </div>
    </div>
  );
}

export default FileDownloadListDisplay;

import {
    GET_ASSESSMENT_FAILURE,
    GET_ASSESSMENT_SUCCESS,
    GET_ASSESSMENT_LOADING,
} from "../actions/assessment";

const defaultState = {
    info: [],
    getAssessmentSuccess: false,
    getAssessmentFailure: false,
    getAssessmentLoading: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_ASSESSMENT_LOADING:
            return {
                ...state, 
                getAssessmentSuccess: false,
                getAssessmentFailure: false,
                getAssessmentLoading: true,
            }
        case GET_ASSESSMENT_SUCCESS:
            return {
                ...state, 
                getAssessmentSuccess: true,
                getAssessmentFailure: false,
                getAssessmentLoading: false,
                info: action.info,
            }
        case GET_ASSESSMENT_FAILURE:
            return {
                ...state, 
                getAssessmentSuccess: false,
                getAssessmentFailure: true,
                getAssessmentnLoading: false,
            }
        default:
            return state;
    }
}
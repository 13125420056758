import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const getAuthorizedHeader = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.get('token')}`,
  };
};

export const fetchWithTimeout = async (resource, options = {}) => {
  const { timeout = 8000 } = options;

  const controller = new window.AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

import BootstrapInput from './BootstrapInput';

import { colors } from '../../palette';
import BootstrapInputSmall from './BootstrapInputSmall';

const useStyles = makeStyles()(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function TechnologyInputField(props) {
  const { classes } = useStyles();

  const {
    value,
    onChange,
    technologyOptions = [],
    rowDisplay = false,
    bootstrapStyle = true,
    id,
    required = false,
  } = props;

  const handleChange = (evt) => {
    const newTech = evt.target.value;
    onChange(newTech);
  }

  if (bootstrapStyle) {
    return (
      <div
        data-cy={id}
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        <InputLabel
          className={rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel}
        >
          Technology
        </InputLabel>
        <Select
          id='technology'
          className={classes.selectField}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value}
          onChange={handleChange}
          required={required}
        >
          {technologyOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }

  return (
    <TextField
      select
      label="Technology"
      variant="outlined"
      onChange={handleChange}
      value={value}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      required={required}
    >
      {technologyOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default TechnologyInputField;

import { isEmpty } from 'lodash';
import { useEffect, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormControl, TextField } from '@mui/material';
import { Grid, Box, Container, Select, MenuItem, InputLabel } from '@mui/material';

import { useItemInputConfig } from "../../hooks/useItemInputConfig";

import TechnologyInputField from '../inputs/TechnologyInputField';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../inputs/ThreeDLayerThicknessField';

import UploadFileItem from '../cards/UploadFileItem';

import SolidBlueButton from '../buttons/SolidBlueButton';

import { is2DGenerationSupport } from '../../utils/fileUtils';
import { is3DPTechnology } from '../../utils/itemUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology
} from '../../utils/inputUtils';

import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  standardToleranceOptions,
  toleranceOptions,
  threeDToleranceOptions,
} from '../../constants/NewPartConstants';
import { ppe3dpInfillOptions, ppe3dpLayerThicknessOptions } from '../../constants/PPEConstants';
import {
  ALL_CAD_FILES_TYPES,
  ALL_TECHNICAL_DRAWING_FILES_TYPES,
  QC_REPORT_TEMPLATE_DEFAULT,
} from '../../constants/predefinedItemConstants';

import {
  uploadFileToS3,
  getOnboardingCadFileS3Key,
  getOnboardingTechDrawingS3Key,
  getUploadedImageFileS3Key,
  uploadPublicFileToS3,
} from '../../services/s3Service';
import { notifySuccess, notifyError } from '../../services/notificationService';
import { convert2DImage } from '../../services/convert2DImageService'

import { addNewOnboardingItem } from '../../apis/onboardingApi';
import SingleImageWithUpload from '../images/SingleImageWithUpload';

function AddNewItemForm() {
  const [name, setName] = useState('');
  const [quantity, setItemQuantity] = useState(1);

  const [technology, setTechnology] = useState(null);
  const [material, setMaterial] = useState(null);
  const [threeDTechnology, setThreeDTechnology] = useState(null);

  const [surfaceFinish, setSurfaceFinish] = useState(null);
  const [color, setColor] = useState(null); // surface finish color

  const [materialColor, setMaterialColor] = useState(null); // 3d printing
  const [threeDInfill, setThreeDInfill] = useState(null);
  const [threeDLayerThickness, setThreeDLayerThickness] = useState(null);

  const [otherTechnology, setOtherTechnology] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');

  const [tolerance, setTolerance] = useState(0.100);
  const [leadTime, setLeadTime] = useState(7);

  const [qcReportTemplate, setQcReportTemplate] = useState(QC_REPORT_TEMPLATE_DEFAULT);

  const [description, setDescription] = useState(null);

  const [imageFile, setImageFile] = useState(null);

  const [cadFile, setCadFile] = useState(null);
  const [techDrawing, setTechDrawing] = useState(null);

  const [cadFileExtension, setCadFileExtension] = useState(null);
  const [cadFileImagePreview, setCadFileImagePreview] = useState(null);

  const isMounted = useRef(true);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      setSurfaceFinishColorOptions,
    }
  ] = useItemInputConfig({
    setTechnology: setTechnology,
    setMaterial: setMaterial,
    setThreeDTechnology: setThreeDTechnology,
    setSurfaceFinish: setSurfaceFinish,
    setMaterialColor: () => { }, // Due to color choice differences from main portal
    setColor: () => { }, // Due to color choice differences from main portal
  });

  const navigate = useNavigate();

  const techIs3DPrinting = technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
      ? 'Powder Coat Color'
      : 'Color';


  useEffect(() => {
    loadTechnologyOptions()
    return () => {
      // Component Will Unmount
      isMounted.current = false;
    };
  }, [])

  useEffect(() => {
    if (isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
    }
    if (!is3DPTechnology(technology)) {
      loadMaterialCategoryOptions(params);
    } else {
      load3DTechnologyOptions();
    }
  }, [technology]);

  useEffect(() => {
    if (isEmpty(material) || isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    }
    loadSurfaceFinishOptions(params);
    loadMaterialColorOptions(params);
  }, [material, technology, threeDTechnology]);

  useEffect(() => {
    if (isEmpty(surfaceFinish)) {
      setSurfaceFinishColorOptions([]);
      return;
    }
    const params = {
      technology,
      material,
      surfaceFinish,
    }
    loadSurfaceFinishColorOptions(params);
  }, [surfaceFinish, technology, material]);

  useEffect(() => {
    if (isEmpty(threeDTechnology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
    }
    loadThreeDMaterialOptions(params);
    if (threeDTechnology === THREE_D_P_FDM_TECH) {
      setThreeDInfill(threeDPrintingInfillDefault);
      setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
    } else {
      setThreeDInfill(null);
      setThreeDLayerThickness(null);
    }
  }, [threeDTechnology]);

  const renderTechnologyInputField = () => {
    return (
      <TechnologyInputField
        value={technology}
        onChange={(newTech) => {
          setTechnology(newTech);
          if (newTech !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
            setThreeDTechnology(null);
            setThreeDInfill(null);
            setThreeDLayerThickness(null);
            setTolerance(toleranceOptions[newTech] || 0.1);
          }
        }}
        technologyOptions={technologyOptions}
        id="technology-upload-3d-files"
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderMaterialCategories = () => {
    return (
      <MaterialCategoriesInputField
        technology={technology}
        visible={!techIs3DPrinting}
        value={material}
        onSelect={(value) => setMaterial(value)}
        materialCategoryOptions={materialCategoryOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderSurfaceFinishingField = () => {
    return (
      <SurfaceFinishingField
        visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish)}
        value={surfaceFinish}
        onSelect={(newValue) => setSurfaceFinish(newValue)}
        surfaceFinishOptions={surfaceFinishOptions}
        selectColorSurfaces={selectColorSurfaces}
        bootstrapStyle={false}
        required
      />
    );
  }

  // Clear color if there is no surface finish color options
  useEffect(() => {
    if (isEmpty(surfaceFinishColorOptions)) {
      setColor(null);
    }
  }, [surfaceFinishColorOptions])

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        colorInputLabel={colorInputLabel}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={event => setColor(event.target.value)}  // onSubmit is called on event
        required
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel="Material Color"
        colorPalette={materialColorOptions}
        onSubmit={(event) => setMaterialColor(event.target.value)}  // onSubmit is called on event
        required
      />
    );
  }

  // If 3D Printing is selected, we need to set tolerance to default for that 3D printing technology
  // Has to be side effect since we need to wait for the default threeDTechnology state to be set
  useEffect(() => {
    if (techIs3DPrinting) {
      setTolerance(threeDToleranceOptions[threeDTechnology]);
    }
  }, [techIs3DPrinting, threeDTechnology])

  const render3DPrintingTechnologySelect = () => {
    return (
      <ThreeDPrintingTechnologyInputField
        visible={techIs3DPrinting}
        value={threeDTechnology}
        onChange={(newValue) => {
          setThreeDTechnology(newValue);
          setTolerance(threeDToleranceOptions[newValue]);
        }}
        threeDTechnologyOptions={threeDTechnologyOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        technology={technology}
        threeDTechnology={threeDTechnology}
        visible={techIs3DPrinting}
        value={material}
        onSelect={(_material) => setMaterial(_material)}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DInfillSelect = () => {
    return (
      <ThreeDInfillSelectField
        visible={is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH}
        value={threeDInfill}
        onSelect={(newValue) => setThreeDInfill(newValue)}
        ppe3dpInfillOptions={ppe3dpInfillOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DLayerThicknessSelect = () => {
    return (
      <ThreeDLayerThicknessField
        visible={is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH}
        value={threeDLayerThickness}
        onSelect={(newValue) => setThreeDLayerThickness(newValue)}
        ppe3dpInfillOptions={ppe3dpLayerThicknessOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const handleCadFileUpload = (file) => {
    setSubmitButtonEnabled(false);
    return uploadFileToS3(file[0], getOnboardingCadFileS3Key(file[0]))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setCadFile(s3ObjectUrl);
        const fileExtension = file[0].name.split('.').pop();
        setCadFileExtension(fileExtension);
        notifySuccess('CAD File uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading CAD File!');
        console.error(err)
        setCadFile(null);
        setCadFileExtension(null);
      }).finally(() => {
        setSubmitButtonEnabled(true);
      });
  }

  const renderCadFileUpload = () => {
    return (
      <UploadFileItem
        itemKey="cad-file"
        text="Upload CAD File"
        onFileUpload={(file) => handleCadFileUpload(file)}
        uploadState={cadFile}
        accept={ALL_CAD_FILES_TYPES.map(fileType => `.${fileType}`).join(',')}
        style={{ marginTop: '20px', height: '80px', width: '250px' }}
      />
    )
  }

  const handleTechDrawingUpload = (file) => {
    setSubmitButtonEnabled(false);
    return uploadFileToS3(file[0], getOnboardingTechDrawingS3Key(file[0]))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setTechDrawing(s3ObjectUrl);
        notifySuccess('Technical Drawing uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading Technical Drawing!');
        console.error(err)
        setTechDrawing(null);
      }).finally(() => {
        setSubmitButtonEnabled(true);
      });
  }

  const renderTechDrawingUpload = () => {
    return (
      <UploadFileItem
        itemKey="tech-drawing"
        text="Upload Technical Drawing"
        onFileUpload={(file) => handleTechDrawingUpload(file)}
        uploadState={techDrawing}
        accept={ALL_TECHNICAL_DRAWING_FILES_TYPES.map(fileType => `.${fileType}`).join(',')}
        style={{ marginTop: '20px', height: '80px', width: '250px' }}
      />
    )
  }

  const onClickSubmit = () => {
    if (!name) {
      notifyError('Missing name field.');
      return;
    }
    if (!quantity) {
      notifyError('Missing quantity field.');
      return;
    }
    if (!technology) {
      notifyError('Missing technology field.');
      return;
    }
    if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
      notifyError('Missing Custom Technology field.');
      return;
    }
    if (!material) {
      notifyError('Missing material field.');
      return;
    }
    if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
      notifyError('Missing Custom Material field.');
      return;
    }
    if (!surfaceFinish) {
      notifyError('Missing surface finish field.');
      return;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
      notifyError('Missing Custom Surface Finish field.');
      return;
    }
    if (!tolerance) {
      notifyError('Missing tolerance field.');
      return;
    }
    if (!leadTime) {
      notifyError('Missing lead time field.');
      return;
    }
    if (!cadFile && !techDrawing) {
      notifyError('Missing CAD File/Technical Drawing.');
      return;
    }

    const payload = {
      referenceName: name,
      technology: technology,
      threeDTechnology: threeDTechnology,
      threeDInfill: threeDInfill,
      threeDLayerThickness: threeDLayerThickness,
      quantity: quantity,
      material: materialColor && techIs3DPrinting ? `${materialColor} ${material}` : material,
      surfaceFinish: surfaceFinish,
      color: color,
      tolerance: tolerance,
      leadTime: leadTime,
      cadFile: cadFile,
      technicalDrawing: techDrawing,
      qcReportTemplate: qcReportTemplate,
      description: description,
      imageFile: imageFile ?? cadFileImagePreview,
    };
    addNewOnboardingItem(payload).then(() => {
      notifySuccess("Successfully added new item!");
      navigate("/manage-items");
    })
      .catch((err) => {
        console.error(err);
        notifyError(err.message);
      });
  }

  const renderSubmitButton = () => {
    return (
      <Box sx={{ margin: '16px 0' }} textAlign={{ "xs": "center", "md": "right" }}>
        <SolidBlueButton
          btnContent="Submit"
          disabled={!submitButtonEnabled}
          onBtnClick={onClickSubmit}
        />
      </Box>
    )
  }

  const renderNameField = () => {
    return (
      <TextField
        label="Name"
        id="name"
        onChange={(e) => setName(e.target.value)}
        value={name}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderQuantityField = () => {
    return (
      <TextField
        label="Quantity"
        id="quantity"
        type="number"
        onChange={(e) => setItemQuantity(e.target.valueAsNumber)}
        value={quantity}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderToleranceField = () => {
    return (
      <FormControl
        sx={{ marginTop: '8px' }}
        required
      >
        <InputLabel variant="outlined" htmlFor="tolerance">
          Tolerance
        </InputLabel>
        <Select
          label="Tolerance"
          id="tolerance"
          value={tolerance}
          onChange={(evt) => {
            setTolerance(evt.target.value);
          }}
        >
          {standardToleranceOptions.map((option) => (
            <MenuItem key={option} value={option}>
              +/- {option}mm
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const renderLeadTimeField = () => {
    return (
      <TextField
        label="Lead Time (days)"
        id="lead-time"
        type="number"
        inputProps={{ min: 0 }}
        onChange={(e) => setLeadTime(e.target.valueAsNumber)}
        value={leadTime}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderQcReportTemplateField = () => {
    return (
      <TextField
        label="QC Report Template (Link)"
        id="qc-report-template"
        onChange={(e) => {
          if (e.target.value) {
            setQcReportTemplate(e.target.value)
          } else {
            setQcReportTemplate(null)
          }
        }}
        value={qcReportTemplate}
        sx={{ marginTop: '8px' }}
      />
    )
  }

  const renderDescriptionField = () => {
    return (
      <TextField
        label="Description"
        id="description"
        onChange={(e) => {
          if (e.target.value) {
            setDescription(e.target.value)
          } else {
            setDescription(null);
          }
        }}
        value={description}
        sx={{ marginTop: '8px' }}
        multiline
      />
    )
  }

  useEffect(() => {
    if (!cadFile || !is2DGenerationSupport(cadFileExtension)) {
      setCadFileImagePreview(null);
      return;
    }

    convert2DImage({ file_url: cadFile })
      .then((twoDImageUrl) => {
        setCadFileImagePreview(twoDImageUrl["s3_file_url"]);
        setImageFile(null); // Reset image file
      })
  }, [cadFile, cadFileExtension])

  const handleImageFileUpload = (imageFile) => {
    setSubmitButtonEnabled(false);
    return uploadPublicFileToS3(imageFile, getUploadedImageFileS3Key(imageFile))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setImageFile(s3ObjectUrl);
        notifySuccess('Image uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading image!');
        console.error(err)
        setImageFile(null);
      }).finally(() => {
        setSubmitButtonEnabled(true);
      });
  }

  const renderCadFileImagePreview = () => {
    return (
      <SingleImageWithUpload
        imageUrl={cadFileImagePreview}
        handleUpload={handleImageFileUpload}
      />
    )
  }

  return (
    <Container>
      <Grid container spacing={2} column={2} direction="row" justifyContent={{ xs: "center", md: "flex-start" }}>
        <Grid item md={4} xs={10}>
          <Grid container direction="column" alignItems="center">
            {renderCadFileImagePreview()}

            {renderCadFileUpload()}
            {renderTechDrawingUpload()}
          </Grid>
        </Grid>
        <Grid item md={6} xs={10}>
          <Grid container direction="column" marginLeft={{ md: "50px" }}>
            {renderNameField()}
            {renderQuantityField()}

            {renderTechnologyInputField()}
            {isCustomTechnology(technology) && (
              <TextField
                label="Custom Technology"
                id="custom-technology"
                onChange={(e) => setOtherTechnology(e.target.value)}
              />
            )}
            {render3DPrintingTechnologySelect()}

            {renderMaterialCategories()}
            {render3DPrintingMaterialField()}
            {isCustomMaterial(material) && (
              <TextField
                label="Custom Material"
                id="custom-material"
                onChange={(e) => setOtherMaterial(e.target.value)}
                required
              />
            )}

            {renderMaterialColorInputField()}

            {render3DInfillSelect()}
            {render3DLayerThicknessSelect()}

            {renderSurfaceFinishingField()}
            {isCustomSurfaceFinish(surfaceFinish) && (
              <TextField
                label="Custom Finish"
                id="custom-finish"
                onChange={(e) => setOtherSurfaceFinish(e.target.value)}
                required
              />
            )}
            {renderSurfaceFinishColor()}
            {renderToleranceField()}
            {renderLeadTimeField()}

            {renderQcReportTemplateField()}

            {renderDescriptionField()}

            {renderSubmitButton()}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddNewItemForm;

import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { ROLE_TYPES } from "../constants";
import { BACKEND_SERVICE_URL } from "../constants";
import { getCurrentDateStr } from "../utils/dateTimeUtils";
import { notifyError, notifySuccess } from "../services/notificationService";


export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAILURE = "RESEND_EMAIL_FAILURE";
export const RESET_STATUS = "RESET_STATUS";

const cookies = new Cookies();

const receiveSignup = (props, redirectPath) => {
  return {
    type: SIGNUP_SUCCESS,
  };
};

const signupError = () => {
  toast.error("Signup Error!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: SIGNUP_FAILURE
  };
};

const openVerifyEmailPage = (email, name, props) => {
  // props.history.push("/verify-email"); is deprecated code as no more withRouter
  // in react router dom
  return {
    type: SIGNUP_SUCCESS,
    email,
    name,
  };
};

const emailUsed = () => {
  toast.error("Email has been used. Please login with your email.", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: SIGNUP_FAILURE
  };
};

const verifyEmailFailure = () => {
  return {
    type: VERIFY_EMAIL_FAILURE
  };
};

const resendEmailSuccess = () => {
  toast.success("A new verification email has been sent to your email!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: RESEND_EMAIL_SUCCESS
  };
};

const resendEmailFailure = () => {
  toast.error("Failed to resend verification email. Please try again!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: RESEND_EMAIL_FAILURE
  };
};

export const resetStatus = () => {
  return {
    type: RESET_STATUS,
  };
};

export const verifyEmailSuccess = (country) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    country,
  };
};

export const signupUser = (userData, props, isAdmin = false) => (dispatch) => {
  // for onboarding no buyer, sign up only for supplier
  const requestUrl =`${BACKEND_SERVICE_URL}/signup-onboarding-supplier`
  const newUser = {
    ...userData,
    registrationDate: getCurrentDateStr(),
  };
  fetch(requestUrl, {
    method: "POST",
    headers:
      isAdmin
        ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("token")}`
        }
        : {
          "Content-Type": "application/json",
        },
    body: JSON.stringify(newUser)
  })
    .then(function (response) {
      if (response.ok) {
        notifySuccess("Successfully created a new account!");
        dispatch(openVerifyEmailPage(userData.email, userData.name, props));
      } else {
        response.json().then(result => {
          if (result.error === "Email has been used.") {
            dispatch(emailUsed());
          } else {
            dispatch(signupError());
          }
        });
      }
    })
    .catch(function () {
      dispatch(signupError());
    });
};

// export const signupContinueUser = (userData) => async (dispatch) => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/signup-buyer-continue`;

//   fetch(requestUrl, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${cookies.get("token")}`
//     },
//     body: JSON.stringify(userData)
//   })
//     .then(response => {
//       if (!response.ok) {
//         notifyError("Failed to sign up. Please contact our admin.")
//       } 
//     })
//     .catch(() => {
//       notifyError("Failed to sign up. Please contact our admin.")
//     });
// };

export const signupCompleteUser = (userData, props) => async (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/signup-onboarding-supplier-complete`;
  await fetch(requestUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData)
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(signupError());
      } else {
        dispatch(receiveSignup(props));
        notifySuccess("Welcome to Factorem!");
      }
    })
    .catch(() => {
      dispatch(signupError());
    });
};

export const resendEmail = (userEmail, userName) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/verify-email`;
  let data = {
    email: userEmail,
    name: userName
  };
  fetch(requestUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status === 200) {
        // Successful response
        dispatch(resendEmailSuccess());
      } else {
        dispatch(resendEmailFailure());
      }
    })
    .catch(() => {
      dispatch(resendEmailFailure());
    });
};

export const verifyEmail = (confirmationCode) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/email-confirmation/`;
  let data = {
    confirmationCode: confirmationCode,
    isOnboardingSupplier: true,
  };
  fetch(requestUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
    .then(async response => {
      if (response.status !== 200) {
        // Invalid verification
        dispatch(verifyEmailFailure());
        return;
      }      
        const res = await response.json();
        const { country } = res.data;

        // Successful response
        dispatch(verifyEmailSuccess(country));
    })
    .catch(() => {
      dispatch(verifyEmailFailure());
    });
};
// Import settings
import React, { useCallback, useEffect, useState, forwardRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import _, { get } from "lodash";
// import { CsvBuilder } from 'filefy';

//Import material UI components
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip
} from "@mui/material";

import {
  // ArrowDownward,
  // Alert,
  // Check,
  // ChevronLeft,
  // ChevronRight,
  // Clear,
  Delete as DeleteIcon,
  // FilterList,
  // FirstPage,
  // LastPage,
  // Remove,
  // SaveAlt,
  // Search,
  // ViewColumn
} from "@mui/icons-material";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";

// import MaterialTable from "material-table";
import { makeStyles } from "tss-react/mui";

//Import actions
import {
  deleteSupplierOrder,
  getAllMyOrders,
} from "../actions";

import * as fileUtils from '../utils/fileUtils';

import { ORDER_STATUS } from "../constants";
// import { transformCsvExportData } from "../utils/csvExportUtils";
// import { generateCurrentCustomDateTimeString } from "../util";
import { isEmptyValue } from "../utils/commonUtils";
import { TECHNOLOGY_OPTION_TYPE } from "../constants/NewPartConstants";
import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from "../constants/itemTableConstants";
import {
  quotationStatusColumn,
  supplierPriceStrDisplayCol,
} from "../constants/quotationTableConstants";
import { colors } from "../palette";
import { convertPriceToCurrency } from "../utils/currencyUtils";
import { getQuotationExchangeRate, rejectedReasonTextUtil } from "../utils/quotationUtils";
import { downloadS3File } from "../utils/fileUtils";
import { PageTitle } from "../components/PageTitle";

const useStyles = makeStyles()(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }
}));

export function MyOrders(props) {
  const { classes } = useStyles();
  let navigate = useNavigate();

  const {
    user,
    orders = [],
    onPageLoad,
    deleteQuote,
    currency,
    exchangeRate,
    isMyordersLoading,
  } = props;

  const { techTags } = user;
  const is3DPrintingSupplier = techTags && techTags.includes(TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING.toLowerCase());

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [quoteToDelete, setQuoteToDelete] = useState(null);
  const [sortedQuotesById, setQuotesSortById] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setQuoteToDelete(null);
  };

  const confirmDelete = () => {
    if (quoteToDelete != null) {
      deleteQuote(quoteToDelete);
    }
    setQuoteToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleClickOpen = quoteID => {
    setOpenDeleteDialog(true);
    setQuoteToDelete(quoteID);
  };

  const defaultColumns = [
    { headerName: "Quote ID", field: "quotationID" },
    {
      headerName: "Date of Quote",
      field: "dateOfOrder",
      renderCell: rowData => rowData.value ? rowData.value.substring(0, 10) : "",
      width: 150,
    },
    {
      headerName: "Expiry date",
      field: "dateOfExpiry",
      renderCell: rowData => rowData.value ? rowData.value.substring(0, 10) : "",
      width: 150,
    },
    {
      headerName: "Part ID",
      field: "itemID",
      renderCell: rowData => {
        return (
          <Button
            variant="outlined"
            href={`projects/${rowData.row.projectID}/quote?technology=${rowData.row.technology}&viewQuote=true`}
          >
            {rowData.row.itemID}
          </Button>
        )
      }
    },
    {
      headerName: "Project ID",
      field: "projectID",
      renderCell: rowData => {
        return (
          <Button
            variant="outlined"
            href={`projects/${rowData.row.projectID}/quote?technology=${rowData.row.technology}&viewQuote=true`}
          >
            {rowData.row.projectID}
          </Button>
        )
      }
    },
    {
      ...materialWithColorCol,
      width: 200,
    },
    {
      ...surfaceFinishingWithColorCol,
      width: 200,
    },
    is3DPrintingSupplier ? {
      headerName: "3D Printing Technology",
      field: "metadata.threeDTechnology",
      render: rowData => get(rowData, 'metadata.threeDTechnology'),
    } : null,
    is3DPrintingSupplier ? {
      headerName: "3D Infill",
      render: rowData => {
        const infill = get(rowData, 'metadata.threeDInfill');
        return isEmptyValue(infill) ? '' : `${(infill * 100).toFixed(0)}%`;
      },
    } : null,
    is3DPrintingSupplier ? {
      headerName: "3D Layer Thickness",
      render: rowData => {
        const thickness = get(rowData, 'metadata.threeDLayerThickness');
        return isEmptyValue(thickness) ? '' : `${thickness}mm`;
      },
    } : null,
    {
      ...supplierPriceStrDisplayCol({ currency, exchangeRate }),
    },
    { headerName: "Quantity", field: "quantity" },
    {
      headerName: "Lead time",
      field: 'leadTime',
      renderCell: rowData => `${rowData.row.leadTime} working day(s)`,
      width: 130,
    },
    // {
    //   headerName: "CMM Price",
    //   render: rowData => {
    //     return convertPriceToCurrency({
    //       price: (rowData.metadata && rowData.metadata.cmmPrice) || 0,
    //       currency,
    //       exchangeRate: getQuotationExchangeRate(rowData, currency) || exchangeRate,
    //     });
    //   },
    //   cellStyle: {
    //     minWidth: 200,
    //     textAlign: 'center',
    //   },
    // },
    {
      headerName: "My remarks",
      field: 'remarks',
      render: rowData => `${rowData.row.ppeQuoteRemarks || rowData.row.remarks || ''}`,
      width: 100,
    },
    // {
    //   headerName: "Hardware Part",
    //   export: false,
    //   render: rowData => {
    //     const projectPath = `projects/${rowData.projectID}/quote?technology=${rowData.technology}&viewQuote=true`;
    //     const btnText = rowData.status === "expired"
    //       ? 'Quote again'
    //       : 'View Part';
    //     return (
    //       <Button
    //         size="small"
    //         variant="outlined"
    //         disableElevation
    //         onClick={() => navigate(projectPath)}
    //       >
    //         {btnText}
    //       </Button>
    //     );
    //   },
    // },
    {
      ...quotationStatusColumn,
    },
    {
      headerName: 'Quotation File',
      field: 'quotationFile',
      renderCell: ({ row: rowData }) => {
        if (!rowData.quotationFile) {
          return 'N.A.';
        }
        return (
          <Button
            data-cy="download-btn"
            size='small'
            variant='outlined'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              fileUtils.downloadS3File(rowData.quotationFile);
            }}
          >
            Download
          </Button>
        );
      },
      width: 120,
    },
    // {
    //   headerName: 'Rejected Reason',
    //   field: 'reasonRejected',
    //   exportData: rowData => {
    //     return rowData.reasonRejected
    //       && !!rowData.reasonRejected.length
    //       ? rejectedReasonTextUtil(rowData.reasonRejected)
    //       : '';
    //   },
    //   hidden: true,
    //   export: true,
    // },
    // {
    //   headerName: "Customer remarks",
    //   field: "customerRemarks",
    //   cellStyle: {
    //     maxWidth: "280px",
    //   },
    // },
    // {
    //   headerName: "Purchase Order",
    //   render: rowData => {
    //     const status = rowData.ppeQuoteStatus || rowData.status;
    //     return rowData.purchaseOrderForm && status === ORDER_STATUS.ACCEPTED
    //       ? (
    //         <Link
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             downloadS3File(rowData.purchaseOrderForm);
    //           }}
    //         >
    //           Download
    //         </Link>
    //       )
    //       : (
    //         <div>N.A.</div>
    //       );
    //   },
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 150,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <Tooltip title="Delete Quote" arrow>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete Quote"
              onClick={() => handleClickOpen(params.row.quotationID)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    onPageLoad();
    setColumns(defaultColumns.filter(col => !!col));
  }, [currency]);

  useEffect(() => {
    setQuotesSortById(orders);
  }, [orders]);

  return (
    <div>
      <PageTitle title="My Quotes" />
      {isMyordersLoading
        ? (
          <CircularProgress color="primary" className={classes.circularProgress} />
        )
        : sortedQuotesById && sortedQuotesById.length === 0
          ? (
            <div>
              <Alert severity="warning">You have not submitted any quotes.</Alert>
            </div>
          )
          : (
            <div
              style={{
                padding: '0 1rem',
              }}
            >
              <DataGrid
                autoHeight
                rows={orders}
                columns={columns}
                editMode="row"
                getRowId={(row) => row.quotationID}
                rowModesModel={rowModesModel}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 50]}
                disableRowSelectionOnClick
              />
            </div>
          )
      }
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Delete quote?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You are deleting a quote that you have submitted previously.
              Please ensure that you are deleting the correct quote as this
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    orders: state.myorders.myorders,
    isMyordersLoading: state.myorders.getMyordersLoading,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates && state.auth.rates[state.auth.location.currency],
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onPageLoad: () => dispatch(getAllMyOrders()),
    deleteQuote: quoteID => dispatch(deleteSupplierOrder(quoteID, props))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MyOrders);

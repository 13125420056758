import React from 'react';

import { Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  itemPropertyKey: {
    fontWeight: "bold",
    margin: 0,
  },
}));

function InfoTitle(props) {
  const { classes } = useStyles();

  const { title } = props;

  return (
    <Typography
      gutterBottom
      variant="body1"
      className={classes.itemPropertyKey}
    >
      {title}
    </Typography>
  );
}

export default InfoTitle;

import { Cookies } from "react-cookie";
import { BACKEND_SERVICE_URL } from "../constants";
import { getAuthorizedHeader } from "../utils/apiUtils";
import queryString from 'query-string';

import { notifyError, notifySuccess } from '../services/notificationService';

export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const EDIT_USER_LOADING = "EDIT_USER_LOADING";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";
export const INSERT_PROPERTIES_SUCCESS = "INSERT_PROPERTIES_SUCCESS";
export const INSERT_PROPERTIES_FAILURE = "INSERT_PROPERTIES_FAILURE";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

const cookies = new Cookies();

const getUsers = () => {
  return {
    type: GET_USERS_LOADING,
  };
};

const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    users,
  };
};

const getUsersFailure = () => {
  notifyError("Unable to get users. Please refresh the page.");
  return {
    type: GET_USERS_FAILURE,
  };
};

const editUserSuccess = (editedUser) => {
  notifySuccess("User successfully edited!");
  return {
    type: EDIT_USER_SUCCESS,
    editedUser,
  };
};

const editUserFailure = () => {
  notifyError("User cannot be edited. Please try again.");
  return {
    type: EDIT_USER_FAILURE,
  };
};

const deleteUserSuccess = (deleteUserID) => {
  notifySuccess("User successfully deleted!");
  return {
    type: DELETE_USER_SUCCESS,
    deleteUserID,
  };
};

const deleteUserFailure = () => {
  notifyError("User cannot be deleted. Please try again.");
  return {
    type: DELETE_USER_FAILURE,
  };
};

export const getAllUsers = (params = {}) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users?${queryString.stringify(params)}`;
  fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
    .then(function (response) {
      dispatch(getUsers());
      response.json().then((users) => {
        if (users.success) {
          dispatch(getUsersSuccess(users.data));
        } else {
          dispatch(getUsersFailure());
        }
      });
    })
    .catch(function () {
      dispatch(getUsersFailure());
    });
};

export const getAllOnboardingUsers = () => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-suppliers`;
  fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
    .then(function (response) {
      dispatch(getUsers());
      response.json().then((users) => {
        if (users.success) {
          dispatch(getUsersSuccess(users.data));
        } else {
          dispatch(getUsersFailure());
        }
      });
    })
    .catch(() => {
      dispatch(getUsersFailure());
    });
};

export const editOnboardingUserData = (newData) => (dispatch) => {
  const userID = newData.userID;
  const data = {
    ...newData,
  };
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/users/onboarding/edit/${userID}`;
  fetch(requestUrl, {
    method: "POST",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(editUserFailure());
      } else {
        dispatch(editUserSuccess(userID));
        dispatch(getAllOnboardingUsers());
      }
    })
    .catch(() => {
      dispatch(editUserFailure());
    });
};

export const deleteUser = (deleteUserID) => (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/users/delete/${deleteUserID}`;
  fetch(requestUrl, {
    method: "POST",
    headers: getAuthorizedHeader(),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(deleteUserFailure());
      } else {
        dispatch(deleteUserSuccess(deleteUserID));
      }
    })
    .catch(() => {
      dispatch(deleteUserFailure());
    });
};

export const recoverPassword = (resetData, token, props) => (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/recover/${token}`;
  return fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(resetData)
  })
    .then(async (response) => {
      if (!response.ok) {
        notifyError("Password cannot be reset. Please try again.");
        return false;
      } else {
        notifySuccess("Password reset successful!");
        return true;
      }
    })
    .catch((e) => {
      notifyError("Password cannot be reset. Please try again.");
      return false;
    });
};

import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

import { ROLE_TYPES } from "./constants";

import { Cookies } from "react-cookie";

import { logoutUser, retrieveExchangeRate } from './actions';

import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import ManageSuppliers from "./pages/ManageSuppliers";
import SupplierProgress from "./pages/SupplierProgress";
import Reset from "./components/Reset";
import SignUp from "./pages/SignUp";
import EmailConfirmation from "./pages/EmailConfirmationSignUp";
import BrowseWithOptions from "./pages/BrowseWithOptions";
import MyOrders from "./pages/MyOrders";
import ManageItems from "./pages/ManageItems";
import ProjectQuoteForSupplier from "./pages/ProjectQuoteForSupplier";
import VerifyEmail from "./pages/VerifyEmail";
// import SupplierFaq from "./pages/SupplierFaq";
import FAQPage from "./containers/FAQPage";
import SupplierAcceptedOrders from "./pages/SupplierAcceptedOrders";
import ProjectOrderReady from "./pages/ProjectOrderReady";
import AllOrderReadyManagement from "./pages/AllOrderReadyManagement";
import Recover from "./pages/Recover";
import PartnershipAgreement from "./pages/PartnershipAgreement";
import AddNewItem from "./pages/AddNewItem";
import EditItem from "./pages/EditItem";
import GoMainSupplierPortal from "./pages/GoMainSupplierPortal";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App(props) {
  const cookie = new Cookies();

  const dispatch = useDispatch();

  const { isAuthenticated, isVerifying, role } = props;

  // retrieve rate once on first load
  useEffect(() => {
    if (isAuthenticated) {dispatch(retrieveExchangeRate())
    }
  }, [dispatch, isAuthenticated])

  // Declare function that sets a time interval to check validity of token
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  const checkToken = () => {
    const token = cookie.get("token");
    let isTokenValid = true;
    if (!token) {
      isTokenValid = false;
    } else {
      const accessTokenDecoded = jwt_decode(token);
      // Check if the token has expired
      const currentTimestamp = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds
      if (accessTokenDecoded.exp && accessTokenDecoded.exp < currentTimestamp) {
        isTokenValid = false;
      }
    }
    if (!isTokenValid && isAuthenticated) {
      dispatch(logoutUser());
      toast.warn(
        "You have been logged out due to inactivity. Please log in again to access the portal"
      );
    }
  };

  // Call the functions to check user login tokens
  checkToken();
  useInterval(checkToken, 6 * 1000);

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>Factorem Onboarding</title>
        <meta
          name="description"
          content="On-demand manufacturing platform for ordering high-quality parts"
        />
      </Helmet>
      <Routes>
        <Route
          element={
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
          }
        >
          <Route
            path="/"
            element={
              role === ROLE_TYPES.SUPPLIER ? (
                <GoMainSupplierPortal />
              ) : role === ROLE_TYPES.ADMIN ||
              role === ROLE_TYPES.SUPER_ADMIN ? (
                <ManageSuppliers />
              ) : role === ROLE_TYPES.ONBOARDING_SUPPLIER ? (
                <BrowseWithOptions />
              ) : null // should not reach here as checks done in Login.jsx
            }
          />
          <Route
            path="/supplier-progress/:userID"
            element={<SupplierProgress />}
          />
          <Route path="/order-ready-management" element={<AllOrderReadyManagement />} />
          <Route path="/orders" element={<MyOrders />} />
          <Route path="/accepted-orders" element={<SupplierAcceptedOrders />} />
          <Route path="/partnership-agreement" element={<PartnershipAgreement />} />
          <Route path="/projects/:projectID/order-ready" element={<ProjectOrderReady />} />
          <Route path="/manage-items" element={<ManageItems />} />
          <Route path="/add-new-item" element={<AddNewItem />} />
          <Route path="/edit-item/:id" element={<EditItem />} />
          <Route
            path="/projects/:projectID/quote"
            element={<ProjectQuoteForSupplier />}
          />
          <Route path="/supplier-faq" element={<FAQPage />} />
          <Route path="/browse-parts" element={<BrowseWithOptions />} />
        </Route>
        {/* routes that do not require authentication */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/reset" element={<Reset />} />
        <Route
          path="/email-confirmation/:confirmationCode"
          element={<EmailConfirmation />}
        />
        <Route path="/recover" element={<Recover />} />
      </Routes>
    </QueryClientProvider>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth?.isAuthenticated,
    isVerifying: state.auth?.isVerifying,
    role: state.auth?.user?.role,
  };
}

export default connect(mapStateToProps)(App);

import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { isNumber } from 'lodash';

import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';

const useStyles = makeStyles()(() => ({
  infoIcon: {
    marginLeft: '10px',
  },
}));

/**
 *
 * @param {object} props
 * @param {any} props.valueCustomer
 * @param {any} props.valueSupplier
 * @param {string} props.name
 */

function InfoMatchValue(props) {
  const { valueCustomer, valueSupplier, name } = props;
  const classes = useStyles();

  if (valueCustomer === valueSupplier) {
    return (
      <CheckIcon
        className={classes.infoIcon}
        toolTipText={`${name} matches customer request`}
      />
    );
  }

  let newValueCustomer = valueCustomer;
  if (name === '3D Infill') {
    newValueCustomer = isNumber(valueCustomer)
      ? `${Math.round(valueCustomer * 100)}%`
      : valueCustomer;
  } else if (name === '3D Layer Thickness') {
    newValueCustomer = isNumber(valueCustomer)
      ? `${valueCustomer.toFixed(2)} mm`
      : valueCustomer;
  }
  return (
    <WarningIcon
      className={classes.infoIcon}
      toolTipText={`Customer request: ${newValueCustomer}`}
    />
  );
}

export default InfoMatchValue;

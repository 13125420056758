import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProjectSingleQuoteItemDisplay from '../list-items/ProjectSingleQuoteItemDisplay';

function ProjectBulkQuotationsForm(props) {
  const [items, setItems] = useState([]);

  const {
    projectBulkQuotationsForm,
    currency,
  } = props;

  useEffect(() => {
    if (projectBulkQuotationsForm) {
      const { items: formItems } = projectBulkQuotationsForm;
      setItems(formItems || []);
    }
  }, [projectBulkQuotationsForm]);

  return (
    <div>
      {items.map(item => (
        <ProjectSingleQuoteItemDisplay
          key={item.itemID}
          item={item}
          currency={currency}
        />
      ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projectBulkQuotationsForm: state.projectBulkQuotationsForm,
    currency: state.auth.location.currency,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ProjectBulkQuotationsForm);

import { useEffect, useState } from "react";
import { getButtonClicked } from "../apis/onboardingApi";
import { notifyError } from "../services/notificationService";

export const useButtonClicked = (supplierID, pageKey) => {
  const [buttonsClicked, setButtonsClicked] = useState([]);

  const addButtonClicked = (buttonClicked) => {
    setButtonsClicked([...buttonsClicked, buttonClicked])
  }

  useEffect(() => {
    getButtonClicked({ supplierID, pageKey })
    .then((res) => {
      setButtonsClicked(res)
    })
    .catch((err) => {
      notifyError(err);
    });
  }, [supplierID, pageKey])

  return [buttonsClicked, addButtonClicked]
}

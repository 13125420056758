export const PAGE_KEYS = {
  INITIATION: 'INITIATION', 
  FABRICATION: 'FABRICATION',
  ASSESSMENT: 'ASSESSMENT',
  ONBOARD: 'ONBOARD',
}

export const BUTTON_KEYS = {
  SEND_INITIATION_EMAIL: 'SEND_INITIATION_EMAIL',
  APPROVE_QUOTATION: 'APPROVE_QUOTATION',
  SEND_FABRICATION_EMAIL: 'SEND_FABRICATION_EMAIL',
  SEND_ASSESSMENT_EMAIL: 'SEND_ASSESSMENT_EMAIL',
  SEND_ONBOARD_EMAIL: 'SEND_ONBOARD_EMAIL',
}

// Import settings
import React, { Fragment } from 'react';
import { sampleSize } from 'lodash';

import { Grid } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import {
  Settings,
  WatchLaterOutlined,
  VerifiedUserOutlined,
  TrendingUpOutlined,
  SupervisedUserCircle,
  BuildRounded,
  ShoppingCartRounded,
} from '@mui/icons-material';

const useStyles = makeStyles()({
  mobileIcon: {
    marginRight: '0.3rem',
    verticalAlign: 'middle',
  },
});

/**
 * @param {Object} props
 * @param {Number} props.size 
 */
function Description(props) {
  const { classes } = useStyles();
  const { size } = props;
  return (
    <Fragment>
      {sampleSize([
        <Grid item>
          <Settings className={classes.mobileIcon}/>
          Digital Supply Chain At Your Fingertips
        </Grid>, 
        <Grid item>
          <WatchLaterOutlined className={classes.mobileIcon}/>
          Order In Clicks, Delivery In Days
        </Grid>, 
        <Grid item>
          <VerifiedUserOutlined className={classes.mobileIcon}/>
          On-Time Delivery, Uncompromised Quality
        </Grid>, 
        <Grid item>
          <ShoppingCartRounded className={classes.mobileIcon}/>
          No Minimum Order Quantity
        </Grid>,
        <Grid item>
          <BuildRounded className={classes.mobileIcon}/>
          Custom Parts On-Demand
        </Grid>,
        <Grid item>
          <TrendingUpOutlined className={classes.mobileIcon}/>
          Power Your Production Process
        </Grid>,
        <Grid item>
          <SupervisedUserCircle className={classes.mobileIcon}/>
          Access Southeast Asia’s Largest Verified Capacity
        </Grid>,
        ], size)}
    </Fragment>
  );
}

export default Description;
import React from "react";

import { colors } from "../../palette";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(() => ({
  textAlign: "center",
  justifyContent: "center",
  height: "8vh",
  lineHeight: "8vh",
  background: colors.inputLabelGrey,
  textDecoration: "underline",
  cursor: "pointer",
}));

function DownloadFileItem(props) {
  const { key, text, onClickItem, style } = props;
  return (
    <Item key={key} elevation={4} onClick={onClickItem} style={style}>
      {text}
    </Item>
  );
}

export default DownloadFileItem;

import React, { useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';

import { colors, FACTOREM_BLUE } from '../../palette';
import { MATERIAL_STR } from '../../constants/quotationConstants';
import { useMaterialInfo } from '../../hooks/useMaterialInfo';
import { isEmpty } from 'lodash';
import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import InfoIcon from '../icons/InfoIcon';

const useStyles = makeStyles()(() => ({
  inputLabel: {
    fontSize: 16,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  inputLabelRowDisplayFixWidth: {
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function ThreeDPrintingMaterialField(props) {
  const { classes } = useStyles();

  const {
    technology,
    threeDTechnology,
    visible = false,
    threeDMaterialOptions = [],
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    defaultThreeDMaterial,
    required = false,
  } = props;

  const [
    {
      description,
      datasheetUrl,
    },
    {
      setParams,
    }
  ] = useMaterialInfo();

  useEffect(() => {
    if (isEmpty(technology) || isEmpty(value) || isEmpty(threeDTechnology) || technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
      return;
    }
    const params = {
      technology: TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
      category: threeDTechnology,
      material: value,
    }
    setParams(params);
  }, [value, threeDTechnology]);

  const handleChange = (evt) => {
    const newMaterial = evt.target.value;
    onSelect(newMaterial);
  }

  if (!visible) {
    return null;
  }

  const renderMenuItems = () => {
    return threeDMaterialOptions.map((option) => {
      const label = defaultThreeDMaterial === option
        ? (
          <span>
            {option}&nbsp;
            <span
              style={{
                fontStyle: 'italic',
                color: FACTOREM_BLUE,
                fontSize: '0.8rem',
              }}
            >
              (Preferred)
            </span>
          </span>
        )
        : option;
      return (
        <MenuItem key={option} value={option}>
          {label}
        </MenuItem>
      )
    })
  }

  const renderDataSheetUrl = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          minWidth: 200,
        }}
      >
        <a
          style={{
            fontSize: 'small',
            fontStyle: 'italic',
          }}
          href={datasheetUrl}
          target="_blank"
        >
          {value} Datasheet
        </a>
      </div>
    )
  }

  if (bootstrapStyle) {
    return (
      <div
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        <div
          className={rowDisplay ? classes.inputLabelRowDisplayFixWidth : null}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 7,
          }}
        >
          <InputLabel
            className={rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel}
          >
            Material
          </InputLabel>

          {description && (
            <div
              style={{
                marginLeft: '0.5rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <InfoIcon toolTipText={description} />
            </div>
          )}
          {!rowDisplay && datasheetUrl && renderDataSheetUrl()}
        </div>
        <Select
          className={classes.selectField}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          id='material'
          value={value}
          renderValue={value => value}
          onChange={handleChange}
          required={required}
        >
          {renderMenuItems()}
        </Select>
        {rowDisplay && datasheetUrl && renderDataSheetUrl()}
      </div>
    )
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <TextField
        name={MATERIAL_STR}
        labelId="material"
        label="Material"
        id="material"
        variant="outlined"
        select
        value={value}
        SelectProps={{
          renderValue: value => value,
        }}
        onChange={handleChange}
        margin="dense"
        fullWidth
        required={required}
      >
        {renderMenuItems()}
      </TextField>
    </FormControl>
  );
}

export default ThreeDPrintingMaterialField;

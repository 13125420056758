import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { resendEmail } from "../actions/createUser";
import { Button, Container, Paper } from "@mui/material";
import { colors, FACTOREM_BLUE } from "../palette";
import { useQueryParams } from "../hooks/useQueryParamsHook";
import Background from "../components/backdrops/Background";

const useStyles = makeStyles()((theme) => ({
  body: {
    display: "flex",
    fontSize: "14pt",
    textAlign: "left",
    flexDirection: "column",
    letterSpacing: "-0.5pt",
  },
  container: {
    height: "100%",
    display: "grid",
    alignContent: "center",
    justifyItems: "center",
  },
  emailText: {
    display: "flex",
    fontSize: "16pt",
    fontWeight: "bold",
    justifyContent: "center",
    color: colors.buttonBlue,
    margin: "1.25rem 0 1.25rem 0",
  },
  footer: {
    display: "flex",
    fontSize: "11pt",
    marginBottom: "1px",
    alignItems: "center",
    color: colors.fontFaintGrey,
  },
  loginButton: {
    width: "100%",
    fontWeight: 600,
    fontSize: "14pt",
    marginBottom: "5px",
    textTransform: "none",
    marginTop: "0.875rem",
    color: colors.fontWhite,
    borderRadius: "0.375rem",
    backgroundColor: colors.buttonBlue,
    "&:hover": {
      backgroundColor: FACTOREM_BLUE,
    },
  },
  paper: {
    borderRadius: 5,
    display: "flex",
    padding: "3.5rem 3rem",
    flexDirection: "column",
    boxShadow: "0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)",
    [theme.breakpoints.down("sm")]: {
      padding: "1.75rem 1.25rem",
    },
  },
  resendButton: {
    textTransform: "none",
    textDecoration: "none",
    color: colors.buttonBlue,
    ".visited": {
      color: colors.buttonBlue,
    },
  },
  title: {
    fontSize: "24pt",
    fontWeight: "bold",
    letterSpacing: "-1pt",
    paddingBottom: "15pt",
  },
}));

export function VerifyEmail(props) {
  const { classes } = useStyles();
  const urlParams = useQueryParams();

  const { userEmail, userName, resendEmail } = props;

  const displayEmail = urlParams.get("email") || userEmail;

  return (
    <Container component="main" className={classes.container} maxWidth="sm">
      <Background />
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.title}>Verify your email address</div>
        <div className={classes.body}>
          Please check your email for verification instructions. <br />
          We sent a verification email to:
          <div className={classes.emailText}>{displayEmail}</div>
        </div>
        <div className={classes.footer}>
          <div>Didn't receive the email?</div>
          <Button
            type="button"
            variant="text"
            className={classes.resendButton}
            onClick={() => resendEmail(displayEmail, userName)}
          >
            Resend email.
          </Button>
        </div>
        <Button
          type="button"
          color="secondary"
          className={classes.loginButton}
          component={Link}
          to="/login"
          data-cy="back-to-login"
        >
          Back to Login
        </Button>
      </Paper>
    </Container>
  );
}

function mapStateToProps(state, props) {
  return {
    userEmail: state.createUser.email,
    userName: state.createUser.name,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    resendEmail: (userEmail, userName) =>
      dispatch(resendEmail(userEmail, userName)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(VerifyEmail);

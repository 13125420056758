import { COUNTRY_NAMES } from './countryConstants';

export const CURRENCY_CODE = {
  SGD: 'SGD',
  MYR: 'MYR',
  USD: 'USD',
  AUD: 'AUD',
}

export const SUPPORTED_CURRENCIES = [
  CURRENCY_CODE.SGD,
  CURRENCY_CODE.MYR,
  CURRENCY_CODE.USD,
  CURRENCY_CODE.AUD,
]

export const COUNTRY_CURRENCY_MAPPING = {
  [COUNTRY_NAMES.SINGAPORE]: CURRENCY_CODE.SGD,
  [COUNTRY_NAMES.MALAYSIA]: CURRENCY_CODE.MYR,
  [COUNTRY_NAMES.AMERICA]: CURRENCY_CODE.USD,
  [COUNTRY_NAMES.AUSTRALIA]: CURRENCY_CODE.AUD,
}

export const COUNTRY_CODE_CURRENCY_MAPPING = {
  SG: CURRENCY_CODE.SGD,
  MY: CURRENCY_CODE.MYR,
  US: CURRENCY_CODE.USD,
  AU: CURRENCY_CODE.AUD,
}

export const CURRENCY_SYMBOL_MAPPING = {
  [CURRENCY_CODE.SGD]: 'S$',
  [CURRENCY_CODE.MYR]: 'RM',
  [CURRENCY_CODE.USD]: 'US$',
  [CURRENCY_CODE.AUD]: 'A$',
}

export const DEFAULT_MYR_EXCHANGE_RATE = 3.3;

import React from 'react';

import { Tooltip } from '@mui/material';

import WarningIconMui from '@mui/icons-material/ReportProblemOutlined';

import { colors } from '../../palette';

function WarningIcon(props) {
  const { toolTipText, className, style } = props;
  return (
    <Tooltip
      title={toolTipText}
      placement='top'
      arrow
      className={className}
      style={style}
    >
      <WarningIconMui
        style={{
          fontSize: '1.5rem',
          color: colors.warningYellow,
        }}
      />
    </Tooltip>
  );
}

export default WarningIcon;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { getAllProjectOrderReady, getProjectOrderReadyInfo, updateOrderCollected } from '../apis/orderReadyApi';

import { MCTItemCollectedButton } from '../components/buttons/actionButtons/MCTActionButton';
import YesNoPopup from '../components/popups/YesNoPopup';
import { FlexRow } from '../components/layouts/FlexLayouts';

import OrderReadyStatusDisplay from '../pages/orderReady/OrderReadyStatusDisplay';

import { isEmptyValue } from '../utils/commonUtils';

import { ORDER_READY_STATUS } from '../constants/orderReadyConstants';

function DeliveryStep(props) {
  const { user, switchToAssessmentTab } = props;

  const [userID, setUserID] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [openCollectItemPopup, setOpenCollectItemPopup] = useState(false);
  const [poAcknowledgedVersion, setPoAcknowledgedVersion] = useState(1);

  const { data: allProjectOrderReady = [], isLoading: isLoadingOrders, error: orderError } = useQuery(
    'getAllProjectOrderReady',
    getAllProjectOrderReady
  );

  const { data: allPorInfo = {}, isLoading: isLoadingProjectInfo, error: projectInfoError } = useQuery(
    [projectID, userID, poAcknowledgedVersion],
    async () => {
      if (isEmptyValue(projectID) || isEmptyValue(userID)) {
        return {};
      }
      return await getProjectOrderReadyInfo({
        projectID,
        supplierID: userID,
        poAcknowledgedVersion
      });
    }
  );

  useEffect(() => {
    if (isEmpty(user)) {
      return;
    }
    setUserID(user.userID);
  }, [user]);

  useEffect(() => {
    if (isEmpty(user) || isEmpty(allProjectOrderReady)) {
      return;
    }
    const orderReady = allProjectOrderReady.find(or => or.supplierID === user.userID);
    if (orderReady) {
      setProjectID(orderReady.projectID);
      setPoAcknowledgedVersion(orderReady.poAcknowledgedVersion);
    }
  }, [user, allProjectOrderReady]);

  const ior = (allPorInfo?.itemOrderReady?.length > 0 && allPorInfo?.itemOrderReady[0].info) || {};
  const isItemDelivered = !isEmptyValue(ior) && ior.status === ORDER_READY_STATUS.DELIVERED;
  const isItemInTransit =  !isEmptyValue(ior) && ior.status === ORDER_READY_STATUS.IN_TRANSIT;

  const renderStatus = (status) => {
    return (
      <OrderReadyStatusDisplay status={status} />
    )
  }

  const renderProjectOrderReadyStatus = () => {
    const projectStatus = allPorInfo?.projectOrderReady?.info?.status;
    return renderStatus(projectStatus);
  }

  if (isLoadingOrders || isLoadingProjectInfo) {
    return <div>Loading...</div>;
  }

  if (orderError || projectInfoError) {
    return <div>Error loading data!</div>;
  }

  return projectID && (
    <div>
      View Order Ready Management&nbsp;
      <Link
        to={`/projects/${projectID}/order-ready?supplierID=${userID}`}
      >
        here
      </Link>
      <div
        style={{
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <FlexRow>
          {`Status of item #${ior.itemID}:`}
          {renderProjectOrderReadyStatus()}
        </FlexRow>
        <div>
          {!isItemDelivered && (
            <MCTItemCollectedButton
              onClick={() => {
                setOpenCollectItemPopup(true);
              }}
              acceptedItems={allPorInfo?.itemOrderReady}
            />
          )}
        </div>
      </div>
      <div>
        {!isItemDelivered && 'Click on \'Collect Items\' to mark the item as collected and to proceed on to Assessment.'}
      </div>
      <YesNoPopup
        title={`Mark this item #${ior.itemID} as collected?`}
        open={openCollectItemPopup}
        handleNo={() => setOpenCollectItemPopup(false)}
        handleYes={() => {
          updateOrderCollected(allPorInfo.projectOrderReady.info.projectOrderReadyID, { itemOrderReadyIDList: [ior.itemOrderReadyID] });
          setOpenCollectItemPopup(false);
          switchToAssessmentTab();
        }}
        body={isItemInTransit ? '' : 'Note that the order is not in transit yet.'}
        showCloseButton={false}
      />
    </div>
  );
}

export default DeliveryStep;

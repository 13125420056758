import React from 'react';

import { MenuItem, TextField } from '@mui/material';

import { ppe3dpInfillOptions } from '../../../constants/PPEConstants';
import { THREE_D_INFILL } from '../../../constants/quotationConstants';

function ThreeDInfillFieldSelect(props) {
  const {
    value,
    onChange,
    error,
  } = props;

  return (
    <TextField
      name={THREE_D_INFILL}
      select
      label="3D Infill"
      variant="outlined"
      value={value}
      onChange={onChange}
      error={error}
      helperText={error}
      margin="dense"
      fullWidth
    >
      {ppe3dpInfillOptions.map((option) => {
        const displayedText = `${Math.round(option * 100)}%`;
        return (
          <MenuItem key={displayedText} value={option}>
            {displayedText}
          </MenuItem>
        )
      })}
    </TextField>
  );
}

export default ThreeDInfillFieldSelect;

// Import settings
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

// Import color palette
import { colors, FACTOREM_BLUE } from "../palette";

// Import material UI components
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import factoremLogoSmall from "../assets/img/factorem-logo-with-name-small.png";
import factoremLogoWhite from "../assets/img/factorem-logo-white.png";

// Import actions
import { resetStatus, signupUser } from "../actions/createUser";

// Import customized component
import { isEmpty } from "lodash";
import * as emailValidator from "../utils/emailValidator";
import {
  getContactWithCountryCode,
  getDefaultProfilePic,
} from "../utils/userUtils";
import { COUNTRY_LIST } from "../constants/countryConstants";
import Slideshow from "../components/Slideshow";
import Description from "../components/Description";
import PhoneNumberFields from "../components/forms/PhoneNumberFields";

const useStyles = makeStyles()((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "30pt",
    letterSpacing: "-0.6px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    fontSize: "14pt",
    marginBottom: "1.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11pt",
      textAlign: "center",
    },
  },
  signup: {
    marginTop: 12,
    fontSize: "14pt",
    fontWeight: "bold",
    textTransform: "none",
    color: colors.fontWhite,
    background: colors.buttonBlue,
    "&:hover": {
      backgroundColor: FACTOREM_BLUE,
    },
  },
  goToLogin: {
    width: "100%",
    fontSize: "14pt",
    fontWeight: "bold",
    textTransform: "none",
    color: colors.fontGrey,
    background: colors.fontWhite,
    border: `solid 1px ${colors.fontGrey}`,
  },
  conditions: {
    fontSize: 12,
    marginTop: 10,
    lineHeight: 1.4,
    fontWeight: 500,
    marginBottom: 25,
    color: colors.fontBlackGrey,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  inputField: {
    width: "100%",
    textTransform: "none",
    color: colors.fontGrey,
    marginBottom: "0.8rem",
  },
  inputLabel: {
    width: "100%",
    fontWeight: 600,
    fontSize: "12pt",
    color: colors.fontBlack,
  },
  container: {
    height: "100%",
    overflow: "hidden",
    flexWrap: "nowrap",
    position: "relative",
    alignItems: "center",
    backgroundColor: FACTOREM_BLUE,
    [theme.breakpoints.down("sm")]: {
      overflow: "scroll",
      backgroundSize: "cover",
      backgroundImage: colors.mobileBackground,
    },
  },
  video: {
    left: "25%",
    height: "100%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
  },
  form: {
    height: "100%",
    position: "relative",
    alignItems: "center",
    background: colors.mainLightBlue,
    [theme.breakpoints.down("sm")]: {
      background: "none",
    },
  },
  mobile: {
    fontSize: "10pt",
    paddingTop: "2rem",
    color: colors.fontWhite,
  },
  mobileIcon: {
    marginRight: "0.3rem",
    verticalAlign: "middle",
  },
  paper: {
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
    padding: "1.75rem 1.55rem",
    boxShadow: "0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)",
  },
}));

function SignUp(props) {
  const { openVerifyEmailPage, resetCreateUserStatus, signupSuccess } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [countryValue, setCountryValue] = useState("Singapore");
  const [nameValidated, setNameValidated] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [contactValidated, setContactValidated] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    setSubmitButtonEnabled(nameValidated && emailValidated && contactValidated);
  }, [nameValidated, emailValidated, contactValidated]);

  const validateName = (name) => {
    if (!isEmpty(name)) {
      setNameValidated(true);
      return true;
    } else {
      setNameValidated(false);
      return false;
    }
  };

  const validateEmail = (email) => {
    if (emailValidator.validateEmail(email)) {
      setEmailValidated(true);
      return true;
    } else {
      setEmailValidated(false);
      return false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const countryCode = COUNTRY_LIST[countryValue].phone;
    const formattedContact = getContactWithCountryCode(contact, countryCode);
    const userData = {
      email,
      name,
      role: "onboarding_supplier",
      country: countryValue,
      contact: formattedContact,
      profilePic: getDefaultProfilePic(name),
    };
    openVerifyEmailPage(userData);
  };

  useEffect(() => {
    if (signupSuccess) {
      navigate("/verify-email");
      resetCreateUserStatus();
    }
  }, [signupSuccess]);

  const renderSignUpForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography className={classes.title}>Sign Up</Typography>
        <Typography className={classes.subtitle}>
          to start your manufacturing journey with us
        </Typography>
        <Typography className={classes.inputLabel}>Your Name & Title (e.g. Anne Tan, Head of Sales)</Typography>
        <TextField
          id="name"
          name="name"
          autoFocus
          className={classes.inputField}
          value={name}
          onChange={({target}) => {
            validateName(target.value);
            setName(target.value);
          }}
          variant="outlined"
          size="small"
          style={{width: "100%"}}
        />
        <Typography className={classes.inputLabel}>Your Company Email Address</Typography>
        <TextField
          id="email"
          name="email"
          className={classes.inputField}
          value={email}
          error={isEmailError}
          onChange={({target}) => {
            validateEmail(target.value.trim());
            setEmail(target.value.trim());
          }}
          onBlur={() => setIsEmailError(!emailValidated)}
          variant="outlined"
          size="small"
          style={{width: "100%"}}
        />
        <Typography className={classes.inputLabel}>Contact number</Typography>
        <PhoneNumberFields
          setCountryValue={setCountryValue}
          contact={contact}
          countryValue={countryValue}
          classes={classes}
          setContact={setContact}
          contactValidated={contactValidated}
          setContactValidated={setContactValidated}
        />
        <Button
          id="signup"
          type="submit"
          fullWidth
          variant="contained"
          className={classes.signup}
          disabled={!submitButtonEnabled}
        >
          Sign Up
        </Button>
        <div className={classes.conditions}>
          By selecting Sign Up, I agree to Factorem's&nbsp;
          <a href="https://app.factorem.co/terms" rel="noreferrer" target="_blank">
            Terms of Use
          </a>
          &nbsp;and&nbsp;
          <a href="https://app.factorem.co/privacy" rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
          .
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: colors.fontGrey,
            fontWeight: "bold",
          }}
        >
          <Divider style={{width: "47%"}}/>
          &nbsp;or&nbsp;
          <Divider style={{width: "47%"}}/>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 40,
            fontSize: "10pt",
          }}
        >
          <Button
            type="button"
            color="secondary"
            className={classes.goToLogin}
            component={Link}
            to="/login"
          >
            Login
          </Button>
        </div>
      </form>
    );
  };

  const renderAdditionalInfo = () => {
    return (
      <Grid container direction="column" spacing={1} className={classes.mobile}>
        <Description size={4} />
      </Grid>
    );
  };

  return (
    <Grid container className={classes.container}>
      {!isMobile ? (
        <Grid item sm={6}>
          <a href="https://www.factorem.co" target="_blank" rel="noreferrer">
            <img
              src={factoremLogoWhite}
              alt="icon"
              style={{
                height: "4rem",
                position: "absolute",
                top: "2rem",
                left: "4rem",
                zIndex: 2,
              }}
            />
          </a>
          <Slideshow />
        </Grid>
      ) : (
        <Grid item>
          <a href="https://www.factorem.co" target="_blank" rel="noreferrer">
            <img
              src={factoremLogoSmall}
              alt="icon"
              style={{
                height: "3rem",
                position: "absolute",
                top: "2rem",
                left: "2rem",
              }}
            />
          </a>
        </Grid>
      )}
      <Grid
        container
        sm={6}
        component="main"
        justifyContent="center"
        className={classes.form}
      >
        <div
          style={{
            padding: isMobile ? "7rem 5% 5rem 5%" : "0 22% 0 22%",
          }}
        >
          {isMobile ? (
            <Fragment>
              <Paper className={classes.paper}>{renderSignUpForm()}</Paper>
              {renderAdditionalInfo()}
            </Fragment>
          ) : (
            renderSignUpForm()
          )}
        </div>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    signupSuccess: state.createUser.signupSuccess,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openVerifyEmailPage: (userData) => dispatch(signupUser(userData, props)),
    resetCreateUserStatus: () => dispatch(resetStatus()),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(SignUp);

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import editProfile from './editProfile';
import item from './item';
import items from './items';
import myorders from './myorders';
import order from './order';
import orders from './orders';
import projectBulkQuotationsForm from './projectBulkQuotationsForm';
import users from './users';
import createUser from './createUser';
import fabrication from './fabrication';
import assessment from './assessment';
import orderReady from './orderReady';
import poAcknowledge from './poAcknowledge';

const authConfig = {
  key: 'auth',
  storage,
  blacklist: ['rates'],
};

export default combineReducers({
  auth: persistReducer(authConfig, auth),
  editProfile,
  item,
  items,
  myorders,
  order,
  orders,
  users,
  createUser,
  projectBulkQuotationsForm,
  fabrication,
  assessment,
  orderReady,
  poAcknowledge,
});

import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { FormControl, TextField } from '@mui/material';
import { Grid, Box, Container, Select, MenuItem, InputLabel } from '@mui/material';
import { useItemInputConfig } from "../../hooks/useItemInputConfig";

import TechnologyInputField from '../inputs/TechnologyInputField';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../inputs/ThreeDLayerThicknessField';
import UploadFileItem from '../cards/UploadFileItem';
import SolidBlueButton from '../buttons/SolidBlueButton';
import DownloadIconButton from "../buttons/DownloadIconButton";

import { is2DGenerationSupport } from '../../utils/fileUtils';
import { is3DPTechnology } from '../../utils/itemUtils';
import * as fileUtils from '../../utils/fileUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../utils/inputUtils';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_STR_TO_VAR_MAP,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  standardToleranceOptions,
  toleranceOptions,
  threeDToleranceOptions,
} from '../../constants/NewPartConstants';
import { 
  ppe3dpInfillOptions, 
  ppe3dpLayerThicknessOptions 
} from '../../constants/PPEConstants';
import {
  ALL_CAD_FILES_TYPES,
  ALL_TECHNICAL_DRAWING_FILES_TYPES,
} from '../../constants/predefinedItemConstants';
import {
  uploadFileToS3,
  getOnboardingCadFileS3Key,
  getOnboardingTechDrawingS3Key,
  getUploadedImageFileS3Key,
  uploadPublicFileToS3,
} from '../../services/s3Service';
import { notifySuccess, notifyError } from '../../services/notificationService';
import { convert2DImage } from '../../services/convert2DImageService'

import { editOnboardingItem } from '../../apis/onboardingApi';
import { getSingleOnboardingItem } from "../../actions";
import SingleImageWithUpload from '../images/SingleImageWithUpload';
import DownloadIcon from '@mui/icons-material/Download';

function EditItemForm(props) {
  const { id } = useParams();
  const { items, onPageLoad } = props;

  const navigate = useNavigate();

  useEffect(() => {
    onPageLoad(id);
  }, [id])

  const currItem = items.find(item => item.id === parseInt(id));

  // Current properties (retrieved from the database)
  const [name, setName] = useState(currItem.referenceName);
  const [quantity, setItemQuantity] = useState(currItem.quantity);
  const [technology, setTechnology] = useState(currItem.technology);
  const [material, setMaterial] = useState('');
  const [threeDTechnology, setThreeDTechnology] = useState(currItem.threeDTechnology);
  const [surfaceFinish, setSurfaceFinish] = useState(currItem.surfaceFinish);
  const [color, setColor] = useState(currItem.color); // surface finish color
  const [materialColor, setMaterialColor] = useState(''); // 3d printing
  const [threeDInfill, setThreeDInfill] = useState(currItem.threeDInfill);
  const [threeDLayerThickness, setThreeDLayerThickness] = useState(currItem.threeDLayerThickness);
  const [otherTechnology, setOtherTechnology] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [tolerance, setTolerance] = useState(currItem.tolerance);
  const [leadTime, setLeadTime] = useState(currItem.leadTime);
  const [qcReportTemplate, setQcReportTemplate] = useState(currItem.qcReportTemplate);
  const [description, setDescription] = useState(currItem.description);
  const [imageFile, setImageFile] = useState(currItem.imageFile);
  const [cadFile, setCadFile] = useState(currItem.cadFile);
  const [techDrawing, setTechDrawing] = useState(currItem.technicalDrawing);
  const [cadFileExtension, setCadFileExtension] = useState(currItem.cadFileExtension);
  const [cadFileImagePreview, setCadFileImagePreview] = useState(currItem.cadFileImagePreview);

  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

  const surfaceFinishIsPainting = (currItem.surfaceFinish === 'Painting');
  const surfaceFinishIsCoating = (currItem.surfaceFinish === 'Powder Coating');
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
      ? 'Powder Coat Color'
      : 'Color';

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setSurfaceFinishOptions,
    }
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const populateItemDetails = async (currItem) => {
    // Step 1: Set Technology
    const allTechOptions = await loadTechnologyOptions(false);

    if (!allTechOptions.includes(currItem.technology)) {
      setTechnology(TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY);
      setOtherTechnology(currItem.technology);
    } else {
      setTechnology(currItem.technology);
      setOtherTechnology(null);
    }

    loadSelectColorSurfaces({ 
      technology: currItem.technology, 
    });

    // Step 2: Load Material Category options or 3d Tech options
    let materialOptions = [];

    if (!is3DPTechnology(currItem.technology)) {
      loadMaterialCategoryOptions({ 
        technology: currItem.technology, 
      }, false).then((response) => {

        if (!isEmpty(response.options)) {
          let matchedMaterialOption = '';

          response.options.some(category => {
              return category.options.some(material => {
                  if (material === currItem.material) {
                      matchedMaterialOption = material;
                      return true; // Break the inner loop
                  }
                  return false; // Continue searching
              });
          });

          if (matchedMaterialOption === '') {
              setMaterial('Custom Material');
          } else {
              setMaterial(matchedMaterialOption);
          }
        } else {
          setMaterial('Custom Material');
        }
      });

    } else {
      await load3DTechnologyOptions(false);
      setThreeDTechnology(currItem.threeDTechnology);

      const { options: threeDPMaterials } = await loadThreeDMaterialOptions({
        technology: currItem.technology,
        threeDTechnology: currItem.threeDTechnology,
      }, false)

      materialOptions = threeDPMaterials;

      if (!isEmpty(materialOptions)) {
        let longestMaterialMatch = '';
  
        materialOptions.forEach((option) => {
          if (currItem.material && (currItem.material).includes(option) && option.length > longestMaterialMatch.length) {
            longestMaterialMatch = option;
          }
        });

        if (longestMaterialMatch === '') {
          longestMaterialMatch = 'Custom Material';
        } else {
          setMaterial(longestMaterialMatch);

          const indexOfMaterial = (currItem.material).indexOf(longestMaterialMatch);
          const materialColor = (currItem.material).substring(0, indexOfMaterial).trim();
          setMaterialColor(materialColor);

          currItem.material = longestMaterialMatch;
          currItem.materialColor = materialColor;
        }

        if (currItem.threeDTechnology === THREE_D_P_FDM_TECH) {
          setThreeDInfill(currItem.threeDInfill);
          setThreeDLayerThickness(currItem.threeDLayerThickness);
        } else {
          setThreeDInfill(null);
          setThreeDLayerThickness(null);
        }
      }
    }

    // Step 3: Load Surface Finish options and/or Material Color options
    const { options: allSurfaceOptions } = await loadSurfaceFinishOptions({
      technology: currItem.technology,
      threeDTechnology: currItem.threeDTechnology,
      material: currItem.material,
    }, false);

    if (isCustomSurfaceFinish(currItem.surfaceFinish) || (currItem.surfaceFinish && !(allSurfaceOptions || []).includes(currItem.surfaceFinish))) {
      if (currItem.surfaceFinish && isEmpty(allSurfaceOptions)) {
        setSurfaceFinishOptions(['Custom Finish']);
      }
      setSurfaceFinish('Custom Finish');
      setOtherSurfaceFinish(surfaceFinish); 

    } else {
      setSurfaceFinish(currItem.surfaceFinish);
    }

    if (currItem.material) {
      await loadMaterialColorOptions({
        technology: currItem.technology,
        threeDTechnology: currItem.threeDTechnology,
        material: currItem.material,
      }, false);
    }

    if (currItem.color) {
      await loadSurfaceFinishColorOptions({
        technology: currItem.technology,
        surfaceFinish: currItem.surfaceFinish,
      }, false);
      setColor(currItem.color);
    }

    //Step 4: Load preview image (if any)
    if (currItem.imageFile) {
      setCadFileImagePreview(currItem.imageFile);
    }

    if (currItem.cadFile) {
      setCadFile(currItem.cadFile);
    } else {
      setCadFile(null);
    }

    if (currItem.technicalDrawing) {
      setTechDrawing(currItem.technicalDrawing);
    } else {
      setTechDrawing(null);
    }
  };

  useEffect(() => {
    populateItemDetails(currItem);
  }, [currItem]);

  const renderTechnologyInputField = () => { 
    return (
      <TechnologyInputField
        value={technology}
        onChange={(newTech) => {
          setTechnology(newTech);
          technologyHasChanged(newTech);
          setMaterial('');
          if (newTech !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
            setThreeDTechnology(null);
            setThreeDInfill(null);
            setThreeDLayerThickness(null);
            setTolerance(toleranceOptions[newTech] || 0.1);
          } else if (newTech === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
            setThreeDInfill(threeDPrintingInfillDefault);
            setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
          }
        }}
        technologyOptions={technologyOptions}
        id="technology-upload-3d-files"
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderMaterialCategories = () => {
    return (
      <MaterialCategoriesInputField
        technology={technology}
        visible={technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING}
        value={material}
        onSelect={(value) => {
          setMaterial(value);
          const params = {
            technology,
            threeDTechnology,
            material: value,
          }
          materialHasChanged(params);
        }}
        materialCategoryOptions={materialCategoryOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderSurfaceFinishingField = () => {
    return (
      <SurfaceFinishingField
        visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish)}
        value={surfaceFinish}
        onSelect={(newValue) => {
          setSurfaceFinish(newValue);
          const params = {
            technology,
            material,
            surfaceFinish: newValue,
          }
          surfaceFinishHasChanged(params);
        }}
        surfaceFinishOptions={surfaceFinishOptions}
        selectColorSurfaces={selectColorSurfaces}
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions) || color}
        colorInputLabel={colorInputLabel}
        value={color}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={event => {
          setColor(event.target.value || color);
        }}
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions) || materialColor}
        colorInputLabel={'Material Color'}
        value={materialColor}
        colorPalette={materialColorOptions}
        onSubmit={(event) => {
          setMaterialColor(event.target.value || materialColor);
        }}
        required
      />
    );
  }

  const render3DPrintingTechnologySelect = () => {
    return (
      <ThreeDPrintingTechnologyInputField
        visible={technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING}
        value={threeDTechnology}
        onChange={(newValue) => {
          setThreeDTechnology(newValue);
          const params = {
            technology,
            threeDTechnology: newValue,
          }
          threeDTechnologyHasChanged(params);

          setTolerance(threeDToleranceOptions[newValue]);
          setMaterial('');

          if (newValue === THREE_D_P_FDM_TECH) {
            setThreeDInfill(threeDInfill || threeDPrintingInfillDefault);
            setThreeDLayerThickness(threeDLayerThickness || threeDPrintingLayerThicknessDefault);
          } else {
            setThreeDInfill('');
            setThreeDLayerThickness('');
          }
        }}
        threeDTechnologyOptions={threeDTechnologyOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        technology={technology}
        threeDTechnology={THREE_D_STR_TO_VAR_MAP[threeDTechnology]}
        visible={technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING}
        value={material}
        onSelect={(newMaterial) => {
          setMaterial(newMaterial);
          const params = {
            technology,
            threeDTechnology,
            material: newMaterial,
          }
          materialHasChanged(params);
        }}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DInfillSelect = () => {
    return (
      <ThreeDInfillSelectField
        visible={is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH}
        value={threeDInfill}
        onChange={(newValue) => {
          setThreeDInfill(newValue);
        }}
        ppe3dpInfillOptions={ppe3dpInfillOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const render3DLayerThicknessSelect = () => {
    return (
      <ThreeDLayerThicknessField
        visible={is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH}
        value={threeDLayerThickness}
        onChange={(newValue) => {
          setThreeDLayerThickness(newValue);
        }}
        ppe3dpInfillOptions={ppe3dpLayerThicknessOptions}
        bootstrapStyle={false}
        required
      />
    );
  }

  const renderNameField = () => {
    return (
      <TextField
        label="Name"
        id="name"
        onChange={(e) => {
          setName(e.target.value);
        }}
        value={name}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderQuantityField = () => {
    return (
      <TextField
        label="Quantity"
        id="quantity"
        type="number"
        onChange={(e) => {
          setItemQuantity(e.target.valueAsNumber);
        }}
        value={quantity}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderToleranceField = () => {
    return (
      <FormControl
        sx={{ marginTop: '8px' }}
        required
      >
        <InputLabel variant="outlined" htmlFor="tolerance">
          Tolerance
        </InputLabel>
        <Select
          label="Tolerance"
          id="tolerance"
          value={tolerance}
          onChange={(evt) => {
            setTolerance(evt.target.value);
          }}
        >
          {standardToleranceOptions.map((option) => (
            <MenuItem key={option} value={option}>
              +/- {option}mm
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const renderLeadTimeField = () => {
    return (
      <TextField
        label="Lead Time (days)"
        id="lead-time"
        type="number"
        inputProps={{ min: 0 }}
        onChange={(e) => {
          setLeadTime(e.target.valueAsNumber);
        }}
        value={leadTime}
        sx={{ marginTop: '8px' }}
        required
      />
    )
  }

  const renderQcReportTemplateField = () => {
    return (
      <TextField
        label="QC Report Template (Link)"
        id="qc-report-template"
        onChange={(e) => {
          if (e.target.value) {
            setQcReportTemplate(e.target.value);
          } else {
            setQcReportTemplate(null);
          }
        }}
        value={qcReportTemplate}
        sx={{ marginTop: '8px' }}
      />
    )
  }

  const renderDescriptionField = () => {
    return (
      <TextField
        label="Description"
        id="description"
        onChange={(e) => {
          if (e.target.value) {
            setDescription(e.target.value);
          } else {
            setDescription(null);
          }
        }}
        value={description}
        sx={{ marginTop: '8px' }}
        multiline
      />
    )
  }

  const handleCadFileChange = (file) => {
    setSaveButtonEnabled(false);
    return uploadFileToS3(file[0], getOnboardingCadFileS3Key(file[0]))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setCadFile(s3ObjectUrl);
        const fileExtension = file[0].name.split('.').pop();
        setCadFileExtension(fileExtension);
        notifySuccess('CAD File uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading CAD File!');
        console.error(err)
        setCadFile(null);
        setCadFileExtension(null);
      }).finally(() => {
        setSaveButtonEnabled(true);
      });
  }

  const renderCadFileUpload = () => {
    return (
      <UploadFileItem
        itemKey="cad-file"
        text="Upload CAD File"
        onFileUpload={(file) => handleCadFileChange(file)}
        uploadState={cadFile}
        accept={ALL_CAD_FILES_TYPES.map(fileType => `.${fileType}`).join(',')}
        style={{ marginTop: '20px', height: '80px', width: '250px' }}
      />
    )
  }

  const renderCadFileDownloadButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DownloadIconButton
          id={`download-item-files-${id}`}
          tooltip="Download CAD File"
          icon={DownloadIcon}
          onClick={() => fileUtils.downloadS3File(cadFile)}
        />
        <span style={{ marginLeft: '3px', fontSize: '14px', textDecoration: 'underline' }}>
          Download previously uploaded CAD file
        </span>
      </div>
    );
  };

  const renderTechDrawingUpload = () => {
    return (
      <UploadFileItem
        itemKey="tech-drawing"
        text="Upload Technical Drawing"
        onFileUpload={(file) => handleTechDrawingChange(file)}
        uploadState={techDrawing}
        accept={ALL_TECHNICAL_DRAWING_FILES_TYPES.map(fileType => `.${fileType}`).join(',')}
        style={{ marginTop: '20px', height: '80px', width: '250px' }}
      />
    )
  }

  const renderTechDrawingDownloadButton = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-3px' }}>
        <DownloadIconButton
          id={`download-item-files-${id}`}
          tooltip="Download Technical Drawing"
          icon={DownloadIcon}
          onClick={() => fileUtils.downloadS3File(techDrawing)}
        />
        <span style={{ marginLeft: '3px', fontSize: '14px', textDecoration: 'underline' }}>
          Download previously uploaded technical drawing
        </span>
      </div>
    );
  };
  

  const handleTechDrawingChange = (file) => {
    setSaveButtonEnabled(false);
    return uploadFileToS3(file[0], getOnboardingTechDrawingS3Key(file[0]))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setTechDrawing(s3ObjectUrl);
        notifySuccess('Technical Drawing uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading Technical Drawing!');
        console.error(err)
        setTechDrawing(null);
      }).finally(() => {
        setSaveButtonEnabled(true);
      });
  }

  const renderSaveButton = () => {
    return (
      <Box sx={{ margin: '16px 0' }} textAlign={{ "xs": "center", "md": "right" }}>
        <SolidBlueButton
          btnContent="Save"
          disabled={!saveButtonEnabled}
          onBtnClick={onClickSave}
        />
      </Box>
    )
  }

  useEffect(() => {
    if (!cadFile || !cadFileExtension || !is2DGenerationSupport(cadFileExtension)) {
      setCadFileImagePreview(null);
      return;
    }

    convert2DImage({ file_url: cadFile })
      .then((twoDImageUrl) => {
        setCadFileImagePreview(twoDImageUrl["s3_file_url"]);
        setImageFile(null); // Reset image file
      })
  }, [cadFile, cadFileExtension])

  const handleImageFileUpload = (imageFile) => {
    setSaveButtonEnabled(false);
    return uploadPublicFileToS3(imageFile, getUploadedImageFileS3Key(imageFile))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        setImageFile(s3ObjectUrl);
        notifySuccess('Image uploaded successfully!');
      }).catch((err) => {
        notifyError('Error uploading image!');
        console.error(err)
        setImageFile(null);
      }).finally(() => {
        setSaveButtonEnabled(true);
      });
  }

  const renderCadFileImagePreview = () => {
    return (
      <SingleImageWithUpload
        imageUrl={cadFileImagePreview}
        handleUpload={handleImageFileUpload}
      />
    )
  }

  const onClickSave = () => {
    // Validate the new inputs first
    if (!name && !currItem.referenceName) {
      notifyError('Missing name field.');
      return;
    }
    if (!quantity && !currItem.quantity) {
      notifyError('Missing quantity field.');
      return;
    }
    if (!technology && !currItem.technology) {
      notifyError('Missing technology field.');
      return;
    }
    if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
      notifyError('Missing Custom Technology field.');
      return;
    }
    if ((technology && !material) && !currItem.material) {
      notifyError('Missing material field.');
      return;
    }
    if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
      notifyError('Missing Custom Material field.');
      return;
    }
    if ((material && !surfaceFinish) && (!currItem.surfaceFinish)) {
      notifyError('Missing surface finish field.');
      return;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
      notifyError('Missing Custom Surface Finish field.');
      return;
    }
    if (!tolerance && !currItem.tolerance) {
      notifyError('Missing tolerance field.');
      return;
    }
    if (!leadTime && !currItem.leadTime) {
      notifyError('Missing lead time field.');
      return;
    }
    if ((!cadFile && !currItem.cadFile) && (!techDrawing && !currItem.techDrawing)) {
      notifyError('Missing CAD File/Technical Drawing.');
      return;
    }

    const updatedBody = {
      referenceName: name || currItem.referenceName,
      technology: technology || currItem.technology,
      threeDTechnology: threeDTechnology || currItem.threeDTechnology,
      threeDInfill: threeDInfill || currItem.threeDInfill,
      threeDLayerThickness: threeDLayerThickness || currItem.threeDLayerThickness,
      quantity: quantity || currItem.quantity,
      material: ((technology || currItem.technology) === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) 
        ? `${materialColor || currItem.materialColor} ${material || currItem.material}`
        : material || currItem.material,
      surfaceFinish: surfaceFinish || currItem.surfaceFinish,
      color: color || currItem.color,
      tolerance: tolerance || currItem.tolerance,
      leadTime: leadTime || currItem.leadTime,
      cadFile: cadFile || currItem.cadFile,
      technicalDrawing: techDrawing || currItem.techDrawing,
      qcReportTemplate: qcReportTemplate || currItem.qcReportTemplate,
      description: description || currItem.description,
      imageFile: (imageFile ?? cadFileImagePreview) || (currItem.imageFile ?? currItem.cadFileImagePreview),
    };

    editOnboardingItem(id, updatedBody).then(() => {
      notifySuccess("Successfully updated item!");
      navigate("/manage-items");
    })
    .catch((err) => {
      console.error(err);
      notifyError(err.message);
    });
  }

  return (
    <Container>
      <Grid container spacing={2} column={2} direction="row" justifyContent={{ xs: "center", md: "flex-start" }}>
        <Grid item md={4} xs={10}>
          <Grid container direction="column" alignItems="center">
            {renderCadFileImagePreview()}

            {renderCadFileUpload()}
            {cadFile && renderCadFileDownloadButton()}

            {renderTechDrawingUpload()}
            {techDrawing && renderTechDrawingDownloadButton()}
          </Grid>
        </Grid>
        <Grid item md={6} xs={10}>
          <Grid container direction="column" marginLeft={{ md: "50px" }}>
            {renderNameField()}
            {renderQuantityField()}

            {renderTechnologyInputField()}
            {isCustomTechnology(technology) && (
              <TextField
                label="Custom Technology"
                id="custom-technology"
                onChange={(e) => setOtherTechnology(e.target.value)}
              />
            )}
            {render3DPrintingTechnologySelect()}

            {renderMaterialCategories()}
            {render3DPrintingMaterialField()}
            {isCustomMaterial(material) && (
              <TextField
                label="Custom Material"
                id="custom-material"
                onChange={(e) => setOtherMaterial(e.target.value)}
                required
              />
            )}

            {renderMaterialColorInputField()}

            {render3DInfillSelect()}
            {render3DLayerThicknessSelect()}

            {renderSurfaceFinishingField()}
            {isCustomSurfaceFinish(surfaceFinish) && (
              <TextField
                label="Custom Finish"
                id="custom-finish"
                onChange={(e) => setOtherSurfaceFinish(e.target.value)}
                required
              />
            )}
            {renderSurfaceFinishColor()}
            {renderToleranceField()}
            {renderLeadTimeField()}

            {renderQcReportTemplateField()}

            {renderDescriptionField()}

            {renderSaveButton()}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

function mapStateToProps(state) {
    return {
      items: state.items.items,
    };
}

function matchDispatchToProps(dispatch, props) {
    return {
        onPageLoad: (itemID) => dispatch(getSingleOnboardingItem(itemID)),
    };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(EditItemForm);

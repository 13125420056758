import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advanced from "dayjs/plugin/advancedFormat"

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

const dateFns = require('date-fns');

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
export const FOURTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000;
export const ORDER_READY_HISTORY_DATE_FORMAT = 'DD MMM YYYY HH:mm';
export const DELIVERY_DATE_FORMAT = 'ddd, DD MMM YYYY';

export const getDefaultExpiredDate = () => new Date(Date.now() + FOURTEEN_DAYS_IN_MS);

export const getDaysElapsed = (datePosted) => {
  return Math.floor((Date.now() - Date.parse(datePosted)) / ONE_DAY_IN_MS);
}

/**
 * return a date string in the format eg: 2022-06-09 06:18:00
 */
export const getDateStr = (date) => {
  const dateObj = typeof date === 'string'
    ? new Date(date)
    : date;
  return dateFns.format(dateObj, 'yyyy-MM-dd HH:mm:ss.SS');
}

export const getCurrentDateStr = () => {
  return getDateStr(new Date());
}

export const getCurrentFullYearStr = () => {
  return new Date().getFullYear().toString();
}

export const getDateStrWithMonth = (date, monthType = 'short') => {
  const dateFormat = { year: 'numeric', month: monthType, day: 'numeric' };
  return new Date(date).toLocaleDateString('en-GB', dateFormat);
}

export const formatOrderReadyHistoryDate = (date) => {
  return dayjs(date).format(ORDER_READY_HISTORY_DATE_FORMAT)
}

export const compareDate = (dateStr1, dateStr2) => {
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);
  return date1.getTime() - date2.getTime();
}

export const formatDeliveryDate = (dateStr) => {
  return dayjs(dateStr).format(DELIVERY_DATE_FORMAT);
}

export const dateTzSingapore = (date, formatStr = 'YYYY-MM-DD HH:mm') => {
  return dayjs(date).tz('Singapore').format(formatStr);
}

import React from "react";
import { connect, useDispatch } from "react-redux";

import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Add as AddIcon } from "@mui/icons-material";

import * as formActions from '../../actions/projectBulkQuotationsForm';

// import { convertPriceWithQuantityToCurrency } from "../../utils/currencyUtils";
// import { getQuotationExchangeRate } from "../../utils/quotationUtils";

import { colors, FACTOREM_BLUE } from "../../palette";
import { get } from "lodash";
import RfqQuotationRowInput from "./RfqQuotationRowInput";
import {
  CMM_PRICE_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  REMARKS_STR,
  UNIT_QUOTE_STR,
} from "../../constants/quotationConstants";

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
  },
  containerTitle: {
    margin: 0,
  },
  addButton: {
    borderRadius: 8,
    backgroundColor: colors.menuItemHover,
    '&:hover': {
      backgroundColor: FACTOREM_BLUE,
      color: 'white',
    },
  },
}));

function ProjectItemQuotationForm(props) {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    currency,
    exchangeRate,
    item,
    formData,
    expanded,
  } = props;

  const { formError, qcReports } = formData;
  const {
    itemQuoteType,
    quotations: inputFields,
    remarks,
    technology,
  } = item;

  const onInputFieldsChange = (event, quotationID, fieldName) => {
    dispatch(formActions.updateQuoteFieldValue({
      itemID: item.itemID,
      quotationID,
      fieldName,
      value: event.target.value,
    }));
  }

  const onRemoveFields = (event, quotationID) => {
    dispatch(formActions.removeQuotation({
      itemID: item.itemID,
      quotationID,
    }));
  }

  const onRemarksChange = (event) => {
    dispatch(formActions.updateRemarksValue({
      itemID: item.itemID,
      value: event.target.value,
    }));
  }

  const renderRemarksInput = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: 4,
        }}
      >
        <TextField
          label="Remarks / Clarifications"
          placeholder="Please enter any remarks (type NIL for no remarks)"
          multiline={true}
          variant="outlined"
          minRows={1}
          maxRows={2}
          fullWidth
          name={REMARKS_STR}
          error={get(formError, `${item.itemID}.${REMARKS_STR}`)}
          helperText={get(formError, `${item.itemID}.${REMARKS_STR}`)}
          onChange={onRemarksChange}
          value={remarks}
          inputProps={{
            maxLength: 8000,
          }}
        />
      </div>
    );
  }

  const renderRfqQuotationInput = (inputField) => {
    return (
      <RfqQuotationRowInput
        inputField={inputField}
        currency={currency}
        technology={technology}
        qcReports={qcReports}
        showDeleteButton={inputFields.length > 1}
        onInputFieldsChange={onInputFieldsChange}
        quantityError={get(formError, `${item.itemID}.${inputField.id}.${QUANTITY_STR}`)}
        unitQuoteError={get(formError, `${item.itemID}.${inputField.id}.${UNIT_QUOTE_STR}`)}
        // otherMaterialError={get(formError, `${item.itemID}.${inputField.id}.${OTHER_MATERIAL_STR}`)}
        // otherSurfaceFinishError={get(formError, `${item.itemID}.${inputField.id}.${OTHER_SURFACE_FINISH_STR}`)}
        // cmmPriceError={get(formError, `${item.itemID}.${inputField.id}.${CMM_PRICE_STR}`)}
        onDeleteButtonClick={(event) => onRemoveFields(event, inputField.id)}
      />
    );
  }

  return (
    <Grid container fluid="true" spacing={3} direction="column">
      <Grid item className={classes.container}>
        <Grid item>
          {expanded && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                gutterBottom
                variant="body1"
                className={classes.containerTitle}
              >
                Enter quote:
              </Typography>
            </div>
          )}
          {!expanded && <Divider />}
          <div
            style={{
              marginTop: 4,
            }}
          >
            {inputFields.map((inputField, index) => (
              <div
                style={{
                  padding: '2px 0',
                }}
                key={`${inputField.id}`}
              >
                <div style={{ margin: '2px 0' }}>
                  {renderRfqQuotationInput(inputField)}
                </div>
                {inputFields.length > 1 && <Divider />}
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {expanded && renderRemarksInput()}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    formData: state.projectBulkQuotationsForm,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ProjectItemQuotationForm);

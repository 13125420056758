import { makeStyles } from 'tss-react/mui';
import { colors } from '../../../palette';

const actionButtonStyles = makeStyles(() => ({
    completedButton: {
        background: colors.greenGradient,
        color: colors.fontWhite,
        padding: '0.625rem 0.3rem',
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        textTransform: 'uppercase',
        textAlign: 'center',
        width: '6.5rem',
    },
    invoiceButton: {
        background: colors.redGradient,
        color: colors.fontWhite,
        padding: '0.625rem 0.9375rem',
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        width: '6.5rem',
    },
    issueDoButton: {
        background: colors.buttonColorBlueGradient,
        color: colors.fontWhite,
        padding: '0.625rem 0',
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        width: '6.5rem',
    },
    itemCollectedButton: {
        background: colors.orangeGradient,
        color: colors.fontWhite,
        padding: '0.625rem 0',
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        width: '6.5rem',
    },
    linkButton: {
        color: colors.blue050,
        textDecoration: 'underline',
        width: '6.5rem',
    },
    seeMoreButton: {
        textTransform: 'none',
        width: '6.5rem',
    },
    shipItemButton: {
        background: colors.purpleGradient,
        color: colors.fontWhite,
        padding: '0.625rem 0.9375rem',
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        width: '6.5rem',
    },
}));

export default actionButtonStyles;
import React, { useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import digitalSupplyChain from '../assets/img/slideshow-digital-supply-chain.png';
import orderInClicks from '../assets/img/slideshow-order-in-clicks.png';
import onTimeDelivery from '../assets/img/slideshow-on-time-delivery.png';
import noMOQ from '../assets/img/slideshow-no-moq.png';
import customParts from '../assets/img/slideshow-custom-parts.png';
import powerYourProduction from '../assets/img/slideshow-power-your-production.png';
import accessSEA from '../assets/img/slideshow-access-sea.png';

const fadeImages = [
  digitalSupplyChain,
  orderInClicks,
  onTimeDelivery,
  noMOQ,
  customParts,
  powerYourProduction,
  accessSEA,
];

function Slideshow() {
    // to hide the ResizeObserver loop limit exceeded overlay
    useEffect(() => {
      window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop limit exceeded') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
        }
    });
  }, []);

  return (
    <Fade arrows={false} pauseOnHover={false} duration={2500}>
      {fadeImages.map((fadeImage, index) => (
        <div key={index}>
          <img style={{ maxWidth: '100%', height: 'auto', zIndex: 2 }} src={fadeImage} alt={`${fadeImage}`} />
        </div>
      ))}
    </Fade> 
  );
};

export default Slideshow;
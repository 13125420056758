import React from 'react';

import StatusDisplayAdmin from '../../components/StatusDisplayAdmin';
import StatusDisplay from '../../components/StatusDisplay';

import {
  ORDER_READY_STATUS_CHIPS,
} from '../../constants/orderReadyConstants';

function OrderReadyStatusDisplay(props) {
  const { status, onClick, onAcceptedOrderPage = false, isAdminOrHigher = false} = props;
  if (!status) {
    return null
  }
  const chipDetails = ORDER_READY_STATUS_CHIPS.find(chip => chip.status === status)

  return (
    isAdminOrHigher
      ? <div
        onClick={(event) => {
          event.stopPropagation();
        }} >
        <StatusDisplayAdmin
          chipDetails={chipDetails}
          handleAdminEditStatus={onClick}
        />
      </div>
      :
      <div onClick={onClick}>
        <StatusDisplay
          label={chipDetails.config.statusStr}
          bgColor={chipDetails.config.bgColor}
          textColor={chipDetails.config.textColor}
          toolTip={chipDetails.config.toolTip}
          icon={chipDetails.config.icon}
          onAcceptedOrderPage={onAcceptedOrderPage}
        />
      </div>
  );
}

export default OrderReadyStatusDisplay;

import React from 'react';

import { Tooltip } from '@mui/material';

import {
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';

import { colors } from '../../palette';

function InfoIcon(props) {
  const { toolTipText } = props;
  return (
    <Tooltip
      title={toolTipText}
      placement='top'
      arrow
    >
      <InfoOutlinedIcon
        style={{
          fontSize: '1.1rem',
          color: colors.solidBlue,
        }}
      />
    </Tooltip>
  );
}

export default InfoIcon;

// Import settings
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clsx from "clsx";

import { makeStyles } from 'tss-react/mui';

import {
  AddCircleOutlineOutlined,
  ExitToAppOutlined,
  ContactSupportOutlined,
  HelpOutline,
  LibraryBooksOutlined,
  PeopleAlt,
  Plumbing,
  MonetizationOn,
} from '@mui/icons-material';

import { Badge, Tooltip } from '@mui/material';

import { logoutUser } from '../actions';
import { getSubmittedProjectOrderReadyCountAction } from "../actions/orderReady";

import ProfileMenuItem from './ProfileMenuItem';
import ResponsiveSidebar from './ResponsiveSidebar';

import { FACTOREM_BLUE, colors } from '../palette';

const drawerWidth = 250;

// Style component
const useStyles = makeStyles()({
  top: {
    marginBottom: 20,
    '&:hover': {
      background: colors.bgLightGrey,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  middle: {
    margin: '0 0px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 20,
  },
  countrySelector: {
    margin: '0px 0px',
    padding: '0px 30px'
  },
  edit: {},
  icon: {
    display: 'flex',
    marginRight: 15,
  },
  navlinks: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: drawerWidth,
  },
  listText: {
    textTransform: 'capitalize',
    fontSize: '15px',
    letterSpacing: '0.05em',
  },
  listTextLink: {
    color: colors.fontLightGrey,
    display: 'flex',
    textDecoration: 'none',
    textTransform: 'capitalize',
    fontSize: '15px',
    letterSpacing: '0.05em',
    margin: '9px 30px',
  },
  link: {
    textDecoration: 'none',
    margin: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 30px',
  },
  sidebarTitle: {
    color: colors.tertiaryBlue,
    textTransform: 'uppercase',
    fontWeight: '600',
    margin: '9px 30px',
    letterSpacing: '0.08em',
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  sidebarMenu: {
    textDecoration: 'none',
    margin: '0px 0px',
    display: 'flex',
    padding: '9px 30px',
    backgroundColor: FACTOREM_BLUE,
    color: 'white',
    '&:hover': {
      backgroundColor: FACTOREM_BLUE,
      color: 'white',
    },
    overflow: 'wrap'
  },
  sidebarTransparent: {
    textDecoration: 'none',
    margin: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    padding: '9px 30px',
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    fontSize: '15px',
    letterSpacing: '0.05em',
    color: colors.fontLightGrey,
    '&:hover': {
      backgroundColor: FACTOREM_BLUE,
      color: 'white',
    },
  },
});

/**
 * This is Admin sidebar component
 * @param {*} props
 * @returns
 */
export function AdminSidebar(props) {
  // Define styles to use in function
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Define props to be used in component
  const {
    onLogOut,
    name,
    profilePic,
    submittedOrderReadyCount,
  } = props;

  let location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(location.pathname);

  useEffect(() => {
    dispatch(getSubmittedProjectOrderReadyCountAction());
  }, []);

  // Add tabs for buyer
  const renderListMenuItems = () => (
    <div className={classes.navlinks}>
      <div className={classes.sidebarTitle}>General</div>
      <Link
        to='/'
        className={clsx(classes.sidebarTransparent, selectedMenu === '/' && classes.sidebarMenu)}
        onClick={() => setSelectedMenu('/')}
      >
        <div className={classes.icon}>
          <PeopleAlt color={selectedMenu === '/' ? 'red' : 'blue'} />
        </div>
        <div className={classes.listText}>
          Manage Suppliers
        </div>
      </Link>
      <Link
        to='/manage-items'
        className={clsx(classes.sidebarTransparent, selectedMenu === '/manage-items' && classes.sidebarMenu)}
        onClick={() => setSelectedMenu('/manage-items')}
      >
        <div className={classes.icon}>
          <AddCircleOutlineOutlined />
        </div>
        <div className={classes.listText}>
          Manage Items
        </div>
      </Link>
      <Link
        to='/order-ready-management'
        className={clsx(classes.sidebarTransparent, selectedMenu === '/order-ready-management' && classes.sidebarMenu)}
        onClick={() => setSelectedMenu('/order-ready-management')}
      >
        <div className={classes.icon}>
          <MonetizationOn />
        </div>
        <Tooltip
          title={submittedOrderReadyCount > 0
            ? `${submittedOrderReadyCount} Order ${submittedOrderReadyCount > 1 ? 'Readies' : 'Ready'} to be reviewed`
            : ''}
        >
          <Badge badgeContent={submittedOrderReadyCount} color="error" style={{ maxWidth: '7.5rem' }}>
            <div className={classes.listText}>
              Order Ready Management
            </div>
          </Badge>
        </Tooltip>
      </Link>
      <Link
        to='/browse-parts'
        className={clsx(classes.sidebarTransparent, selectedMenu === '/browse-parts' && classes.sidebarMenu)}
        onClick={() => setSelectedMenu('/browse-parts')}
      >
        <div className={classes.icon}>
          <Plumbing />
        </div>
        <div className={classes.listText}>Browse Parts</div>
      </Link>
      <div className={classes.sidebarTitle} style={{ marginTop: 50 }}>
        Support
      </div>
      <a
        href='https://factorem.co/partner'
        target='_blank'
        rel="noreferrer"
        className={classes.sidebarTransparent}
      >
        <div className={classes.icon}>
          <HelpOutline />
        </div>
        <div className={classes.listText}>About</div>
      </a>
      <Link
        to='/supplier-faq'
        className={clsx(classes.sidebarTransparent, selectedMenu === '/supplier-faq' && classes.sidebarMenu)}
      >
        <div className={classes.icon}>
          <ContactSupportOutlined />
        </div>
        <div className={classes.listText}>FAQ</div>
      </Link>
      <Link
        className={classes.sidebarTransparent}
        to="https://app.factorem.co/terms"
        target="_blank"
        rel="noreferrer"
      >
        <div className={classes.icon}>
          <LibraryBooksOutlined />
        </div>
        <div className={classes.listText}>Terms of use</div>
      </Link>
    </div>
  );

  return (
    <ResponsiveSidebar drawerWidth={drawerWidth} Content={props.children}>
      <div>
        <div className={classes.top}>
          <ProfileMenuItem name={name} profilePic={profilePic} />
        </div>
        <div>{renderListMenuItems()}</div>
      </div>
      <div className={classes.bottom}>
        <Link
          data-cy='logout-btn'
          onClick={onLogOut}
          className={classes.sidebarTransparent}
        >
          <div className={classes.icon}>
            <ExitToAppOutlined />
          </div>
          <div className={classes.listText} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Logout
          </div>
        </Link>
      </div>
    </ResponsiveSidebar>
  );
}

// Import states to use in component
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    name: state.editProfile.name
      ? state.editProfile.name
      : state.auth.user.name,
    profilePic: state.editProfile.profilePic
      ? state.editProfile.profilePic
      : state.auth.user.profilePic,
    submittedOrderReadyCount: state.orderReady.submittedCount,
  };
}

// Import functions to use in component
function matchDispatchToProps(dispatch) {
  return {
    onLogOut: () => dispatch(logoutUser()),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(AdminSidebar);

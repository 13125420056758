import React, { useReducer, useRef } from 'react';
import { capitalize, isEmpty } from 'lodash';

import { makeStyles } from "tss-react/mui";

import {
  Flip,
  Publish,
  Visibility,
  Warning,
} from '@mui/icons-material';

import {
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import SingleImage from '../../components/images/SingleImage';

import { IMG_PLACE_HOLDER_URL } from '../../constants';

import { colors } from '../../palette';

const useStyles = makeStyles()((theme, _params, classes) => ({
  actionButton: {
    backgroundColor: '#ffffff50',
    '&:hover': {
      backgroundColor: colors.lightGray,
      cursor: 'pointer',
    },
  },
  uploadImageText: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '0.875rem',
    width: '100%',
    zIndex: 999999,
  },
  containerPlaceholder: {
    '& img': {
      filter: 'brightness(0.8)'
    },
    [`& .${classes.uploadImageText}`]: {
      display: 'block',
    },
  },
  container: {
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)'
      },
      [`& .${classes.uploadImageText}`]: {
        display: 'block',
      },
    }
  },
  uploadNotAllowed: {
    '&:hover': {
      '& img': {
        filter: 'none'
      },
      [`& .${classes.uploadImageText}`]: {
        display: 'none',
      },
    }
  },
}));

const FLIP_BUTTON_TOOLTIPS = [
  'Flip to view sample image',
  'Flip to view actual image',
]

function ImageUpload(props) {
  const { classes } = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const uploadRef = useRef();

  const {
    type = 'title',
    id = 'upload-image',
    onUpload = () => { },
    imageURL,
    onViewClick = () => { },
    showAlarm = false,
    placeHolderUrl,
    uploadAllowed = true
  } = props;
  const hasImgUploaded = imageURL !== placeHolderUrl
    && !isEmpty(imageURL)
    && imageURL !== IMG_PLACE_HOLDER_URL
    && !imageURL.includes('2dImgFiles')
    ;

  const showFlipButton = imageURL !== placeHolderUrl
    && !isEmpty(imageURL)
    && !isEmpty(placeHolderUrl)
    ;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      frontImageUrl: imageURL,
      backImageUrl: placeHolderUrl,
      flipButtonTooltipIndex: 0,
    },
  );

  const handleUploadFiles = (files) => {
    onUpload(files[0]);
  }

  const handleFlipImage = () => {
    if (!isEmpty(localState.backImageUrl)) {
      const frontImageUrl = localState.backImageUrl;
      const backImageUrl = localState.frontImageUrl;
      const flipButtonTooltipIndex = (localState.flipButtonTooltipIndex + 1) % 2;
      updateLocalState({
        frontImageUrl,
        backImageUrl,
        flipButtonTooltipIndex,
      });
    }
  }

  // eslint-disable-next-line no-unused-vars
  const renderUploadFileButton = () => {
    return (
      <div>
        <input
          id={id}
          type="file"
          accept="image/*"
          multiple={false}
          onChange={evt => {
            evt.stopPropagation();
            handleUploadFiles(evt.target.files);
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.target.value = null;
          }}
          style={{
            display: "none"
          }}
        />
        <label
          className={classes.actionButton}
          htmlFor={id}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Publish color='primary' />
        </label>
      </div>
    )
  }

  const renderViewImageButton = () => {
    return (
      <Tooltip title='View image'>
        <div>
          <label
            className={classes.actionButton}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onViewClick();
            }}
          >
            <Visibility color='primary' />
          </label>
        </div>
      </Tooltip>
    );
  }

  const renderFlipImageButton = () => {
    return (
      <Tooltip title={FLIP_BUTTON_TOOLTIPS[localState.flipButtonTooltipIndex]}>
        <div>
          <label
            className={classes.actionButton}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleFlipImage();
            }}
          >
            <Flip color='primary' />
          </label>
        </div>
      </Tooltip>
    );
  }

  const renderActionButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showFlipButton === true && renderFlipImageButton()}
        {renderViewImageButton()}
      </div>
    );
  }

  return (
    <div
      id={id}
      className={!uploadAllowed
        ? classes.uploadNotAllowed
        : hasImgUploaded
          ? classes.container
          : classes.containerPlaceholder
      }
      style={{
        position: 'relative',
      }}
      onClick={() => {
        uploadRef.current.click();
      }}
    >
      {uploadAllowed && <input
        ref={uploadRef}
        id={id}
        type="file"
        accept="image/*"
        multiple={false}
        onChange={evt => {
          evt.stopPropagation();
          handleUploadFiles(evt.target.files);
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.target.value = null;
        }}
        style={{
          display: "none"
        }}
      />}
      <SingleImage
        url={localState.frontImageUrl || IMG_PLACE_HOLDER_URL}
        width={isMobile ? 150 : 150}
        height={isMobile ? 150 : 150}
        noBorder
      />
      <div
        className={classes.uploadImageText}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Publish />
          <Typography>
            Upload Image
          </Typography>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          backgroundColor: '#00000050',
          color: 'white',
          width: '100%',
          padding: '0.2rem',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {capitalize(type.replace(/_/g, ' '))}
        {renderActionButtons()}
      </div>
      {showAlarm === true && (
        <Tooltip title="Please upload image">
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              padding: 2,
              boxSizing: 'border-box',
            }}
          >
            <Warning style={{ color: 'orange' }} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default ImageUpload;

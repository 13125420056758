import React from "react";
import { connect } from "react-redux";

import { makeStyles } from 'tss-react/mui';

import BrowseProjectDisplay from "./BrowseProjectDisplay";

const useStyles = makeStyles()(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
  browseHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export function BrowseWithOptions(props) {
  const { classes } = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.browseHeader}>
        <h1>Partner Job Board</h1>
      </div>
      <BrowseProjectDisplay />
    </div>
  );
}

function mapStateToProps(state) {
  return {
  };
}

function matchDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(BrowseWithOptions);

/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Typography } from '@mui/material';

function ItalicNote({ text = 'Note', color = 'black', variant = 'caption' }) {
  return (
    <Box
      style={{
        color: color,
      }}
    >
      <Typography
        style={{
          fontStyle: 'italic',
        }}
        variant={variant}
        display='block'
        color='inherit'
      >
        {text}
      </Typography>
    </Box>
  );
}

export default ItalicNote;

import { BACKEND_SERVICE_URL } from "../constants";
import { getAuthorizedHeader } from "../utils/apiUtils";

export const getAllSuppliersGroupByTech = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/suppliers/group-by-tech`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: suppliers } = await response.json();
    return suppliers;
  });
}

export const getUserInfo = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getUserPreConfirmationInfo = async (code) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/signup-preconfirmation-info/${code}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
  .then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getUnAcknowledgeQuotationsCount = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/quotations/un-acknowledged-count`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data: { count } } = await response.json();
    return count;
  });
}

export const convertUserToSupplier = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/convert-to-supplier`;
  return fetch(requestUrl, {
    method: "POST",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

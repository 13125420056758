import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, FACTOREM_BLUE } from '../../palette';

const GreenStyleButton = styled(Button)({
  root: {
    backgroundColor: colors.greenTagColor,
    color: 'white',
    borderRadius: 0,
    padding: '16px 20px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: FACTOREM_BLUE,
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
  },
});

function GreenButton(props) {
  const { text = 'Button', onClick, disabled, id, tooltipTitle } = props;
  return (
    tooltipTitle? 
    <Tooltip title={tooltipTitle}>
      <GreenStyleButton
        data-cy={id}
        fullWidth
        onClick={onClick}
        disabled={disabled}
      >
          {text}
      </GreenStyleButton>
    </Tooltip> :
    <GreenStyleButton
    data-cy={id}
    fullWidth
    onClick={onClick}
    disabled={disabled}
    >
      {text}
    </GreenStyleButton>
  );
}

export default GreenButton;

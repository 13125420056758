import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";

export function CustomToolbar(props) {
  const { children } = props;

  return (
    <GridToolbarContainer 
      sx={{
        display: 'flex', 
        justifyContent: 'space-between' 
      }}
    >
      <div>
        { children }
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </div>
      <div>
        <GridToolbarQuickFilter debounceMs={500}/>
      </div>
    </GridToolbarContainer>
  );
}

// Import settings
import React from "react";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
  Button
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";

// Style components
const useStyles = makeStyles()(() => ({
  submitBtn: {
    textTransform: 'uppercase',
    background: colors.fontWhite,
    border: `solid 1px ${colors.inputBorderBlue}`,
    borderRadius: '5px',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    color: colors.titleBlue,
    padding: '10px 15px',
    textAlign: 'center'
  },
  fullWidth: {
    width: '100%',
  },
}));

function WhiteButton(props) {
  const { classes } = useStyles();
  const {
    btnContent,
    onBtnClick,
    component,
    to,
    fullWidth = false,
  } = props;
  return (
    <Button
      className={clsx(classes.submitBtn, fullWidth ? classes.fullWidth : null)}
      onClick={onBtnClick}
      variant='contained'
      component={component ? component : 'span'}
      to={to ? to : null}
    >
      {btnContent}
    </Button>
  )
}

export default WhiteButton;

import { isEmpty } from "lodash";

export const getQcReportsList = (qcReports) => {
  if (isEmpty(qcReports)) {
    return [];
  }
  let qcReportList = [];
  if (!isEmpty(qcReports.main)) {
    qcReportList.push(qcReports.main);
  }
  if (!isEmpty(qcReports.addOns)) {
    qcReportList = [...qcReportList, ...qcReports.addOns];
  }
  if (!isEmpty(qcReports.others)) {
    qcReportList = [...qcReportList, ...qcReports.others];
  }
  return qcReportList;
}

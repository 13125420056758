import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
} from "@mui/material";

import { Edit as EditIcon } from "@mui/icons-material";

const resultToColourConverter = (res) => {
  switch (res) {
    case "Fail":
      return "#fb4b4b";
    case "Pending":
      return "#F5F5F5";
    case "Pass":
      return "#c0ff33";
    default:
      return "gray";
  }
};

const TestPartResultsTable = (props) => {
  const { data, onClickEditIcon } = props;

  return (
    <TableContainer sx={{ maxHeight: "60%" }}>
      <Table sx={{ maxHeight: "60%" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", minWidth: "6vw" }}>
              Action
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "7vw" }}>
              Part ID
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "11vw" }}>
              Part Name
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "9vw" }}>
              Result
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "9vw" }}>
              Assessment Report File
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "9vw" }}>
              No Assessment Report Needed
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", minWidth: "12vw" }}>
              Feedback
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow key={row.itemID}>
                <TableCell>
                  <IconButton onClick={() => onClickEditIcon(row.itemID)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                {Object.entries(row).map(([key, value]) => {
                  if (key === "partnershipAgreement") {
                    return null; // skip rendering of PA
                  }

                  if (key === "noNeedAssessmentReport") {
                    return (
                      <TableCell key={key}>
                        {value ? "True" : "False"}
                      </TableCell>
                    );
                  }

                  return key === "hasItemPassQC" ? (
                    <TableCell key={key}>
                      <Chip
                        label={value}
                        style={{
                          backgroundColor: resultToColourConverter(value),
                        }}
                      />
                    </TableCell>
                  ) : key === "assessmentReport" ? (
                    <TableCell key={key}>
                      {value === null ? "Not uploaded" : "Uploaded"}
                    </TableCell>
                  ) : (
                    <TableCell key={key}>{value}</TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TestPartResultsTable;

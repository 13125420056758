import React from 'react';

import { Typography } from '@mui/material';

function ErrorLabel(props) {
  const { message = 'error' } = props;

  return (
    <Typography
      variant="caption"
      color="error"
    >
      <i>{message}</i>
    </Typography>
  );
}

export default ErrorLabel;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbar,
} from "@mui/x-data-grid";

import { Box, Tooltip, Button } from "@mui/material";
import { isEmpty } from "lodash";

import { makeStyles } from "tss-react/mui";

import {
  Assessment as AssessmentIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Hardware as HardwareIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import StepChip from "../components/StepChip";
import PageTitle from "../components/PageTitle";

import {
  deleteUser,
  editOnboardingUserData,
  getAllOnboardingUsers,
} from "../actions";

import { renderFlag } from "../components/images/CountryFlag";
import { SUPPORTED_CURRENCIES } from '../constants/currencyConstants';
import { dateTzSingapore, getDateStrWithMonth } from "../utils/dateTimeUtils";
import { isEmptyValue } from '../utils/commonUtils';
import { ONBOARDING_STEPS } from "../constants/userConstants";
import { CustomEditComponent } from "../components/CustomEditComponent";
import { TECHNOLOGIES_ARR } from "../constants/technologies";
import { COUNTRIES, COUNTRY_LIST } from "../constants/countryConstants";
import YesNoPopup from '../components/popups/YesNoPopup';
import { convertUserToSupplier } from "../apis/userApi";
import DataGridWrapTextCell from "../components/tables/cells/DataGridWrapTextCell";

const useStyles = makeStyles()(theme => ({
  body: {
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
  }
}));

export function ManageSuppliers(props) {
  const { classes } = useStyles();

  const { users, editUser, deleteUser, onPageLoad } = props;

  const [rowModesModel, setRowModesModel] = useState({});
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showConvertPopup, setShowConvertPopup] = useState(false);
  const [convertID, setConvertID] = useState(null);

  useEffect(() => {
    onPageLoad();
  }, []);

  function handleEditClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  }

  function handleSaveClick(id) {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }

  function handleDeleteClick(id) {
    setShowDeletePopup(true);
    setDeleteID(id);
  }

  function handleDeletePopupClose() {
    setShowDeletePopup(false);
    setDeleteID(null);
  }

  function handleCancelClick(id) {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  }

  async function handleProcessRowUpdate(newRow, oldRow) {
    await editUser(newRow);
    return newRow;
  }

  const navigate = useNavigate();

  // const handlePartClickRedirect = (userId) => {
  //   // todo should redirect to supplier assigned test part
  //   // using the userId
  //   return navigate("/browse-part");
  // };

  const handleRowClickRedirectProgress = (user) => {
    return navigate(`/supplier-progress/${user.userID}`, {
      state: { user: user },
    });
  };

  const handleConvertToSupplier = (supplierID) => {
    setShowConvertPopup(true);
    setConvertID(supplierID);
  }

  const handleConvertPopupClose = () => {
    setShowConvertPopup(false);
    setConvertID(null);
  }

  const renderVerificationLink = (verificationDate, verificationCode) => {
    if (verificationDate || isEmptyValue(verificationCode)) {
      return 'N.A.';
    }
    const domain = window.location.origin;
    const url = `${domain}/email-confirmation/${verificationCode}`;

    return url;
  }

  const renderContactNumber = (contact, country) => {
    if (contact) {
      const currCountryCode = COUNTRY_LIST[country].phone;

      // e.g. for contacts with (+65)90123456 OR for contacts with +6090123456 but the country has been changed to Singapore
      if (contact.startsWith(`(`) || ((contact.startsWith('+') && !contact.startsWith(currCountryCode)))) {
        return contact;

      } else if (contact.startsWith(currCountryCode)) { // e.g. for contacts with +6590123456
        const remainingContact = contact.slice(currCountryCode.length);
        return `(${currCountryCode}) ${remainingContact}`;

      } else {  // e.g. for contact with 90123456
        return `(${currCountryCode}) ${contact}`;
      }
    }
  }

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 150,
      cellClassName: "actions",
      getActions: (params) => {
        const { id } = params;
        const machiningCapabilities = params.row.machiningCapabilities;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={() => handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip title="View supplier progress" arrow>
            <GridActionsCellItem
              icon={<AssessmentIcon />}
              label="View Supplier Progress"
              onClick={() => handleRowClickRedirectProgress(params.row)}
              color="inherit"
              disabled={isEmpty(machiningCapabilities)}
            />
          </Tooltip>,
          <Tooltip title="View assigned part" arrow>
            <GridActionsCellItem
              icon={<HardwareIcon />}
              label="View Part"
              // onClick={() => handlePartClickRedirect(id)}
              color="inherit"
              disabled={isEmpty(machiningCapabilities)}
            />
          </Tooltip>,
          <Tooltip title="Edit supplier details" arrow>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleEditClick(id)}
              color="inherit"
              disabled={isEmpty(machiningCapabilities)}
            />
          </Tooltip>,
          <Tooltip title="Delete supplier" arrow>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
    { field: "userID", headerName: "User ID", align: "center" },
    {
      field: "companyName",
      headerName: "Company",
      editable: true,
      width: 150
    },
    {
      field: "step",
      headerName: "Onboarding Step",
      type: "singleSelect",
      valueOptions: ONBOARDING_STEPS,
      editable: true,
      width: 140,
      renderCell: (params) => <StepChip step={params.value} />,
    },
    {
      field: "machiningCapabilities",
      headerName: "Machining Capabilities",
      editable: true,
      width: 170,
      valueOptions: TECHNOLOGIES_ARR,
      renderEditCell: CustomEditComponent,
      valueFormatter: ({ value }) => (value ? value : ""),
      filterable: false,
      // filter not implemented yet
    },
    {
      field: "qualifications",
      headerName: "Qualifications",
      editable: true,
      width: 125,
    },
    {
      field: "name",
      headerName: "POC Name",
      editable: true,
      width: 150
    },
    {
      field: "email",
      headerName: "Email",
      editable: true,
      width: 225,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.email} />,
    },
    {
      field: "contact",
      headerName: "Contact",
      editable: true,
      width: 150,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={renderContactNumber(rowData.contact, rowData.country)} />,
    },
    {
      field: "secondaryName",
      headerName: "Secondary POC Name",
      editable: true,
      width: 150,
    },
    {
      field: "secondaryEmail",
      headerName: "Secondary POC Email",
      editable: true,
      width: 225,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.secondaryEmail} />,
    },
    {
      field: "secondaryContact",
      headerName: "Secondary POC Contact",
      editable: true,
      width: 150,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={renderContactNumber(rowData.secondaryContact, rowData.country)} />,
    },
    {
      field: "verificationLink",
      headerName: "Verification Link",
      editable: true,
      width: 300,
      valueGetter: ({ row: rowData }) => {
        return renderVerificationLink(rowData.verificationDate, rowData.verificationCode);
      },
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell text={renderVerificationLink(rowData.verificationDate, rowData.verificationCode)} />
        );
      },
    },
    {
      field: "country",
      headerName: "Country of Registration",
      type: "singleSelect",
      valueOptions: COUNTRIES,
      editable: true,
      width: 125,
      renderCell: (params) => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          {renderFlag(params.value)}&nbsp;
          {params.value}
        </Box>
      ),
    },
    {
      field: "companyRegistrationNumber",
      headerName: "Company Registration Number",
      editable: true,
      width: 150,
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.companyRegistrationNumber} />,
    },
    {
      field: "companyYearEstablished",
      headerName: "Year of Establishment",
      editable: true,
      width: 150,
      renderCell: ({ row: rowData }) => {
        const year = dateTzSingapore(new Date(rowData.companyYearEstablished), 'YYYY');
        return <DataGridWrapTextCell text={year} />
      },
    },
    {
      field: "question",
      headerName: "How did you hear about us?",
      width: 200,
      renderCell: ({ row: rowData }) => {
        let answer = rowData.qAndA?.answer;
        const { details } = rowData.qAndA || {};
        if (details) {
          answer = `${answer}: ${details}`;
        }
        return <DataGridWrapTextCell text={answer} />
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      type: "singleSelect",
      valueOptions: SUPPORTED_CURRENCIES,
      editable: true,
      width: 125,
      renderCell: (params) => (
        <DataGridWrapTextCell text={params.value} />
      ),
    },
    {
      field: "address",
      headerName: "Address",
      editable: true,
      width: 175
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 125,
      valueGetter: ({ value }) => value && getDateStrWithMonth(value),
    },
    {
      field: "endDate",
      headerName: "Completion Date",
      width: 125,
      valueGetter: ({ value }) => value && getDateStrWithMonth(value),
    },
    {
      field: "convertToSupplier",
      type: "actions",
      headerName: "Convert to Supplier",
      width: 150,
      cellClassName: "actions",
      getActions: (params) => {
        const { id } = params;
        return [
          <Tooltip title="Convert user role from onboarding_supplier to supplier" arrow>
            <Button
              variant="outlined"
              onClick={() => handleConvertToSupplier(id)}
            >
              Convert
            </Button>
          </Tooltip>
        ];
      }
    },
  ];

  return (
    <div className={classes.body}>
      <PageTitle title={"Manage Suppliers"} />
      <div style={{
        width: "80vw",
        overflow: "scroll",
        marginLeft: "15px",
        marginRight: "15px",
      }}>
        <DataGrid
          autoHeight
          rows={users}
          columns={columns}
          editMode="row"
          getRowHeight={() => "auto"}
          pageSizeOptions={[10, 20, 50]}
          rowModesModel={rowModesModel}
          getRowId={(row) => row.userID}
          slots={{ toolbar: GridToolbar }}
          processRowUpdate={handleProcessRowUpdate}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          sx={{
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "8px",
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableRowSelectionOnClick
          disableDensitySelector
        />
      </div>
      <YesNoPopup
        open={showDeletePopup}
        handleNo={handleDeletePopupClose}
        handleYes={() => deleteUser(deleteID)}
        body={`Supplier's company: ${users.find(user => user.userID === deleteID)?.companyName}`}
        title="Are you sure you want to delete this supplier?"
      />
      <YesNoPopup
        open={showConvertPopup}
        handleNo={handleConvertPopupClose}
        handleYes={() => convertUserToSupplier({ userID: convertID })}
        body={`Supplier's company: ${users.find(user => user.userID === convertID)?.companyName}`}
        title="Are you sure you want to convert this user to role supplier?"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: () => dispatch(getAllOnboardingUsers()),
    editUser: (newData) => dispatch(editOnboardingUserData(newData)),
    deleteUser: (userToDelete) => dispatch(deleteUser(userToDelete)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ManageSuppliers);

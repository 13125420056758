import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useQuery } from "react-query";
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';


import ProjectItemGridContainer from '../components/grids/ProjectItemGridContainer';

import {
  getReadyForQuoteItemsGroupByProject,
  getSupplierOnboardingProjects,
} from '../apis/itemApi';
import { /* getAllItems, */ getAllMyOrders } from '../actions';

import {
  CircularProgress,
  InputAdornment,
  TextField,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  Search as SearchIcon,
} from '@mui/icons-material'
import { red } from '@mui/material/colors';

const useStyles = makeStyles()((theme) => ({
  browseOption: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    color: "inherit",
  },
}));

function BrowseProjectDisplay(props) {
  const { classes } = useStyles();

  const {
    user,
    myorders,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  // const { status: loadingStatus, data: projects } = useQuery("projectItems", getReadyForQuoteItemsGroupByProject);
  const { status: loadingStatus, data: projects } = useQuery("projectItems", getSupplierOnboardingProjects);

  useEffect(() => {
    if (loadingStatus !== "success") {
      return;
    }
    let newFilteredProjects = projects;
    if (!isEmpty(searchKeyword)) {
      newFilteredProjects = projects.filter(project => {
        const searchFields = [
          `${project.projectID}`,
          ...project.items.flatMap(item => [`${item.itemID}`, item.name, item.referenceName]),
        ];
        return searchFields.some(item => item.toLowerCase().includes(searchKeyword.toLowerCase()));
      });
    }
    setFilteredProjects(newFilteredProjects);
  }, [projects, searchKeyword]);

  const renderLoading = () => {
    return (
      <CircularProgress
        style={{
          width: '1.2rem',
          height: '1.2rem',
        }}
        color='primary'
      />
    );
  }

  const renderData = () => {
    return (
      <div>
        <div className={classes.browseOption}>
          <TextField
            fullWidth={isMobile}
            data-cy="search-project-item"
            variant="outlined"
            className={classes.searchBar}
            input="text"
            id="searchBar"
            placeholder="Search"
            onChange={(e) => setSearchKeyword(e.target.value)}
            value={searchKeyword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        {isEmpty(filteredProjects) && (
          <Alert severity="info">              
              No jobs available right now. Factorem will notify you as soon as more opportunities become available.
              <br />
              <br />
              In the meantime, you may check the status of your&nbsp;
              <Link to="/orders" className={classes.link}>Quotes</Link> 
              &nbsp;and&nbsp;
              <Link to="/accepted-orders" className={classes.link}>Accepted Orders.</Link>
            ‌</Alert>
        )}
        {!isEmpty(filteredProjects) && (
          <ProjectItemGridContainer
            projects={filteredProjects}
            supplierOrders={myorders}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {loadingStatus === 'loading' && renderLoading()}
      {loadingStatus === 'success' && renderData()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    myorders: state.myorders.myorders,
    user: state.auth.user,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      // dispatch(getAllItems());
      dispatch(getAllMyOrders());
    },
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(BrowseProjectDisplay);

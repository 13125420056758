import {
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    RESET_STATUS,
  } from "../actions/createUser";
  
  const defaultState = {
    signupSuccess: false,
    signupFailure: false,
    verifyEmailSuccess: false,
    verifyEmailFailure: false,
    resendEmailSuccess: false,
    resendEmailFailure: false,
    email: "",
    name: "",
    country: "",
  };
  
  export default (state = defaultState, action) => {
    switch (action.type) {
      case SIGNUP_FAILURE:
        return {
          ...state,
          signupSuccess: false,
          signupFailure: true
        };
      case SIGNUP_SUCCESS:
        return {
          ...state,
          signupSuccess: true,
          signupFailure: false,
          email: action.email,
          name: action.name,
        };
      case VERIFY_EMAIL_SUCCESS:
        return {
          ...state,
          verifyEmailSuccess: true,
          verifyEmailFailure: false,
          country: action.country,
        };
      case VERIFY_EMAIL_FAILURE:
        return {
          ...state,
          verifyEmailSuccess: false,
          verifyEmailFailure: true
        };
      case RESEND_EMAIL_SUCCESS:
        return {
          ...state,
          resendEmailSuccess: true,
          resendEmailFailure: false
        };
      case RESEND_EMAIL_FAILURE:
        return {
          ...state,
          resendEmailSuccess: false,
          resendEmailFailure: true
        };
      case RESET_STATUS:
        return {
          ...defaultState,
          email: state.email,
          name: state.name,
          country: state.country,
        }
      default:
        return state;
    }
  };
  
import React, { useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";

import { MenuItem, Select, TextField, Typography } from "@mui/material";

import { colors } from "../../palette";

const useStyles = makeStyles()(() => ({
  selectDropdown: {
    "& .MuiSelect-select": {
      whiteSpace: "break-spaces",
    },
  },
  menuItemRoot: {
    whiteSpace: "break-spaces",
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.fontBlack,
    width: "100%",
  },
}));

function QuestionAnswerDropdown(props) {
  const { classes } = useStyles();

  const {
    id = "question-answer-dropdown",
    question = "",
    answerOptions = [],
    updateAnswer,
  } = props;

  const [answer, setAnswer] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState("");

  const handleUpdateAnswer = () => {
    updateAnswer({
      question,
      answer: answer.answer || "",
      details: details || "",
    });
  };

  useEffect(() => {
    setShowDetails(answer && answer.details);
  }, [answer]);

  useEffect(() => {
    handleUpdateAnswer();
  }, [answer, details]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography className={classes.inputLabel}>{question}</Typography>
      <Select
        className={classes.selectDropdown}
        id={id}
        variant="outlined"
        value={answer}
        onChange={(event) => setAnswer(event.target.value)}
        margin="dense"
        fullWidth
      >
        {answerOptions.map((item) => {
          return (
            <MenuItem
              key={item.answer}
              classes={{
                root: classes.menuItemRoot,
              }}
              value={item}
            >
              {item.answer}
            </MenuItem>
          );
        })}
      </Select>
      {showDetails && (
        <TextField
          label="Details"
          id={`${id}-details`}
          variant="outlined"
          value={details}
          onChange={(event) => setDetails(event.target.value)}
          margin="dense"
          fullWidth
        />
      )}
    </div>
  );
}

export default QuestionAnswerDropdown;

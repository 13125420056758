import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  verifyEmail,
  resendEmail,
  resetStatus,
  signupCompleteUser,
} from "../actions/createUser";
import { loginUser } from "../actions/auth";

import {
  Box,
  Button,
  Container,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";

import { colors, FACTOREM_BLUE } from "../palette";

import { validateEmail } from "../utils/emailValidator";
import { isEmpty } from "lodash";
import { AccountSignUpForm } from "../components/forms/AccountSignUpForm";
import Background from "../components/backdrops/Background";

const useStyles = makeStyles()((theme) => ({
  title: {
    marginTop: 10,
    fontSize: "24pt",
    fontWeight: "bold",
    letterSpacing: "-1pt",
  },
  subtitle: {
    fontSize: "14pt",
    textAlign: "center",
  },
  body: {
    fontWeight: 400,
    display: "flex",
    fontSize: "14pt",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 0 1.25rem 0",
    color: colors.fontGrey,
  },
  button: {
    width: "100%",
    display: "flex",
    fontSize: "10pt",
    flexDirection: "column",
  },
  container: {
    height: "100%",
    display: "grid",
    alignContent: "center",
    justifyItems: "center",
  },
  successIcon: {
    paddingTop: 0,
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    color: colors.successGreen,
  },
  mainButton: {
    width: "80%",
    fontWeight: 600,
    fontSize: "14pt",
    marginBottom: "5px",
    marginTop: "1.875rem",
    textTransform: "none",
    color: colors.fontWhite,
    borderRadius: "0.375rem",
    backgroundColor: colors.buttonBlue,
    "&:hover": {
      backgroundColor: FACTOREM_BLUE,
    },
  },
  inputLabel: {
    width: "80%",
    fontWeight: 600,
    fontSize: "14pt",
    paddingTop: "5px",
    textAlign: "left",
    color: colors.fontBlack,
  },
  errorLoginButton: {
    fontWeight: 600,
    fontSize: "12pt",
    marginTop: "5px",
    color: colors.bgGrey,
    textTransform: "none",
    "&:hover": {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
  paper: {
    display: "flex",
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "column",
    padding: "1.875rem 1.5rem",
    boxShadow: "0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)",
  },
}));

export function EmailConfirmation(props) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    match,
    isVerified,
    userCountry,
    userEmail: cacheEmail,
    userName,
    onPageLoad,
    resendEmail,
    resendEmailSuccess,
    resetStatus,
    loginUser,
    signupCompleteUser,
  } = props;

  const [userEmail, setUserEmail] = useState(cacheEmail);
  const [emailHelperText, setEmailHelperText] = useState(null);
  const confirmationCode = location.pathname.split("/email-confirmation/")[1];
  // used for testing, todo comment before commit
  // const confirmationCode =
  // "$2b$10$EsEgcHj57OscGHgrYxEkZulaVPH9W2TpytegwMRtHTF3VHJpfLiGC";

  useEffect(() => {
    resetStatus();
    onPageLoad(confirmationCode);
  }, []);

  useEffect(() => {
    if (isEmpty(userEmail)) {
      return;
    }
    const isEmailValid = userEmail && validateEmail(userEmail);
    const helperText = isEmailValid
      ? null
      : isEmpty(userEmail)
      ? "Email is required"
      : "Email is invalid";
    setEmailHelperText(helperText);
  }, [userEmail]);

  const handleResendEmail = () => {
    resendEmail(userEmail, userName);
  };

  const completeSignup = async (userData) => {
    await signupCompleteUser(userData);
  };

  const renderEmailVerifiedSuccess = () => {
    return (
      <AccountSignUpForm
        defaultCountry={userCountry}
        completeSignup={completeSignup}
        verificationCode={confirmationCode}
      />
    );
  };

  const renderEmailResentSuccess = () => {
    return (
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.body}>
          <div>
            Verification code is sent to{" "}
            <span style={{ color: FACTOREM_BLUE, fontWeight: "bold" }}>
              {userEmail}
            </span>{" "}
            successfully. Please open your email and click the verification
            link.
          </div>
        </div>
        <Button
          type="button"
          color="secondary"
          className={classes.errorLoginButton}
          component={Link}
          to="/login"
        >
          Back to Login
        </Button>
      </Paper>
    );
  };

  const renderResendEmailForm = () => {
    return (
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.body}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className={classes.title}>Verification Failed</div>
            <div className={classes.subtitle}>
              Your verification link may have expired. <br />
              Please enter your email and we'll send you a <br />
              new link to verify your account.
            </div>
          </div>
          <Box style={{ height: "2rem" }}></Box>
          <InputLabel className={classes.inputLabel}>Email</InputLabel>
          <TextField
            id="email"
            variant="outlined"
            margin="dense"
            name="email"
            value={userEmail}
            InputLabelProps={{ shrink: true }}
            error={!!emailHelperText}
            helperText={emailHelperText}
            onChange={(event) => setUserEmail(event.target.value)}
            style={{ width: "80%" }}
          />
          <Button
            type="button"
            color="secondary"
            className={classes.mainButton}
            onClick={handleResendEmail}
            disabled={!!emailHelperText}
          >
            Verify My Account
          </Button>
          <Button
            type="button"
            color="secondary"
            className={classes.errorLoginButton}
            component={Link}
            to="/login"
          >
            Back to Sign In
          </Button>
        </div>
      </Paper>
    );
  };

  return (
    <Container component="main" className={classes.container} maxWidth="sm">
      <Background />
      {isVerified
        ? renderEmailVerifiedSuccess()
        : resendEmailSuccess
        ? renderEmailResentSuccess()
        : renderResendEmailForm()}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isVerified: state.createUser.verifyEmailSuccess,
    userEmail: state.createUser.email,
    userName: state.createUser.name,
    userCountry: state.createUser.country,
    resendEmailSuccess: state.createUser.resendEmailSuccess,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: (confirmationCode) => dispatch(verifyEmail(confirmationCode)),
    resendEmail: (userEmail, userName) =>
      dispatch(resendEmail(userEmail, userName)),
    resetStatus: () => dispatch(resetStatus()),
    loginUser: (email, password) => dispatch(loginUser(email, password, props)),
    signupCompleteUser: (userData) =>
      dispatch(signupCompleteUser(userData, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(EmailConfirmation);

export const HOW_YOU_HEAR_ANSWERS_MAPPING = [
  {
    answer: 'Google',
    details: false,
  },
  {
    answer: 'Referred by a friend or colleague',
    details: true,
  },
  {
    answer: 'Startup Ecosystem (Incubators, Startup program)',
    details: true,
  },
  {
    answer: 'Educational Institution (Universities, Polytechnics, ITE, etc.)',
    details: true,
  },
  {
    answer: 'Social media (Linkedin, Facebook, etc.)',
    details: true,
  },
  {
    answer: 'Others',
    details: true,
  },
];

import { Cookies } from 'react-cookie';
// import queryString from 'query-string';

// import { getAllItems } from './items';
// import formulas from '../formulas/formulas';
// import { ROLE_TYPES, TWO_D_IMAGE_URLS_KEY } from '../constants';
// import { getDateStr, getCurrentDateStr } from '../utils/dateTimeUtils';
// import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
// import { FEATURE_FLAG_HUBSPOT } from '../constants/featureFlagConstants';
// import { isCustomTechnology } from '../utils/inputUtils';
// import { isAdminOrHigherRole } from '../utils/roleUtils';
import { notifyError, notifySuccess } from '../services/notificationService';

const cookies = new Cookies();

export const GET_ITEM_LOADING = 'GET_ITEM_LOADING';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_FAILURE = 'GET_ITEM_FAILURE';
export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_LOADING = 'CREATE_QUOTATION_LOADING';
export const CREATE_QUOTATION_FAILURE = 'CREATE_QUOTATION_FAILURE';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILURE = 'EDIT_ITEM_FAILURE';
export const EDIT_ITEM_LOADING = 'EDIT_ITEM_LOADING';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const DELETE_ITEM_LOADING = 'DELETE_ITEM_LOADING';
export const SAVE_ORDERED_AGAIN_PARTS = 'SAVE_ORDERED_AGAIN_PARTS';
export const DELETE_ORDERED_AGAIN_PARTS = 'DELETE_ORDERED_AGAIN_PARTS';
export const SAVE_PART_SUMMARY_DETAILS = 'SAVE_PART_SUMMARY_DETAILS';
export const UPDATE_2D_IMAGE_URL = 'UPDATE_2D_IMAGE_URL';
export const UPDATE_UPLOAD_PROJECT_FILES_STEPS = 'UPDATE_UPLOAD_PROJECT_FILES_STEPS';

// const savePartSummaryDetails = (formDataAvailable) => {
//   return {
//     type: SAVE_PART_SUMMARY_DETAILS,
//     formDataAvailable,
//   };
// };

// const getItem = () => {
//   return {
//     type: GET_ITEM_LOADING,
//   };
// };

// const getItemSuccess = (item) => {
//   return {
//     type: GET_ITEM_SUCCESS,
//     item,
//   };
// };

// const getItemFailure = () => {
//   return {
//     type: GET_ITEM_FAILURE,
//   };
// };

// export const getItemDetails = (id) => async (dispatch) => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/${id}`;
//   dispatch(getItem());
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//       Authorization: `Bearer ${cookies.get('token')}`,
//     },
//   }).then(function (response) {
//     response.json().then((item) => {
//       dispatch(getItemSuccess(item.data[0]));
//     });
//   }).catch(function () {
//     dispatch(getItemFailure());
//   });
// };

// export const getItemDetailsForSupplier = (id, userID) => async (dispatch) => {
//   const params = {
//     forSupplier: true,
//     supplierID: userID,
//   }
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/${id}?${queryString.stringify(params)}`;
//   dispatch(getItem());
//   return fetch(requestUrl, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//       Authorization: `Bearer ${cookies.get('token')}`,
//     },
//   }).then(function (response) {
//     response.json().then((item) => {
//       dispatch(getItemSuccess(item.data[0]));
//     });
//   }).catch(function () {
//     dispatch(getItemFailure());
//   });
// };

// const createQuotationFailure = () => {
//   toast.error(
//     'Unable to submit quotation. Please check your quotation again.',
//     {
//       position: toast.POSITION.TOP_RIGHT,
//     }
//   );
//   return {
//     type: CREATE_QUOTATION_FAILURE,
//   };
// };

// const createQuotationSuccess = (props, isAdmin = false) => {
//   if (!isAdmin) {
//     props.history.push('/orders');
//   }
//   toast.success('Quotation(s) successfully submitted!', {
//     position: toast.POSITION.TOP_RIGHT,
//   });
//   return {
//     type: CREATE_QUOTATION_SUCCESS,
//   };
// };

// const createQuotationLoading = () => {
//   return {
//     type: CREATE_QUOTATION_LOADING,
//   };
// };

// const editPartSuccess = () => {
//   toast.success('Part successfully edited!', {
//     position: toast.POSITION.TOP_RIGHT,
//   });
//   return {
//     type: EDIT_ITEM_SUCCESS,
//   };
// };

// const editPartLoading = () => {
//   return {
//     type: EDIT_ITEM_LOADING,
//   };
// };

// const editPartFailure = () => {
//   toast.error('Unable to edit part. Please try again later.', {
//     position: toast.POSITION.TOP_RIGHT,
//   });
//   return {
//     type: EDIT_ITEM_FAILURE,
//   };
// };

const deleteItemSuccess = () => {
  notifySuccess('Item deleted!');
  return {
    type: DELETE_ITEM_SUCCESS,
  };
};

const deleteItemLoading = () => {
  return {
    type: DELETE_ITEM_LOADING,
  };
};

const deleteItemFailure = () => {
  notifyError('Unable to delete item. Please try again later.');
  return {
    type: DELETE_ITEM_FAILURE,
  };
};

// //Function which calls endpoint to edit item details
// const editItem = (itemID, itemInformationToUpdate, isAdmin) => (dispatch) => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/edit/${itemID}`;
//   fetch(requestUrl, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${cookies.get('token')}`,
//     },
//     body: JSON.stringify(itemInformationToUpdate),
//   })
//     .then(function (response) {
//       dispatch(editPartLoading());
//       response.json().then((res) => {
//         if (res.success) {
//           if (isAdmin) {
//             dispatch(getAllItems());
//             dispatch(getItemDetails(itemID));
//           }
//           dispatch(editPartSuccess());
//         } else {
//           dispatch(editPartFailure());
//         }
//       });
//     })
//     .catch(function () {
//       dispatch(editPartFailure());
//     });
// };

export const deletePart = (deleteItemID, props) => (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/delete/${deleteItemID}`;
  fetch(requestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
  })
    .then(function (response) {
      dispatch(deleteItemLoading());
      response.json().then((res) => {
        if (res.success) {
          dispatch(deleteItemSuccess(props));
        } else {
          dispatch(deleteItemFailure());
        }
      });
    })
    .catch(function () {
      dispatch(deleteItemFailure());
    });
};

//Buyer edit part
// export const customerEditItem = (editedPart) => (dispatch, getState) => {
//   const itemID = editedPart.itemID;
//   let joinStrings = '';

//   const arrayOfOriginalFiles = editedPart.cadPart;
//   if (Array.isArray(arrayOfOriginalFiles.cadPart)) {
//     joinStrings = arrayOfOriginalFiles.cadPart.join();
//   } else {
//     joinStrings = arrayOfOriginalFiles;
//   }

//   let toleranceSubmit = parseFloat(0);
//   if (editedPart.tolerance) {
//     toleranceSubmit = parseFloat(editedPart.tolerance);
//   }

//   const itemInformationToUpdate = {
//     name: editedPart.name,
//     status: editedPart.status,
//     technology: editedPart.technology,
//     datePosted: editedPart.datePosted.slice(0, 19).replace('T', ' '),
//     quantity: editedPart.qty,
//     description: editedPart.description,
//     surfaceFinish: editedPart.surfaceFinish,
//     deliveryPreference: editedPart.deliveryPref,
//     partApplication: editedPart.partApplication,
//     tolerance: toleranceSubmit,
//     originalFiles: joinStrings,
//     material: editedPart.material,
//     userID:
//       getState().auth.user.role === 'superadmin' ||
//         getState().auth.user.role === 'admin' ||
//         getState().auth.user.role === 'reviewer'
//         ? editedPart.editUserID
//         : getState().auth.user.userID,
//     price: editedPart.price,
//     color: editedPart.color,
//     materialColor: editedPart.materialColor,
//   };
//   if (editedPart.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
//     itemInformationToUpdate.threeDTechnology = editedPart.threeDTechnology;
//     itemInformationToUpdate.threeDInfill = editedPart.threeDInfill;
//     itemInformationToUpdate.threeDLayerThickness = editedPart.threeDLayerThickness;
//   }
//   dispatch(editItem(itemID, itemInformationToUpdate, false));
// };

// //Admin edit part
// export const adminEditItem = (editedPart) => (dispatch, getState) => {
//   const itemID = editedPart.itemID
//     ? editedPart.itemID
//     : getState().item.item.itemID;
//   let joinStringsOfFactoremCad = '';
//   let joinStringsOfCustomerCad = '';
//   const arrayOfUpdatedCadFiles = editedPart.editCadFile;
//   if (Array.isArray(arrayOfUpdatedCadFiles.cadPart)) {
//     joinStringsOfFactoremCad = arrayOfUpdatedCadFiles.cadPart.join();
//   } else {
//     joinStringsOfFactoremCad = arrayOfUpdatedCadFiles;
//   }

//   const arrayOfOriginalCadFiles = editedPart.editOriginalFiles;
//   if (Array.isArray(arrayOfOriginalCadFiles.cadPart)) {
//     joinStringsOfCustomerCad = arrayOfOriginalCadFiles.cadPart.join();
//   } else {
//     joinStringsOfCustomerCad = arrayOfOriginalCadFiles;
//   }

//   let toleranceSubmit = parseFloat(0);
//   if (editedPart.editTolerance) {
//     toleranceSubmit = parseFloat(editedPart.editTolerance);
//   }

//   let priceSubmit = editedPart.editPrice;
//   if (
//     !editedPart.editPrice ||
//     editedPart.editPrice === 0 ||
//     editedPart.editPrice === '0'
//   ) {
//     priceSubmit = null;
//   }

//   const technology = !isCustomTechnology(editedPart.editTech)
//     ? editedPart.editTech
//     : editedPart.otherTechnology;

//   const itemInformationToUpdate = {
//     ...editedPart,
//     name: editedPart.editName,
//     referenceName: editedPart.editReferenceName,
//     status: editedPart.editStatus,
//     paymentStatus: editedPart.editPaymentStatus,
//     technology,
//     datePosted: editedPart.editDatePosted.slice(0, 19).replace('T', ' '),
//     quantity: editedPart.editQuantity,
//     deadline: editedPart.editDeadline
//       ? getDateStr(editedPart.editDeadline)
//       : '',
//     description: editedPart.editDescription,
//     deliveryPreference: editedPart.editDeliveryPreference,
//     partApplication: editedPart.editPartApplication,
//     tolerance: toleranceSubmit,
//     cadFile: joinStringsOfFactoremCad,
//     originalFiles: joinStringsOfCustomerCad,
//     imageFile: editedPart.editImageFile,
//     expectedPrice: editedPart.editExpectedPrice,
//     userID:
//       getState().auth.user.role === 'superadmin' ||
//         getState().auth.user.role === 'admin' ||
//         getState().auth.user.role === 'reviewer'
//         ? editedPart.editUserID
//         : getState().auth.user.userID,
//     price: priceSubmit,
//     ppeMarkdown: editedPart.editPpeMarkdown,
//     verifiedDate: editedPart.verifiedDate || '',
//     deliveryDate: editedPart.editDeliveryDate
//       ? getDateStr(editedPart.editDeliveryDate)
//       : '',
//     collectionDate: editedPart.editCollectionDate
//       ? getDateStr(editedPart.editCollectionDate)
//       : '',
//     actualDeliveryDate: editedPart.editActualDeliveryDate
//       ? getDateStr(editedPart.editActualDeliveryDate)
//       : '',
//     actualCollectionDate: editedPart.editActualCollectionDate
//       ? getDateStr(editedPart.editActualCollectionDate)
//       : '',
//     actualDeliveryCost: editedPart.editActualDeliveryCost,
//   };
//   if (FEATURE_FLAG_HUBSPOT === 'true') {
//     itemInformationToUpdate.hubspotDealID = editedPart.hubspotDealID;
//   }
//   dispatch(editItem(itemID, itemInformationToUpdate, true));
// };

// export const openPartSummaryPage = (formDataAvailable, props) => (dispatch) => {
//   dispatch(savePartSummaryDetails(formDataAvailable));
//   props.history.push('/summary', { from: '/' });
// };

// const extractedPartDetails = (partDetails, index = 0) => {
//   const {
//     description: remarks,
//     originalFiles,
//     twoDImageUrl,
//   } = partDetails;
//   const extractedPartDetails = {
//     ...partDetails,
//     id: `${index}`,
//     status: 1,
//     // qty,
//     cadPart: originalFiles.split(','),
//     remarks,
//     checked: false,
//     deleted: false,
//     [TWO_D_IMAGE_URLS_KEY]: [twoDImageUrl]
//   };
//   return extractedPartDetails;
// }

// export const savePartDetailsAndAddNew = (oldPartDetails, props) => (
//   dispatch
// ) => {
//   dispatch(savePartSummaryDetails([extractedPartDetails(oldPartDetails)]));
//   props.history.push('/summary', { from: '/' });
// };

// export const saveMultiplePartDetails = (oldPartDetailsList, props) => (
//   dispatch
// ) => {
//   const partDetailsArray = [];
//   for (const [index, oldPartDetails] of Object.entries(oldPartDetailsList)) {
//     partDetailsArray.push(extractedPartDetails(oldPartDetails, index));
//   }
//   dispatch(savePartSummaryDetails(partDetailsArray));
//   props.history.push('/summary', { from: '/' });
// };

// export const sendNewQuote = (quoteObject, props) => (dispatch, getState) => {
//   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotation/create`;
//   const { remarks, currency, exchangeRate, rfqQuote } = quoteObject;

//   // Remove unit quote from input field objects in array
//   let receivedQuotes = quoteObject.inputFields;
//   const priceBidded = receivedQuotes.map((element) => ({
//     quantity: element.quantity,
//     quote: element.quote,
//     material: element.material,
//     otherMaterial: element.otherMaterial,
//     surfaceFinish: element.surfaceFinish,
//     otherSurfaceFinish: element.otherSurfaceFinish,
//     materialColor: element.materialColor,
//     color: element.color,
//     leadTime: element.leadTime,
//     totalPrice: formulas.calculateTotalPrice(
//       element.quote,
//       10,
//       7,
//       0,
//       currency,
//       exchangeRate,
//     ), //markup, gst and deliveryFee
//     threeDTechnology: element.threeDTechnology,
//     threeDInfill: element.threeDInfill,
//     threeDLayerThickness: element.threeDLayerThickness,
//     cmmPrice: element.cmmPrice
//   }));

//   const dateOfOrder = getCurrentDateStr();
//   const dateOfExpiry = getDateStr(quoteObject.dateOfExpiry
//     ? quoteObject.dateOfExpiry
//     : new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)
//   );
//   const status = 'verifying';
//   const name = getState().item.item.referenceName;
//   const userRole = getState().auth.user.role;
//   const userID = [ROLE_TYPES.ADMIN, ROLE_TYPES.REVIEWER, ROLE_TYPES.SUPER_ADMIN].includes(userRole)
//     ? quoteObject.userID
//     : getState().auth.user.userID;
//   const itemID = getState().item.item.itemID;

//   const quotation = {
//     name,
//     status,
//     dateOfOrder,
//     dateOfExpiry,
//     priceBidded,
//     userID,
//     itemID,
//     remarks,
//     currency,
//     exchangeRate,
//     rfqQuote,
//   };

//   const isAdmin = isAdminOrHigherRole(userRole);

//   return fetch(requestUrl, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${cookies.get('token')}`,
//     },
//     body: JSON.stringify(quotation),
//   }).then(function (response) {
//     dispatch(createQuotationLoading());
//     if (response.ok) {
//       dispatch(createQuotationSuccess(props, isAdmin));
//     } else {
//       throw new Error('Creating quotation failed');
//     }
//   }).catch(function (err) {
//     dispatch(createQuotationFailure());
//     throw err;
//   });
// };

// export const update2DImageUrl = ({ originalS3Key, twoDImageUrl }) => {
//   return {
//     type: UPDATE_2D_IMAGE_URL,
//     payload: {
//       originalS3Key,
//       twoDImageUrl,
//     },
//   };
// };

// export const uploadProjectFilesSteps = (payload) => {
//   return {
//     type: UPDATE_UPLOAD_PROJECT_FILES_STEPS,
//     payload,
//   };
// }

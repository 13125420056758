import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from '../../palette';

const BootstrapInputSmall = styled(InputBase)((theme) => ({
  input: {
    minWidth: '200px',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    padding: '3px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: colors.inputBorderBlue,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default BootstrapInputSmall;

import React from 'react';

function DataGridWrapTextCell({ text }) {
  return (
    <div
      style={{
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        lineHeight: 'normal',
      }}
    >
      {text}
    </div>
  );
}

export default DataGridWrapTextCell;

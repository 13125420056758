export const COUNTRY_NAMES = {
  SINGAPORE: "Singapore", 
  AFGHANISTAN: "Afghanistan", 
  ALBANIA: "Albania", 
  ALGERIA: "Algeria", 
  ALLAND_ISLANDS: "Alland Islands", 
  AMERICA: "America", 
  AMERICAN_SAMOA: "American Samoa", 
  ANDORRA: "Andorra", 
  ANGOLA: "Angola", 
  ANGUILLA: "Anguilla", 
  ANTARCTICA: "Antarctica", 
  ANTIGUA_AND_BARBUDA: "Antigua and Barbuda", 
  ARGENTINA: "Argentina", 
  ARMENIA: "Armenia", 
  ARUBA: "Aruba", 
  AUSTRALIA: "Australia", 
  AUSTRIA: "Austria", 
  AZERBAIJAN: "Azerbaijan", 
  BAHAMAS: "Bahamas", 
  BAHRAIN: "Bahrain", 
  BANGLADESH: "Bangladesh", 
  BARBADOS: "Barbados", 
  BELARUS: "Belarus", 
  BELGIUM: "Belgium", 
  BELIZE: "Belize", 
  BENIN: "Benin", 
  BERMUDA: "Bermuda", 
  BHUTAN: "Bhutan", 
  BOLIVIA: "Bolivia", 
  BOSNIA_AND_HERZEGOVINA: "Bosnia and Herzegovina", 
  BOTSWANA: "Botswana", 
  BOUVET_ISLAND: "Bouvet Island", 
  BRAZIL: "Brazil", 
  BRITISH_INDIAN_OCEAN_TERRITORY: "British Indian Ocean Territory", 
  BRITISH_VIRGIN_ISLANDS: "British Virgin Islands", 
  BRUNEI_DARUSSALAM: "Brunei Darussalam", 
  BULGARIA: "Bulgaria", 
  BURKINA_FASO: "Burkina Faso", 
  BURUNDI: "Burundi", 
  CAMBODIA: "Cambodia", 
  CAMEROON: "Cameroon", 
  CANADA: "Canada", 
  CAPE_VERDE: "Cape Verde", 
  CAYMAN_ISLANDS: "Cayman Islands", 
  CENTRAL_AFRICAN_REPUBLIC: "Central African Republic", 
  CHAD: "Chad", 
  CHILE: "Chile", 
  CHINA: "China", 
  CHRISTMAS_ISLAND: "Christmas Island", 
  COCOS_KEELING_ISLANDS: "Cocos (Keeling) Islands", 
  COLOMBIA: "Colombia", 
  COMOROS: "Comoros", 
  CONGO_DEMOCRATIC_REPUBLIC_OF_THE: "Congo, Democratic Republic of the", 
  CONGO_REPUBLIC_OF_THE: "Congo, Republic of the", 
  COOK_ISLANDS: "Cook Islands", 
  COSTA_RICA: "Costa Rica", 
  COTE_DIVOIRE: "Cote d'Ivoire", 
  CROATIA: "Croatia", 
  CUBA: "Cuba", 
  CURACAO: "Curacao", 
  CYPRUS: "Cyprus", 
  CZECH_REPUBLIC: "Czech Republic", 
  DENMARK: "Denmark", 
  DJIBOUTI: "Djibouti", 
  DOMINICA: "Dominica", 
  DOMINICAN_REPUBLIC: "Dominican Republic", 
  ECUADOR: "Ecuador", 
  EGYPT: "Egypt", 
  EL_SALVADOR: "El Salvador", 
  EQUATORIAL_GUINEA: "Equatorial Guinea", 
  ERITREA: "Eritrea", 
  ESTONIA: "Estonia", 
  ETHIOPIA: "Ethiopia", 
  FALKLAND_ISLANDS: "Falkland Islands (Malvinas)", 
  FAROE_ISLANDS: "Faroe Islands", 
  FIJI: "Fiji", 
  FINLAND: "Finland", 
  FRANCE: "France", 
  FRENCH_GUIANA: "French Guiana", 
  FRENCH_POLYNESIA: "French Polynesia", 
  FRENCH_SOUTHERN_TERRITORIES: "French Southern Territories", 
  GABON: "Gabon", 
  GAMBIA: "Gambia", 
  GEORGIA: "Georgia", 
  GERMANY: "Germany", 
  GHANA: "Ghana", 
  GIBRALTAR: "Gibraltar", 
  GREECE: "Greece", 
  GREENLAND: "Greenland", 
  GRENADA: "Grenada", 
  GUADELOUPE: "Guadeloupe", 
  GUAM: "Guam", 
  GUATEMALA: "Guatemala", 
  GUERNSEY: "Guernsey", 
  GUINEA: "Guinea", 
  GUINEA_BISSAU: "Guinea-Bissau", 
  GUYANA: "Guyana", 
  HAITI: "Haiti", 
  HEARD_ISLAND_AND_MCDONALD_ISLANDS: "Heard Island and McDonald Islands", 
  HONDURAS: "Honduras", 
  HONG_KONG: "Hong Kong", 
  HUNGARY: "Hungary", 
  ICELAND: "Iceland", 
  INDIA: "India", 
  INDONESIA: "Indonesia", 
  IRAQ: "Iraq", 
  IRELAND: "Ireland", 
  ISLE_OF_MAN: "Isle of Man", 
  ISRAEL: "Israel", 
  ITALY: "Italy", 
  JAMAICA: "Jamaica", 
  JAPAN: "Japan", 
  JERSEY: "Jersey", 
  JORDAN: "Jordan", 
  KAZAKHSTAN: "Kazakhstan", 
  KENYA: "Kenya", 
  KIRIBATI: "Kiribati", 
  KOSOVO: "Kosovo", 
  KUWAIT: "Kuwait", 
  KYRGYZSTAN: "Kyrgyzstan", 
  LAOS: "Laos", 
  LATVIA: "Latvia", 
  LEBANON: "Lebanon", 
  LESOTHO: "Lesotho", 
  LIBERIA: "Liberia", 
  LIBYA: "Libya", 
  LIECHTENSTEIN: "Liechtenstein", 
  LITHUANIA: "Lithuania", 
  LUXEMBOURG: "Luxembourg", 
  MACAO: "Macao", 
  MADAGASCAR: "Madagascar", 
  MALAWI: "Malawi", 
  MALAYSIA: "Malaysia", 
  MALDIVES: "Maldives", 
  MALI: "Mali", 
  MALTA: "Malta", 
  MARSHALL_ISLANDS: "Marshall Islands", 
  MARTINIQUE: "Martinique", 
  MAURITANIA: "Mauritania", 
  MAURITIUS: "Mauritius", 
  MAYOTTE: "Mayotte", 
  MEXICO: "Mexico", 
  MICRONESIA: "Micronesia", 
  MOLDOVA: "Moldova, Republic of", 
  MONACO: "Monaco", 
  MONGOLIA: "Mongolia", 
  MONTENEGRO: "Montenegro", 
  MONTSERRAT: "Montserrat", 
  MOROCCO: "Morocco", 
  MOZAMBIQUE: "Mozambique", 
  MYANMAR: "Myanmar", 
  NAMIBIA: "Namibia", 
  NAURU: "Nauru", 
  NEPAL: "Nepal", 
  NETHERLANDS: "Netherlands", 
  NEW_CALEDONIA: "New Caledonia", 
  NEW_ZEALAND: "New Zealand", 
  NICARAGUA: "Nicaragua", 
  NIGER: "Niger", 
  NIGERIA: "Nigeria", 
  NIUE: "Niue", 
  NORFOLK_ISLAND: "Norfolk Island", 
  NORTH_MACEDONIA: "North Macedonia", 
  NORTHERN_MARIANA_ISLANDS: "Northern Mariana Islands", 
  NORWAY: "Norway", 
  OMAN: "Oman", 
  PAKISTAN: "Pakistan", 
  PALAU: "Palau", 
  PALESTINE: "Palestine", 
  PANAMA: "Panama", 
  PAPUA_NEW_GUINEA: "Papua New Guinea", 
  PARAGUAY: "Paraguay", 
  PERU: "Peru", 
  PHILIPPINES: "Philippines", 
  PITCAIRN: "Pitcairn", 
  POLAND: "Poland", 
  PORTUGAL: "Portugal", 
  PUERTO_RICO: "Puerto Rico", 
  QATAR: "Qatar", 
  REUNION: "Reunion", 
  ROMANIA: "Romania", 
  RUSSIA: "Russia", 
  RWANDA: "Rwanda", 
  SAMOA: "Samoa", 
  SAN_MARINO: "San Marino", 
  SAO_TOME_AND_PRINCIPE: "Sao Tome and Principe", 
  SAUDI_ARABIA: "Saudi Arabia", 
  SENEGAL: "Senegal", 
  SERBIA: "Serbia", 
  SEYCHELLES: "Seychelles", 
  SIERRA_LEONE: "Sierra Leone", 
  SINT_MAARTEN: "Sint Maarten", 
  SLOVAKIA: "Slovakia", 
  SLOVENIA: "Slovenia", 
  SOLOMON_ISLANDS: "Solomon Islands", 
  SOMALIA: "Somalia", 
  SOUTH_AFRICA: "South Africa", 
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: "South Georgia and the South Sandwich Islands", 
  SOUTH_KOREA: "South Korea",
  SOUTH_SUDAN: "South Sudan", 
  SPAIN: "Spain", 
  SRI_LANKA: "Sri Lanka", 
  ST_BARTHELEMY: "St. Barthelemy", 
  ST_HELENA: "St. Helena", 
  ST_KITTS_AND_NEVIS: "St. Kitts and Nevis", 
  ST_LUCIA: "St. Lucia", 
  ST_MARTIN: "St. Martin", 
  ST_PIERRE_AND_MIQUELON: "St. Pierre and Miquelon", 
  ST_VINCENT_AND_THE_GRENADINES: "St. Vincent and the Grenadines", 
  SUDAN: "Sudan", 
  SURINAME: "Suriname", 
  SVALBARD_AND_JAN_MAYEN: "Svalbard and Jan Mayen", 
  SWAZILAND: "Swaziland", 
  SWEDEN: "Sweden", 
  SWITZERLAND: "Switzerland", 
  SYRIA: "Syria", 
  TAIWAN: "Taiwan", 
  TAJIKISTAN: "Tajikistan", 
  THAILAND: "Thailand", 
  TIMOR_LESTE: "Timor-Leste", 
  TOGO: "Togo", 
  TOKELAU: "Tokelau", 
  TONGA: "Tonga", 
  TRINIDAD_AND_TOBAGO: "Trinidad and Tobago", 
  TUNISIA: "Tunisia", 
  TURKEY: "Turkey", 
  TURKMENISTAN: "Turkmenistan", 
  TURKS_AND_CAICOS_ISLANDS: "Turks and Caicos Islands", 
  TUVALU: "Tuvalu", 
  UGANDA: "Uganda", 
  UKRAINE: "Ukraine", 
  UNITED_ARAB_EMIRATES: "United Arab Emirates", 
  UNITED_KINGDOM: "United Kingdom", 
  UNITED_REPUBLIC_OF_TANZANIA: "United Republic of Tanzania", 
  URUGUAY: "Uruguay", 
  US_VIRGIN_ISLANDS: "US Virgin Islands", 
  UZBEKISTAN: "Uzbekistan", 
  VANUATU: "Vanuatu", 
  VATICAN_CITY_STATE: "Vatican City State", 
  VENEZUELA: "Venezuela", 
  VIETNAM: "Vietnam", 
  WALLIS_AND_FUTUNA: "Wallis and Futuna", 
  WESTERN_SAHARA: "Western Sahara", 
  YEMEN: "Yemen", 
  ZAMBIA: "Zambia", 
  ZIMBABWE: "Zimbabwe",
};

export const COUNTRIES = [
  COUNTRY_NAMES.SINGAPORE, 
  COUNTRY_NAMES.AFGHANISTAN, 
  COUNTRY_NAMES.ALBANIA, 
  COUNTRY_NAMES.ALGERIA, 
  COUNTRY_NAMES.ALLAND_ISLANDS, 
  COUNTRY_NAMES.AMERICA,
  COUNTRY_NAMES.AMERICAN_SAMOA, 
  COUNTRY_NAMES.ANDORRA, 
  COUNTRY_NAMES.ANGOLA, 
  COUNTRY_NAMES.ANGUILLA, 
  COUNTRY_NAMES.ANTARCTICA, 
  COUNTRY_NAMES.ANTIGUA_AND_BARBUDA, 
  COUNTRY_NAMES.ARGENTINA, 
  COUNTRY_NAMES.ARMENIA, 
  COUNTRY_NAMES.ARUBA, 
  COUNTRY_NAMES.AUSTRALIA, 
  COUNTRY_NAMES.AUSTRIA, 
  COUNTRY_NAMES.AZERBAIJAN, 
  COUNTRY_NAMES.BAHAMAS, 
  COUNTRY_NAMES.BAHRAIN, 
  COUNTRY_NAMES.BANGLADESH, 
  COUNTRY_NAMES.BARBADOS, 
  COUNTRY_NAMES.BELARUS, 
  COUNTRY_NAMES.BELGIUM, 
  COUNTRY_NAMES.BELIZE, 
  COUNTRY_NAMES.BENIN, 
  COUNTRY_NAMES.BERMUDA, 
  COUNTRY_NAMES.BHUTAN, 
  COUNTRY_NAMES.BOLIVIA, 
  COUNTRY_NAMES.BOSNIA_AND_HERZEGOVINA, 
  COUNTRY_NAMES.BOTSWANA, 
  COUNTRY_NAMES.BOUVET_ISLAND, 
  COUNTRY_NAMES.BRAZIL, 
  COUNTRY_NAMES.BRITISH_INDIAN_OCEAN_TERRITORY, 
  COUNTRY_NAMES.BRITISH_VIRGIN_ISLANDS, 
  COUNTRY_NAMES.BRUNEI_DARUSSALAM, 
  COUNTRY_NAMES.BULGARIA, 
  COUNTRY_NAMES.BURKINA_FASO, 
  COUNTRY_NAMES.BURUNDI, 
  COUNTRY_NAMES.CAMBODIA, 
  COUNTRY_NAMES.CAMEROON, 
  COUNTRY_NAMES.CANADA, 
  COUNTRY_NAMES.CAPE_VERDE, 
  COUNTRY_NAMES.CAYMAN_ISLANDS, 
  COUNTRY_NAMES.CENTRAL_AFRICAN_REPUBLIC, 
  COUNTRY_NAMES.CHAD, 
  COUNTRY_NAMES.CHILE, 
  COUNTRY_NAMES.CHINA, 
  COUNTRY_NAMES.CHRISTMAS_ISLAND, 
  COUNTRY_NAMES.COCOS_KEELING_ISLANDS, 
  COUNTRY_NAMES.COLOMBIA, 
  COUNTRY_NAMES.COMOROS, 
  COUNTRY_NAMES.CONGO_DEMOCRATIC_REPUBLIC_OF_THE, 
  COUNTRY_NAMES.CONGO_REPUBLIC_OF_THE, 
  COUNTRY_NAMES.COOK_ISLANDS, 
  COUNTRY_NAMES.COSTA_RICA, 
  COUNTRY_NAMES.COTE_DIVOIRE, 
  COUNTRY_NAMES.CROATIA, 
  COUNTRY_NAMES.CUBA, 
  COUNTRY_NAMES.CURACAO, 
  COUNTRY_NAMES.CYPRUS, 
  COUNTRY_NAMES.CZECH_REPUBLIC, 
  COUNTRY_NAMES.DENMARK, 
  COUNTRY_NAMES.DJIBOUTI, 
  COUNTRY_NAMES.DOMINICA, 
  COUNTRY_NAMES.DOMINICAN_REPUBLIC, 
  COUNTRY_NAMES.ECUADOR, 
  COUNTRY_NAMES.EGYPT, 
  COUNTRY_NAMES.EL_SALVADOR, 
  COUNTRY_NAMES.EQUATORIAL_GUINEA, 
  COUNTRY_NAMES.ERITREA, 
  COUNTRY_NAMES.ESTONIA, 
  COUNTRY_NAMES.ETHIOPIA, 
  COUNTRY_NAMES.FALKLAND_ISLANDS, 
  COUNTRY_NAMES.FAROE_ISLANDS, 
  COUNTRY_NAMES.FIJI, 
  COUNTRY_NAMES.FINLAND, 
  COUNTRY_NAMES.FRANCE, 
  COUNTRY_NAMES.FRENCH_GUIANA, 
  COUNTRY_NAMES.FRENCH_POLYNESIA, 
  COUNTRY_NAMES.FRENCH_SOUTHERN_TERRITORIES, 
  COUNTRY_NAMES.GABON, 
  COUNTRY_NAMES.GAMBIA, 
  COUNTRY_NAMES.GEORGIA, 
  COUNTRY_NAMES.GERMANY, 
  COUNTRY_NAMES.GHANA, 
  COUNTRY_NAMES.GIBRALTAR, 
  COUNTRY_NAMES.GREECE, 
  COUNTRY_NAMES.GREENLAND, 
  COUNTRY_NAMES.GRENADA, 
  COUNTRY_NAMES.GUADELOUPE, 
  COUNTRY_NAMES.GUAM, 
  COUNTRY_NAMES.GUATEMALA, 
  COUNTRY_NAMES.GUERNSEY, 
  COUNTRY_NAMES.GUINEA, 
  COUNTRY_NAMES.GUINEA_BISSAU, 
  COUNTRY_NAMES.GUYANA, 
  COUNTRY_NAMES.HAITI, 
  COUNTRY_NAMES.HEARD_ISLAND_AND_MCDONALD_ISLANDS, 
  COUNTRY_NAMES.HONDURAS, 
  COUNTRY_NAMES.HONG_KONG, 
  COUNTRY_NAMES.HUNGARY, 
  COUNTRY_NAMES.ICELAND, 
  COUNTRY_NAMES.INDIA, 
  COUNTRY_NAMES.INDONESIA, 
  COUNTRY_NAMES.IRAQ, 
  COUNTRY_NAMES.IRELAND, 
  COUNTRY_NAMES.ISLE_OF_MAN, 
  COUNTRY_NAMES.ISRAEL, 
  COUNTRY_NAMES.ITALY, 
  COUNTRY_NAMES.JAMAICA, 
  COUNTRY_NAMES.JAPAN, 
  COUNTRY_NAMES.JERSEY, 
  COUNTRY_NAMES.JORDAN, 
  COUNTRY_NAMES.KAZAKHSTAN, 
  COUNTRY_NAMES.KENYA, 
  COUNTRY_NAMES.KIRIBATI, 
  COUNTRY_NAMES.KOSOVO, 
  COUNTRY_NAMES.KUWAIT, 
  COUNTRY_NAMES.KYRGYZSTAN, 
  COUNTRY_NAMES.LAOS, 
  COUNTRY_NAMES.LATVIA, 
  COUNTRY_NAMES.LEBANON, 
  COUNTRY_NAMES.LESOTHO, 
  COUNTRY_NAMES.LIBERIA, 
  COUNTRY_NAMES.LIBYA, 
  COUNTRY_NAMES.LIECHTENSTEIN, 
  COUNTRY_NAMES.LITHUANIA, 
  COUNTRY_NAMES.LUXEMBOURG, 
  COUNTRY_NAMES.MACAO, 
  COUNTRY_NAMES.MADAGASCAR, 
  COUNTRY_NAMES.MALAWI, 
  COUNTRY_NAMES.MALAYSIA, 
  COUNTRY_NAMES.MALDIVES, 
  COUNTRY_NAMES.MALI, 
  COUNTRY_NAMES.MALTA, 
  COUNTRY_NAMES.MARSHALL_ISLANDS, 
  COUNTRY_NAMES.MARTINIQUE, 
  COUNTRY_NAMES.MAURITANIA, 
  COUNTRY_NAMES.MAURITIUS, 
  COUNTRY_NAMES.MAYOTTE, 
  COUNTRY_NAMES.MEXICO, 
  COUNTRY_NAMES.MICRONESIA, 
  COUNTRY_NAMES.MOLDOVA, 
  COUNTRY_NAMES.MONACO, 
  COUNTRY_NAMES.MONGOLIA, 
  COUNTRY_NAMES.MONTENEGRO, 
  COUNTRY_NAMES.MONTSERRAT, 
  COUNTRY_NAMES.MOROCCO, 
  COUNTRY_NAMES.MOZAMBIQUE, 
  COUNTRY_NAMES.MYANMAR, 
  COUNTRY_NAMES.NAMIBIA, 
  COUNTRY_NAMES.NAURU, 
  COUNTRY_NAMES.NEPAL, 
  COUNTRY_NAMES.NETHERLANDS, 
  COUNTRY_NAMES.NEW_CALEDONIA, 
  COUNTRY_NAMES.NEW_ZEALAND, 
  COUNTRY_NAMES.NICARAGUA, 
  COUNTRY_NAMES.NIGER, 
  COUNTRY_NAMES.NIGERIA, 
  COUNTRY_NAMES.NIUE, 
  COUNTRY_NAMES.NORFOLK_ISLAND, 
  COUNTRY_NAMES.NORTH_MACEDONIA, 
  COUNTRY_NAMES.NORTHERN_MARIANA_ISLANDS, 
  COUNTRY_NAMES.NORWAY, 
  COUNTRY_NAMES.OMAN, 
  COUNTRY_NAMES.PAKISTAN, 
  COUNTRY_NAMES.PALAU, 
  COUNTRY_NAMES.PALESTINE, 
  COUNTRY_NAMES.PANAMA, 
  COUNTRY_NAMES.PAPUA_NEW_GUINEA, 
  COUNTRY_NAMES.PARAGUAY, 
  COUNTRY_NAMES.PERU, 
  COUNTRY_NAMES.PHILIPPINES, 
  COUNTRY_NAMES.PITCAIRN, 
  COUNTRY_NAMES.POLAND, 
  COUNTRY_NAMES.PORTUGAL, 
  COUNTRY_NAMES.PUERTO_RICO, 
  COUNTRY_NAMES.QATAR, 
  COUNTRY_NAMES.REUNION, 
  COUNTRY_NAMES.ROMANIA, 
  COUNTRY_NAMES.RUSSIA, 
  COUNTRY_NAMES.RWANDA, 
  COUNTRY_NAMES.SAMOA, 
  COUNTRY_NAMES.SAN_MARINO, 
  COUNTRY_NAMES.SAO_TOME_AND_PRINCIPE, 
  COUNTRY_NAMES.SAUDI_ARABIA, 
  COUNTRY_NAMES.SENEGAL, 
  COUNTRY_NAMES.SERBIA, 
  COUNTRY_NAMES.SEYCHELLES, 
  COUNTRY_NAMES.SIERRA_LEONE, 
  COUNTRY_NAMES.SINT_MAARTEN, 
  COUNTRY_NAMES.SLOVAKIA, 
  COUNTRY_NAMES.SLOVENIA, 
  COUNTRY_NAMES.SOLOMON_ISLANDS, 
  COUNTRY_NAMES.SOMALIA, 
  COUNTRY_NAMES.SOUTH_AFRICA, 
  COUNTRY_NAMES.SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS, 
  COUNTRY_NAMES.SOUTH_KOREA,
  COUNTRY_NAMES.SOUTH_SUDAN, 
  COUNTRY_NAMES.SPAIN, 
  COUNTRY_NAMES.SRI_LANKA, 
  COUNTRY_NAMES.ST_BARTHELEMY, 
  COUNTRY_NAMES.ST_HELENA, 
  COUNTRY_NAMES.ST_KITTS_AND_NEVIS, 
  COUNTRY_NAMES.ST_LUCIA, 
  COUNTRY_NAMES.ST_MARTIN, 
  COUNTRY_NAMES.ST_PIERRE_AND_MIQUELON, 
  COUNTRY_NAMES.ST_VINCENT_AND_THE_GRENADINES, 
  COUNTRY_NAMES.SUDAN, 
  COUNTRY_NAMES.SURINAME, 
  COUNTRY_NAMES.SVALBARD_AND_JAN_MAYEN, 
  COUNTRY_NAMES.SWAZILAND, 
  COUNTRY_NAMES.SWEDEN, 
  COUNTRY_NAMES.SWITZERLAND, 
  COUNTRY_NAMES.SYRIA, 
  COUNTRY_NAMES.TAIWAN, 
  COUNTRY_NAMES.TAJIKISTAN, 
  COUNTRY_NAMES.THAILAND, 
  COUNTRY_NAMES.TIMOR_LESTE, 
  COUNTRY_NAMES.TOGO, 
  COUNTRY_NAMES.TOKELAU, 
  COUNTRY_NAMES.TONGA, 
  COUNTRY_NAMES.TRINIDAD_AND_TOBAGO, 
  COUNTRY_NAMES.TUNISIA, 
  COUNTRY_NAMES.TURKEY, 
  COUNTRY_NAMES.TURKMENISTAN, 
  COUNTRY_NAMES.TURKS_AND_CAICOS_ISLANDS, 
  COUNTRY_NAMES.TUVALU, 
  COUNTRY_NAMES.UGANDA, 
  COUNTRY_NAMES.UKRAINE, 
  COUNTRY_NAMES.UNITED_ARAB_EMIRATES, 
  COUNTRY_NAMES.UNITED_KINGDOM, 
  COUNTRY_NAMES.UNITED_REPUBLIC_OF_TANZANIA, 
  COUNTRY_NAMES.URUGUAY, 
  COUNTRY_NAMES.US_VIRGIN_ISLANDS, 
  COUNTRY_NAMES.UZBEKISTAN, 
  COUNTRY_NAMES.VANUATU, 
  COUNTRY_NAMES.VATICAN_CITY_STATE, 
  COUNTRY_NAMES.VENEZUELA, 
  COUNTRY_NAMES.VIETNAM, 
  COUNTRY_NAMES.WALLIS_AND_FUTUNA, 
  COUNTRY_NAMES.WESTERN_SAHARA, 
  COUNTRY_NAMES.YEMEN, 
  COUNTRY_NAMES.ZAMBIA, 
  COUNTRY_NAMES.ZIMBABWE,
];

// Reformatted version of
// https://bitbucket.org/atlassian/atlaski"At-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const COUNTRY_LIST = {
  "Singapore": { code: "SG", phone: "+65" }, 
  "Afghanistan": { code: "AF", phone: "+93" }, 
  "Albania": { code: "AL", phone: "+355" }, 
  "Algeria": { code: "DZ", phone: "+213" }, 
  "Alland Islands": { code: "AX", phone: "+358" }, 
  "American Samoa": { code: "AS", phone: "+1-684" }, 
  "Andorra": { code: "AD", phone: "+376" }, 
  "Angola": { code: "AO", phone: "+244" }, 
  "Anguilla": { code: "AI", phone: "+1-264" }, 
  "Antarctica": { code: "AQ", phone: "+672" }, 
  "Antigua and Barbuda": { code: "AG", phone: "+1-268" },
  "Argentina": { code: "AR", phone: "+54" },
  "Armenia": { code: "AM", phone: "+374" },
  "Aruba": { code: "AW", phone: "+297" },
  "Australia": { code: "AU", phone: "+61" },
  "Austria": { code: "AT", phone: "+43" },
  "Azerbaijan": { code: "AZ", phone: "+994" },
  "Bahamas": { code: "BS", phone: "+1-242" },
  "Bahrain": { code: "BH", phone: "+973" },
  "Bangladesh": { code: "BD", phone: "+880" },
  "Barbados": { code: "BB", phone: "+1-246" },
  "Belarus": { code: "BY", phone: "+375" },
  "Belgium": { code: "BE", phone: "+32" },
  "Belize": { code: "BZ", phone: "+501" },
  "Benin": { code: "BJ", phone: "+229" },
  "Bermuda": { code: "BM", phone: "+1-441" },
  "Bhutan": { code: "BT", phone: "+975" },
  "Bolivia": { code: "BO", phone: "+591" },
  "Bosnia and Herzegovina": { code: "BA", phone: "+387" },
  "Botswana": { code: "BW", phone: "+267" },
  "Bouvet Island": { code: "BV", phone: "+47" },
  "Brazil": { code: "BR", phone: "+55" },
  "British Indian Ocean Territory": { code: "IO", phone: "+246" },
  "British Virgin Islands": { code: "VG", phone: "+1-284" },
  "Brunei Darussalam": { code: "BN", phone: "+673" },
  "Bulgaria": { code: "BG", phone: "+359" },
  "Burkina Faso": { code: "BF", phone: "+226" },
  "Burundi": { code: "BI", phone: "+257" },
  "Cambodia": { code: "KH", phone: "+855" },
  "Cameroon": { code: "CM", phone: "+237" },
  "Canada": { code: "CA", phone: "+1" },
  "Cape Verde": { code: "CV", phone: "+238" },
  "Cayman Islands": { code: "KY", phone: "+1-345" },
  "Central African Republic": { code: "CF", phone: "+236" },
  "Chad": { code: "TD", phone: "+235" },
  "Chile": { code: "CL", phone: "+56" },
  "China": { code: "CN", phone: "+86" },
  "Christmas Island": { code: "CX", phone: "+61" },
  "Cocos (Keeling) Islands": { code: "CC", phone: "+61" },
  "Colombia": { code: "CO", phone: "+57" },
  "Comoros": { code: "KM", phone: "+269" },
  "Congo, Democratic Republic of the": { code: "CD", phone: "+243" },
  "Congo, Republic of the": { code: "CG", phone: "+242" },
  "Cook Islands": { code: "CK", phone: "+682" },
  "Costa Rica": { code: "CR", phone: "+506" },
  "Cote d'Ivoire": { code: "CI", phone: "+225" },
  "Croatia": { code: "HR", phone: "+385" },
  "Cuba": { code: "CU", phone: "+53" },
  "Curacao": { code: "CW", phone: "+599" },
  "Cyprus": { code: "CY", phone: "+357" },
  "Czech Republic": { code: "CZ", phone: "+420" },
  "Denmark": { code: "DK", phone: "+45" },
  "Djibouti": { code: "DJ", phone: "+253" },
  "Dominica": { code: "DM", phone: "+1-767" },
  "Dominican Republic": { code: "DO", phone: "+1-809" },
  "Ecuador": { code: "EC", phone: "+593" },
  "Egypt": { code: "EG", phone: "+20" },
  "El Salvador": { code: "SV", phone: "+503" },
  "Equatorial Guinea": { code: "GQ", phone: "+240" },
  "Eritrea": { code: "ER", phone: "+291" },
  "Estonia": { code: "EE", phone: "+372" },
  "Ethiopia": { code: "ET", phone: "+251" },
  "Falkland Islands (Malvinas)": { code: "FK", phone: "+500" },
  "Faroe Islands": { code: "FO", phone: "+298" },
  "Fiji": { code: "FJ", phone: "+679" },
  "Finland": { code: "FI", phone: "+358" },
  "France": { code: "FR", phone: "+33" },
  "French Guiana": { code: "GF", phone: "+594" },
  "French Polynesia": { code: "PF", phone: "+689" },
  "French Southern Territories": { code: "TF", phone: "+262" },
  "Gabon": { code: "GA", phone: "+241" },
  "Gambia": { code: "GM", phone: "+220" },
  "Georgia": { code: "GE", phone: "+995" },
  "Germany": { code: "DE", phone: "+49" },
  "Ghana": { code: "GH", phone: "+233" },
  "Gibraltar": { code: "GI", phone: "+350" },
  "Greece": { code: "GR", phone: "+30" },
  "Greenland": { code: "GL", phone: "+299" },
  "Grenada": { code: "GD", phone: "+1-473" },
  "Guadeloupe": { code: "GP", phone: "+590" },
  "Guam": { code: "GU", phone: "+1-671" },
  "Guatemala": { code: "GT", phone: "+502" },
  "Guernsey": { code: "GG", phone: "+44" },
  "Guinea": { code: "GN", phone: "+224" },
  "Guinea-Bissau": { code: "GW", phone: "+245" },
  "Guyana": { code: "GY", phone: "+592" }, 
  "Haiti": { code: "HT", phone: "+509" }, 
  "Heard Island and McDonald Islands": { code: "HM", phone: "+672" }, 
  "Honduras": { code: "HN", phone: "+504" }, 
  "Hong Kong": { code: "HK", phone: "+852" }, 
  "Hungary": { code: "HU", phone: "+36" }, 
  "Iceland": { code: "IS", phone: "+354" }, 
  "India": { code: "IN", phone: "+91" }, 
  "Indonesia": { code: "ID", phone: "+62" }, 
  "Iraq": { code: "IQ", phone: "+964" }, 
  "Ireland": { code: "IE", phone: "+353" }, 
  "Isle of Man": { code: "IM", phone: "+44" }, 
  "Israel": { code: "IL", phone: "+972" }, 
  "Italy": { code: "IT", phone: "+39" }, 
  "Jamaica": { code: "JM", phone: "+1-876" }, 
  "Japan": { code: "JP", phone: "+81" }, 
  "Jersey": { code: "JE", phone: "+44" }, 
  "Jordan": { code: "JO", phone: "+962" }, 
  "Kazakhstan": { code: "KZ", phone: "+7" }, 
  "Kenya": { code: "KE", phone: "+254" }, 
  "Kiribati": { code: "KI", phone: "+686" }, 
  "Kosovo": { code: "XK", phone: "+383" }, 
  "Kuwait": { code: "KW", phone: "+965" }, 
  "Kyrgyzstan": { code: "KG", phone: "+996" }, 
  "Laos": { code: "LA", phone: "+856" }, 
  "Latvia": { code: "LV", phone: "+371" }, 
  "Lebanon": { code: "LB", phone: "+961" }, 
  "Lesotho": { code: "LS", phone: "+266" }, 
  "Liberia": { code: "LR", phone: "+231" }, 
  "Libya": { code: "LY", phone: "+218" }, 
  "Liechtenstein": { code: "LI", phone: "+423" }, 
  "Lithuania": { code: "LT", phone: "+370" }, 
  "Luxembourg": { code: "LU", phone: "+352" }, 
  "Macao": { code: "MO", phone: "+853" }, 
  "Madagascar": { code: "MG", phone: "+261" }, 
  "Malawi": { code: "MW", phone: "+265" }, 
  "Malaysia": { code: "MY", phone: "+60" }, 
  "Maldives": { code: "MV", phone: "+960" }, 
  "Mali": { code: "ML", phone: "+223" }, 
  "Malta": { code: "MT", phone: "+356" }, 
  "Marshall Islands": { code: "MH", phone: "+692" },
  "Martinique": { code: "MQ", phone: "+596" },
  "Mauritania": { code: "MR", phone: "+222" },
  "Mauritius": { code: "MU", phone: "+230" },
  "Mayotte": { code: "YT", phone: "+262" },
  "Mexico": { code: "MX", phone: "+52" },
  "Micronesia": { code: "FM", phone: "+691" },
  "Moldova, Republic of": { code: "MD", phone: "+373" },
  "Monaco": { code: "MC", phone: "+377" },
  "Mongolia": { code: "MN", phone: "+976" },
  "Montenegro": { code: "ME", phone: "+382" },
  "Montserrat": { code: "MS", phone: "+1-664" },
  "Morocco": { code: "MA", phone: "+212" },
  "Mozambique": { code: "MZ", phone: "+258" },
  "Myanmar": { code: "MM", phone: "+95" },
  "Namibia": { code: "NA", phone: "+264" },
  "Nauru": { code: "NR", phone: "+674" },
  "Nepal": { code: "NP", phone: "+977" },
  "Netherlands": { code: "NL", phone: "+31" },
  "New Caledonia": { code: "NC", phone: "+687" },
  "New Zealand": { code: "NZ", phone: "+64" },
  "Nicaragua": { code: "NI", phone: "+505" },
  "Niger": { code: "NE", phone: "+227" },
  "Nigeria": { code: "NG", phone: "+234" },
  "Niue": { code: "NU", phone: "+683" },
  "Norfolk Island": { code: "NF", phone: "+672" },
  "North Macedonia": { code: "MK", phone: "+389" },
  "Northern Mariana Islands": { code: "MP", phone: "+1-670" },
  "Norway": { code: "NO", phone: "+47" },
  "Oman": { code: "OM", phone: "+968" },
  "Pakistan": { code: "PK", phone: "+92" },
  "Palau": { code: "PW", phone: "+680" },
  "Palestine": { code: "PS", phone: "+970" },
  "Panama": { code: "PA", phone: "+507" },
  "Papua New Guinea": { code: "PG", phone: "+675" },
  "Paraguay": { code: "PY", phone: "+595" },
  "Peru": { code: "PE", phone: "+51" },
  "Philippines": { code: "PH", phone: "+63" },
  "Pitcairn": { code: "PN", phone: "+870" },
  "Poland": { code: "PL", phone: "+48" },
  "Portugal": { code: "PT", phone: "+351" },
  "Puerto Rico": { code: "PR", phone: "+1" },
  "Qatar": { code: "QA", phone: "+974" },
  "Reunion": { code: "RE", phone: "+262" },
  "Romania": { code: "RO", phone: "+40" },
  "Russia": { code: "RU", phone: "+7" },
  "Rwanda": { code: "RW", phone: "+250" },
  "St. Barthelemy": { code: "BL", phone: "+590" },
  "St. Helena": { code: "SH", phone: "+290" },
  "St. Kitts and Nevis": { code: "KN", phone: "+1-869" },
  "St. Lucia": { code: "LC", phone: "+1-758" },
  "St. Martin": { code: "MF", phone: "+590" },
  "St. Pierre and Miquelon": { code: "PM", phone: "+508" },
  "St. Vincent and the Grenadines": { code: "VC", phone: "+1-784" },
  "Samoa": { code: "WS", phone: "+685" },
  "San Marino": { code: "SM", phone: "+378" },
  "Sao Tome and Principe": { code: "ST", phone: "+239" },
  "Saudi Arabia": { code: "SA", phone: "+966" },
  "Senegal": { code: "SN", phone: "+221" },
  "Serbia": { code: "RS", phone: "+381" },
  "Seychelles": { code: "SC", phone: "+248" },
  "Sierra Leone": { code: "SL", phone: "+232" },
  "Sint Maarten": { code: "SX", phone: "+1-721" },
  "Slovakia": { code: "SK", phone: "+421" },
  "Slovenia": { code: "SI", phone: "+386" },
  "Solomon Islands": { code: "SB", phone: "+677" },
  "Somalia": { code: "SO", phone: "+252" },
  "South Africa": { code: "ZA", phone: "+27" },
  "South Georgia and the South Sandwich Islands": { code: "GS", phone: "+500" },
  "South Korea": { code: "KR", phone: "+82" },
  "South Sudan": { code: "SS", phone: "+211" },
  "Spain": { code: "ES", phone: "+34" },
  "Sri Lanka": { code: "LK", phone: "+94" },
  "Sudan": { code: "SD", phone: "+249" },
  "Suriname": { code: "SR", phone: "+597" },
  "Svalbard and Jan Mayen": { code: "SJ", phone: "+47" },
  "Swaziland": { code: "SZ", phone: "+268" },
  "Sweden": { code: "SE", phone: "+46" },
  "Switzerland": { code: "CH", phone: "+41" },
  "Syria": { code: "SY", phone: "+963" },
  "Taiwan": { code: "TW", phone: "+886" },
  "Tajikistan": { code: "TJ", phone: "+992" },
  "Thailand": { code: "TH", phone: "+66" },
  "Timor-Leste": { code: "TL", phone: "+670" },
  "Togo": { code: "TG", phone: "+228" },
  "Tokelau": { code: "TK", phone: "+690" },
  "Tonga": { code: "TO", phone: "+676" },
  "Trinidad and Tobago": { code: "TT", phone: "+1-868" },
  "Tunisia": { code: "TN", phone: "+216" },
  "Turkey": { code: "TR", phone: "+90" },
  "Turkmenistan": { code: "TM", phone: "+993" },
  "Turks and Caicos Islands": { code: "TC", phone: "+1-649" },
  "Tuvalu": { code: "TV", phone: "+688" },
  "Uganda": { code: "UG", phone: "+256" },
  "Ukraine": { code: "UA", phone: "+380" },
  "United Arab Emirates": { code: "AE", phone: "+971" },
  "United Kingdom": { code: "GB", phone: "+44" },
  "United Republic of Tanzania": { code: "TZ", phone: "+255" },
  "America": { code: "US", phone: "+1", fullName: "United States of America" },
  "Uruguay": { code: "UY", phone: "+598" },
  "US Virgin Islands": { code: "VI", phone: "+1-340" },
  "Uzbekistan": { code: "UZ", phone: "+998" },
  "Vanuatu": { code: "VU", phone: "+678" },
  "Vatican City State": { code: "VA", phone: "+379" },
  "Venezuela": { code: "VE", phone: "+58" },
  "Vietnam": { code: "VN", phone: "+84" },
  "Wallis and Futuna": { code: "WF", phone: "+681" },
  "Western Sahara": { code: "EH", phone: "+212" },
  "Yemen": { code: "YE", phone: "+967" },
  "Zambia": { code: "ZM", phone: "+260" },
  "Zimbabwe": { code: "ZW", phone: "+263" },
};

import PasswordValidator from 'password-validator';

import { PASSWORD_RULES } from '../constants/passwordConstants';

export const validatePassword = (password) => {
  const schema = new PasswordValidator();
  const length = PASSWORD_RULES.min;
  const uppercase = PASSWORD_RULES.uppercase;
  const number = PASSWORD_RULES.digits;
  const special = PASSWORD_RULES.symbols;

  schema
    .is().min(length.min)
    .has().uppercase(uppercase.min)
    .has().digits(number.min)
    .has().symbols(special.min);
  
  return schema.validate(password, { list: true });
};

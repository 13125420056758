import * as React from "react";
import { useState } from "react";
import { isEmpty } from "lodash";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  CardActions,
} from "@mui/material";
import { getAuthorizedHeader } from "../utils/apiUtils";
import { notifyError, notifySuccess } from "../services/notificationService";
import SolidBlueButton from "./buttons/SolidBlueButton";

function POAcknowledgementLogTable(props) {
  const { rows, user } = props;

  const [data, setData] = useState(isEmpty(rows) ? [] : rows.slice(0, 1));

  const handleStatusChange = (event, idx) => {
    const updatedRows = data.map((row, rowIndex) => {
      if (rowIndex === idx) {
        return {
          ...row,
          poAcknowledged: event.target.value === "Acknowledged" ? 1 : 0,
        };
      }
      return row;
    });

    setData(updatedRows);
  };

  const onClickSaveButton = () => {
    try {
      // update PO acknowledgement log table
      const payload = data.map((row) => ({
        itemID: row.itemID,
        poAcknowledged: row.poAcknowledged,
        userID: user.userID,
      }));

      fetch(
        `${process.env.REACT_APP_BACKEND_SERVICE}/onboarding/update-po-acknowledgement-status`,
        {
          method: "POST",
          headers: getAuthorizedHeader(),
          body: JSON.stringify(payload),
        }
      ).then((res) => {
        if (res.ok) {
          notifySuccess("Table updated!");
        } else {
          notifyError("There was an error, please try again later.");
        }
      });
    } catch (err) {
      notifyError("There was an error, please try again later.");
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", minWidth: "6vw" }}>
                Part ID
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", minWidth: "9vw" }}>
                Part Name
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", minWidth: "13vw" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, idx) => (
              <TableRow
                key={row.itemID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.itemID}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <TextField
                    select
                    value={
                      row.poAcknowledged == 1 ? "Acknowledged" : "Pending"
                    }
                    sx={{
                      backgroundColor:
                        row.poAcknowledged == 1 ? "#c0ff33" : "#ffc163",
                    }}
                    onChange={(event) => handleStatusChange(event, idx)}
                  >
                    <MenuItem value="Acknowledged">Acknowledged</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                  </TextField>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions style={{ justifyContent: "right" }}>
        <SolidBlueButton btnContent={"save"} onBtnClick={onClickSaveButton} />
      </CardActions>
    </React.Fragment>
  );
}

export default POAcknowledgementLogTable;

import React from 'react';
import { isEmpty } from 'lodash';

import { Button, IconButton, Tooltip } from '@mui/material';

import GetAppIcon from '@mui/icons-material/GetApp';

import * as fileUtils from '../../utils/fileUtils';

function DownloadAllFilesButton(props) {
  const {
    folderName = 'Download',
    urlList = [],
    buttonText = '',
  } = props;

  const handleDownloadAll = async () => {
    if (isEmpty(urlList)) {
      return;
    }
    if (!urlList[0].subFolder) {
      fileUtils.downloadAllS3Files(urlList, folderName);
    } else {
      fileUtils.downloadAllS3FilesSeparatedBySubFolders(urlList, folderName);
    }
  };

  return !isEmpty(buttonText)
    ? (
      <Button
        onClick={handleDownloadAll}
        color="primary"
        startIcon={<GetAppIcon />}
      >
        {buttonText}
      </Button>
    )
    : (
      <Tooltip title="Download All">
        <IconButton
          size="small"
          color="primary"
          onClick={handleDownloadAll}
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    );
}

export default DownloadAllFilesButton;

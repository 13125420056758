import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { GOOGLE_PLACES_API } from '../constants/apiConstants';

/**
 *
 * @param {object} props
 * @param {'medium' | 'small'} props.size
 * @param {'normal' | 'none' | 'dense'} props.margin
 * @param {string} props.className
 * @param {string} props.label
 * @param {React.CSSProperties} props.style
 * @param {boolean} props.required
 * @param {({ postalCode, unitNo, address}) => void} props.onChange
 */
const AddressAutocomplete = (props) => {
  const {
    size = 'medium',
    margin = 'normal',
    className = '',
    label = 'Address',
    style = null,
    required = false,
    onChange = () => null,
  } = props;
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [unitNo, setUnitNo] = useState('');

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = GOOGLE_PLACES_API;
      script.async = true;
      document.body.appendChild(script);
      script.onload = initAutocomplete;
    };

    const initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('address'),
        {
          types: ['address'],
        }
      );

      autocomplete.setFields();

      autocomplete.addListener('place_changed', () => {
        const placeResult = autocomplete.getPlace();
        const addressComponents = placeResult.address_components;
        const address = placeResult.name;
        if (address) {
          setAddress(address);
        }
        const country = addressComponents.find(component => component.types.includes('country'));
        if (country) {
          setAddress(`${address}, ${country.long_name}`);
        }
        const postalCode = addressComponents.find(component => component.types.includes('postal_code'));
        if (postalCode) {
          setPostalCode(postalCode.long_name);
        }
      });
    };

    loadGoogleMapsScript();
  }, []);

  useEffect(() => {
    onChange({ postalCode, unitNo, address });
  }, [postalCode, unitNo, address]);

  return (
    <React.Fragment>
      <TextField
        variant='outlined'
        margin={margin}
        fullWidth
        className={className}
        name='address'
        label={label}
        type='text'
        multiline
        id='address'
        placeholder='Please Enter Your Address'
        onChange={(e) => {setAddress(e.target.value)}}
        value={address}
        size={size}
        style={style}
        required={required}
      />
      {address && (
        <div
          style={{
            marginBottom: '0.7rem',
            marginTop: '-0.8rem',
            display: 'flex',
            gap: '0.5rem',
            width: '100%',
            alignContent: 'center',
          }}
        >
          <TextField
            required
            variant='outlined'
            fullWidth
            name='postalCode'
            label='Postal Code'
            type='text'
            id='postalCode'
            margin='dense'
            value={postalCode}
            onChange={(evt) => setPostalCode(evt.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            name='unitNo'
            label='Unit No'
            type='text'
            id='unitNo'
            margin='dense'
            value={unitNo}
            onChange={(evt) => setUnitNo(evt.target.value)}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default AddressAutocomplete;

import React from 'react';

import { colors } from '../../palette';

const DEFAULT_URL = 'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder';

function SingleImage(props) {
  const {
    url,
    width = 70,
    height = 70,
    noBorder = false,
    showImagePlaceholder = true,
  } = props;

  const imageUrl = url && url.startsWith('http') ? url : DEFAULT_URL;

  if (imageUrl === DEFAULT_URL && !showImagePlaceholder) {
    return null;
  }

  return (
    <div
      style={{
        width,
        height,
      }}
    >
      <img
        style={{
          width,
          height,
          borderRadius: 5,
          border: noBorder ? 'none' : `1px solid ${colors.inputBorderBlue}`,
        }}
        src={imageUrl}
        alt=''
      />
    </div>
  );
}

export default SingleImage;

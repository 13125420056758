import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as s3Api from '../apis/s3Api';

import {
  // CADPART_DIRNAME,
  // BOM_DIR_NAME,
  CUSTOMER_PO_DIR_NAME,
  ASSESSMENT_REPORT_DIR_NAME,
  PARTNERSHIP_AGREEMENT_DIR_NAME,
  QUOTATION_FORM_DIR_NAME,
  // REACT_APP_IMGPART_DIRNAME,
  // CUSTOMER_NDA_DIR_NAME,
  ONBOARDING_CAD_FILE_DIR_NAME,
  ONBOARDING_TECH_DRAWING_DIR_NAME,
  ONBOARDING_UPLOADED_IMAGE_FILE_DIR_NAME,
} from '../constants';

// export const getCadPartS3Key = (file) => {
//   const preFilename = file.name
//     .split('.')
//     .slice(0, -1)
//     .join('.');
//   const fileExtension = file.name.split('.').pop();
//   return `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
// };

// export const getBomS3Key = (file, projectID) => {
//   const fileID = uuidv4();
//   const fileExtension = file.name.split('.').pop();
//   return `${BOM_DIR_NAME}/${projectID}/${fileID}.${fileExtension}`;
// }

export const getCustomerPoFileS3Key = (file, itemID) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${CUSTOMER_PO_DIR_NAME}/${itemID}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getAssessmentReportFileS3Key = (file, itemID) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${ASSESSMENT_REPORT_DIR_NAME}/${itemID}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getPartnershipAgreementFileS3Key = (file, userID) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${PARTNERSHIP_AGREEMENT_DIR_NAME}/${userID}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getQuotationFormFileS3Key = (file, userID) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${QUOTATION_FORM_DIR_NAME}/${userID}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getOnboardingCadFileS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${ONBOARDING_CAD_FILE_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getOnboardingTechDrawingS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${ONBOARDING_TECH_DRAWING_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
}

export const getUploadedImageFileS3Key = (file) => {
  const fileID = uuidv4();
  const fileExtension = file.name.split('.').pop();
  return `${ONBOARDING_UPLOADED_IMAGE_FILE_DIR_NAME}/${fileID}.${fileExtension}`;
}

// export const getImageFileS3Key = (file) => {
//   const fileID = uuidv4();
//   const fileExtension = file.name.split('.').pop();
//   return `${REACT_APP_IMGPART_DIRNAME}/${fileID}.${fileExtension}`;
// }

// export const getCustomerNDAFileS3Key = (file) => {
//   const preFilename = file.name
//     .split('.')
//     .slice(0, -1)
//     .join('.');
//   const fileExtension = file.name.split('.').pop();
//   return `${CUSTOMER_NDA_DIR_NAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
// }

export const generatePresignedUrl = async (s3ObjUrl, expirationTime = null) => {
  return s3Api.generatePresignedUrl(s3ObjUrl, expirationTime);
}

export const uploadFileToS3 = async (file, s3Key, customFileName = null) => {
  if (isEmpty(s3Key)) {
    throw new Error(`s3Key is empty`);
  }
  const fileName = customFileName || file.name;
  return s3Api.uploadFileToS3(file, s3Key, fileName);
};

export const uploadPublicFileToS3 = async (file, s3Key, customFileName = null) => {
  if (isEmpty(s3Key)) {
    throw new Error(`s3Key is empty`);
  }
  const fileName = customFileName || file.name;
  return s3Api.uploadFileToS3(file, s3Key, fileName, true);
};

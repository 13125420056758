import React from 'react';

import { CardMedia } from '@mui/material';

import { makeStyles } from 'tss-react/mui';
import { getItemImageUrl } from '../../utils/itemUtils';

const useStyles = makeStyles()(() => ({
  media: {
    backgroundSize: "contain",
    margin: 2,
  },
}));

const IMAGE_AREA_HEIGHT = 250;
const MAX_IMAGES = 9;

function ProjectImageCollection(props) {
  const { classes } = useStyles();

  const { items } = props;
  const itemImages = items
    .map((item, index) => {
      return {
        itemID: item.itemID,
        imageUrl: getItemImageUrl(item),
      }
    })
    .filter(item => item.imageUrl
      && item.imageUrl.startsWith('http')
    )
    .slice(0, MAX_IMAGES);
  const numOfImages = itemImages.length;
  const numOfRows = Math.ceil(Math.sqrt(numOfImages));
  const imageHeight = Math.floor(IMAGE_AREA_HEIGHT / numOfRows);
  return (
    <div
      style={{
        display: 'flex',
        height: IMAGE_AREA_HEIGHT + 16,
        width: IMAGE_AREA_HEIGHT + 16,
        alignItems: 'top',
        justifyContent: 'start',
        flexWrap: 'wrap',
      }}
    >
      {itemImages.map(item => (
        <CardMedia
          key={item.itemID}
          className={classes.media}
          style={{
            minWidth: imageHeight,
            minHeight: imageHeight,
            maxWidth: imageHeight,
            maxHeight: imageHeight,
          }}
          image={item.imageUrl}
          title={item.referenceName}
        />
      ))}
    </div>
  );
}

export default ProjectImageCollection;
